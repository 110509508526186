import ListConfigSupport from "@/view/config/support/list_config_support";
import Category from './categories/route';
import Priority from './priorities/route';
import Assignment from "./assignment/route";
import Schedule from './schedule/route'
import Holiday from './holidays/route'
import Flow from './flow/route';
import GenericHoliday from './generic_holidays/route';

const route = [
    {
        path: '/config_support',
        name: 'config_support',
        component: ListConfigSupport,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Category,
    ...Priority,
    ...Assignment,
    ...Schedule,
    ...Holiday,
    ...Flow,
    ...GenericHoliday
]
export default routes;

<template>
    <div>
        <div class="gh-row-content">
            <GhCalendar
                :datalabel="{
                    id: 'label_search_start_date',
                    text: $t('select_period_from_date'),
                    style: 'min-width: 115px  !important;  width: 246px !important; text-align: right;'
                }"
                :datainput="{
                    id: 'search_start_date',
                    name: 'indicators[start_date][]',
                    value: ''
                }"
            />
            <GhCalendar
                :datalabel="{
                    id: 'label_search_end_date',
                    text: $t('to_date'),
                    style: 'min-width: 141px  !important;  width: 141px !important; text-align: right;'
                }"
                :datainput="{
                    id: 'search_start_date',
                    name: 'indicators[end_date][]',
                    value: ''
                }"
            />
        </div>
        <div class="gh-row-content">
            <GhInputTextField
                :datalabel="{
                    id: 'label_config_support_category_name',
                    text: $t('user') + ':',
                    style: 'width: 93px; min-width: 93px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    id: 'input_config_support_category_name',
                    name: 'indicators[user][]',
                    style: 'width: 15%',
                    type: 'text',
                    value:''
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import {mapState} from "vuex";
    export default {
        name: "search_action_indicators",
        components: {
            GhInputTextField,
            GhCalendar,
        },
        computed:{
            ...mapState(['getFeeds'])
        },
    }
</script>

import Vue from "vue";
// import {LoginUrl} from "@/common/config";
import vuex from "vuex";
import router from "../../../routes/router"
import {API_URL} from "@/common/config";
import axios from "axios";
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        answer_t_quiz:'',
    },
    mutations:{
        LOAD_ANSWER_T_QUIZ(state,payload){
            state.answer_quiz = payload;
        },
    },
    actions:{
        TO_QUIZZ_RESULT(){
            router.push({name: 'result_quizz'});
        },
        GET_ANSWERS_QUIZ(state,item){
            let requestLogin = API_URL + "questions_quizz?id_quizz="+item

            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_ANSWER_QUIZ", response.data.data);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

import ConfigGenericHolidays from "@/view/config/support/generic_holidays/template/generic_holidays.vue";

const routes = [
    {
        path: '/config/support/generic_holidays',
        name: 'generic_holidays',
        component: ConfigGenericHolidays,
        props: {id: null, showMenu: true, otherTitle: 'generic_holidays'},
        children: [
            {
                path: '/config/support/generic_holidays/:id',
                name: 'generic_holidays_id',
                component: ConfigGenericHolidays,
                props: {id: null, showMenu: true,  otherTitle: 'generic_holidays'},
            },
            {
                path: '/config/support/generic_holidays/:id/:view/',
                name: 'generic_holidays_view',
                component: ConfigGenericHolidays,
                props: {id: null, showMenu: true,  otherTitle: 'generic_holidays'},
            },
        ]
    }
];
export default routes;

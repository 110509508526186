var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhNoDropSelector',{key:'REFRESH_BENCHMARK_' + _vm.refreshBenchmark,attrs:{"datablock":{
            id: 'sector_benchmark',
            title: _vm.$t('benchmark') + ' - ' + (_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.country !== undefined ? _vm.ConfigBenchmark.data.country : '') + ' - ' + (_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.evaluation !== undefined ? _vm.ConfigBenchmark.data.evaluation : ''),
        }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('GhAction',{staticStyle:{"margin-left":"-27.5vw"},attrs:{"dataction":{
                    id: 'assign_advisory_evaluator_',
                    text: _vm.$t('download_excel'),
                    icon: require('@/assets/gh_new_dessign/descargar.svg')
                }},on:{"click":function($event){return _vm.$store.dispatch('easyDownload', {url: _vm.API_URL +'benchmark/' + _vm.$root._route.params.id + '/export'}, {root: true});}}}),_c('form',{key:'REFRESH_UPLOAD_' + _vm.refreshUpload,staticStyle:{"margin-top":"-17px"},attrs:{"id":"form_attach_benchmark"}},[_c('input',{attrs:{"type":"hidden","id":"allow_attach_type","value":".xml, .xlsx"}}),_c('GhEasyUpload',{ref:"RefAttachBenchmark",attrs:{"extrablock":{
                        id: 'benchmarck_attach',
                        class: '',
                        name_hidden: 'benchmark[attach]',
                        id_hidden: 'benchmark_attach',
                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                    },"datalabel":{
                        id: 'benchmark_attach',
                        style: 'color: red'
                    },"datainput":{
                        view: _vm.$root._route.params.view,
                        accept: '.xml, .xlsx',
                        text: _vm.$t('attach_excel'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: '',
                        file_path: '',
                        file_download: '',
                        required: false
                    }},on:{"change":_vm.doAttachFile}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_benchmark"}},[_c('input',{attrs:{"type":"hidden","name":"benchmarck[country_id]"},domProps:{"value":_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.country_id !== undefined ? _vm.ConfigBenchmark.data.country_id : ''}}),_c('GhTableCommon',{staticStyle:{"margin-top":"20px"},attrs:{"extratable":{
                        id: 'table_benchmark_sectors',
                    },"header":_vm.header,"data":_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.data !== undefined && _vm.ConfigBenchmark.data.data.sectors !== undefined && _vm.ConfigBenchmark.data.data.sectors.length !== 0 ? _vm.ConfigBenchmark.data.data.sectors : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('GhInputTextField',{attrs:{"datalabel":{
                                text: '',
                                style:'',
                                class: ''
                            },"datainput":{
                                required: true,
                                id: '',
                                name: 'benchmarck[sectors][' + itemProp.id +'][' + _vm.header.find(item => item.field === labelProp)['id'] +']',
                                style: '',
                                value: itemProp[labelProp],
                                type: 'float',
                                label_required: _vm.$t('column') + ': ' + _vm.header.find(item => item.field === labelProp)['text'] + ' ' + _vm.$t('row') + ': ' + itemProp['name'],
                            }}})]}}])}),_c('GhTableCommon',{staticStyle:{"margin-top":"20px"},attrs:{"extratable":{
                        id: 'table_benchmark_num_employees',
                    },"header":_vm.header,"data":_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.data !== undefined && _vm.ConfigBenchmark.data.data.num_employeees !== undefined && _vm.ConfigBenchmark.data.data.num_employeees.length !== 0 ? _vm.ConfigBenchmark.data.data.num_employeees : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('GhInputTextField',{attrs:{"datalabel":{
                                text: '',
                                style:'',
                                class: ''
                            },"datainput":{
                                required: true,
                                id: '',
                                name: 'benchmarck[num_employees][' + itemProp.id +'][' + _vm.header.find(item => item.field === labelProp)['id'] +']',
                                style: '',
                                value: itemProp[labelProp],
                                type: 'text',
                                label_required: _vm.$t('column') + ': ' + _vm.header.find(item => item.field === labelProp)['text'] + ' ' + _vm.$t('row') + ': ' + itemProp['name'],
                            }}})]}}])})],1)]},proxy:true}])}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'process_file',
            title: _vm.$t('process_file'),
            type: 'add',
            style: 'width: 35%; min-width: 430px'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [(!_vm.loadFile)?[_c('div',{staticClass:"centered"},[_c('img',{attrs:{"src":require('@/assets/gh_new_dessign/loader.gif'),"alt":""}})])]:[_c('label',{staticClass:"T15_b gh_text_field-label",staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.$t('load_file_correct')))])]]},proxy:true},{key:"footer",fn:function(){return [(_vm.loadFile)?[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_accept',
                        text: _vm.$t('accept'),
                    }},on:{"click":_vm.submitFile}}),_c('GhButton',{attrs:{"datainput":{
                        id: 'button',
                        text: _vm.$t('close'),
                        class: 'T19 container-md_button_content',
                        style: 'display: flex;'
                    }},on:{"click":function($event){_vm.Global.closePopUp('process_file'); _vm.refreshUpload++}}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"15px","padding-bottom":"15px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept',
                text: _vm.$t('accept'),
            }},on:{"click":_vm.submit}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.$router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
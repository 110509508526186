<template>
    <div style="padding-left: 12px; padding-right: 12px;" v-if="haveData">
        <form id="to_register">
            <GhNoDropSelector :datablock="{id:'register_company',title: $t('company'),second_title:'' }">
                <template v-slot:content>
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('company_name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_name',
                                    name: 'register[company][name]',
                                    style: 'width: 20%',
                                    value: persistance.company.name,
                                    type: 'text'
                                }"
                                ref="refCompanyName"
                                @blur="setPersistance('refCompanyName')"
                            />
                            <div class="no-min-width">
                                <GhDataSelector
                                    style="width: 29.65%"
                                    :datablock="{
                                        id: 'social_reason_register',
                                        class: 'T15_b',
                                        subtitle: $t('select_social_reason') + ':',
                                        text: $t('social_reason')+'*',
                                        label: $t('social_reason'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: 'margin-left:20px;',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    }"
                                    :datainput="{
                                        id: 'value_social_Reason',
                                        name: 'register[company][social_reason][]'
                                    }"
                                    :feed="getFeeds.feed_social_reason"
                                    :selected_input="[]"
                                />
                            </div>
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('cif') + '*',
                                    style: 'text-align: right; margin-left: 20px;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_cif',
                                    name: 'register[company][cif]',
                                    style: 'width: 100px;',
                                    value: persistance.company.cif,
                                    type: 'text'
                                }"
                                ref="refCompanyCif"
                                @blur="setPersistance('refCompanyCif')"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('full_address') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_direction',
                                    name: 'register[company][direction]',
                                    style: 'width: 20%',
                                    value: persistance.company.full_address,
                                    type: 'text'
                                }"
                                ref="refCompanyFullAddress"
                                @blur="setPersistance('refCompanyFullAddress')"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('locality') + '*',
                                    style: 'width: 150px; text-align: right; margin-left: 20px;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'register_company_locality',
                                    name: 'register[company][locality]',
                                    style: 'width:20%;',
                                    value: persistance.company.locality,
                                    type: 'text'
                                }"
                                ref="refLocality"
                                @blur="setPersistance('refLocality')"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('cp') + '*',
                                    style:'text-align: right; margin-left: 20px;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_cp',
                                    name: 'register[company][cp]',
                                    style: 'width: 50px;',
                                    value: persistance.company.cp,
                                    type: 'text'
                                }"
                                ref="refCompanyCp"
                                @blur="setPersistance('refCompanyCp')"
                            />
                        </div>
                        <div class="gh-row-content">
                            <div>
                                <div id="identifier_country" style="width: 28.9%;">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'country',
                                            class: 'T15_b',
                                            subtitle: $t('select_country') + ':',
                                            text: $t('country')+'*',
                                            label: $t('country'),
                                            type: 'radio',
                                            columns: 3,
                                            required: true,
                                            style: '',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'value_country',
                                            name: 'register[company][country][]'
                                        }"
                                        :feed="getFeeds.feed_country"
                                        :selected_input="[]"
                                        @accept="searchProvince"
                                        ref="refCountry"
                                    />
                                </div>
                            </div>
                            <div>
                                <div id="identifier_province" style="width: 23.2%">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'province',
                                            class: 'T15_b',
                                            subtitle: $t('select_province') + ':',
                                            text: $t('province') + '*',
                                            label: $t('province'),
                                            type: 'radio',
                                            columns: 3,
                                            required: true,
                                            style: 'margin-left:20px;',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        :datainput="{
                                            id: 'value_province',
                                            name: 'register[company][province][]'
                                        }"
                                        :feed="getterProvinceByCountry !== undefined ? getterProvinceByCountry : []"
                                        :selected_input="[]"
                                        @accept="searchLocality"
                                        ref="refProvince"
                                        :key="'refresh_province_' + refreshProvince"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_email',
                                    name: 'register[company][email]',
                                    style: 'width: 20%',
                                    value: persistance.company.email,
                                    type: 'text'
                                }"
                                ref="refCompanyEmail"
                                @blur="setPersistance('refCompanyEmail')"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('telephone') + '*',
                                    style: 'width: 150px; text-align: right; margin-left: 20px;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_telephone',
                                    name: 'register[company][telephone]',
                                    style: 'width: 20%;',
                                    value: persistance.company.telephone,
                                    type: 'text'
                                }"
                                ref="refCompanyTelephone"
                                @blur="setPersistance('refCompanyTelephone')"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhDataSelector
                                :datablock="{
                                    id: 'activity',
                                    class: 'T15_b',
                                    subtitle: $t('select_activity_sector') + ':',
                                    text: $t('activity_sector')+'*',
                                    label: $t('activity_sector'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;'
                                }"
                                :datainput="{
                                    id: 'value_activity',
                                    name: 'register[company][activity][]'
                                }"
                                :feed="getFeeds.feed_activity"
                                :selected_input="[]"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhDataSelector
                                :datablock="{
                                    id: 'num_employee',
                                    class: 'T15_b',
                                    subtitle: $t('select_number_employees') + ':',
                                    text: $t('number_employees')+'*',
                                    label: $t('number_employees'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left: -5px;',
                                    popupStyle: 'min-width: 900px;width: 55%;'
                                }"
                                :datainput="{
                                    id: 'value_num_employee',
                                    name: 'register[company][num_employee][]'
                                }"
                                :feed="getFeeds.feed_num_employers"
                                :selected_input="[]"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhTextarea
                                :datalabel="{
                                    text: $t('more_information'),
                                    class: 'T15_b',
                                    styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                                }"
                                :datainput="{
                                    id: 'more_information',
                                    name: 'register[company][more_information]',
                                    required: false,
                                    value: persistance.company.more_information
                                }"
                                ref="refCompanyMoreInformation"
                                @blur="setPersistance('refCompanyMoreInformation')"
                            />
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <GhNoDropSelector :datablock="{id:'register_contact',title: $t('contact_person'),second_title:'' }">
                <template v-slot:content>
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_name',
                                    name: 'register[contact][name]',
                                    style: 'width: 20%',
                                    value: persistance.contact.name,
                                    type: 'text'
                                }"
                                ref="refContactName"
                                @blur="setPersistance('refContactName')"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('surnames') + '*',
                                    style:'width: 150px; text-align: right;margin-left: 20px;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_surnames',
                                    name: 'register[contact][surnames]',
                                    style: 'width: 20%',
                                    value: persistance.contact.surnames,
                                    type: 'text',
                                }"
                                ref="refContactSurnames"
                                @blur="setPersistance('refContactSurnames')"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_email',
                                    name: 'register[contact][email]',
                                    style: 'width: 20%',
                                    value: persistance.contact.email,
                                    type: 'text',
                                }"
                                ref="refContactEmail"
                                @blur="setPersistance('refContactEmail')"
                            />
                            <GhDataSelector
                                :datablock="{
                                    id: 'position',
                                    class: 'T15_b',
                                    subtitle: $t('select_position') + ':',
                                    text: $t('position')+'*',
                                    label: $t('position'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left: 20px;',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                }"
                                :datainput="{
                                    id: 'value_num_employee',
                                    name: 'register[contact][position][]'
                                }"
                                :feed="getFeeds.feed_positions"
                                :selected_input="[]"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('contact_telephone') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_telephone',
                                    name: 'register[contact][telephone]',
                                    style: 'width: 20%',
                                    value: persistance.contact.telephone,
                                    type: 'text',
                                }"
                                ref="refContactTelephone"
                                @blur="setPersistance('refContactTelephone')"
                            />
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <GhNoDropSelector :datablock="{id:'register_treatment_data',title: $t('data_treatment'),second_title:'' }">
                <template v-slot:content>
                    <div class="gh-content-global">
                        <div class="gh-row-content" style="white-space: pre-wrap;">
                            {{getFeeds.data_treatment_text.text}}
                        </div>
                        <div class="gh-row-content">
                            <GhChecks
                                :datalabel="{text:$t('politic_privacity'),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'register[politic_privacity]'}"
                            />
                        </div>
                        <div class="gh-row-content" style="white-space: pre-wrap;">
                            {{getFeeds.data_treatment_text.text_confirmation}}
                        </div>
                        <div class="gh-row-content">
                            <GhChecks
                                :datalabel="{text:$t('legal_advise'),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'register[legal_advise]'}"
                            />
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton
                    :datainput="{id: 'btn_send',text: $t('send_request'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                    @click="sendRegister();"
                />
                <GhButton
                    :datainput="{id: 'btn_back',text: $t('go_out'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                    @click="back();"
                />
            </div>
        </form>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhTextarea from "fe-gh-textarea-lib";

    import {mapGetters, mapState} from "vuex";
    export default {
        name: "register_view",
        components:{
            GhButton,
            GhNoDropSelector,
            GhInputTextField,
            GhDataSelector,
            GhChecks,
            GhTextarea
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterProvinceByCountry']),
        },
        data(){
            return{
                haveData: false,
                refreshProvince: 0,
                persistance:{
                    company: {
                        name: '',
                        cif: '',
                        full_address: '',
                        locality: '',
                        cp: '',
                        email: '',
                        telephone: '',
                        more_information: ''
                    },
                    contact: {
                        name: '',
                        surnames: '',
                        email: '',
                        telephone: ''
                    }
                }
            }
        },
        watch: {
            'getterProvinceByCountry'(){
                this.refreshProvince++;
            },
        },
        async mounted() {
            await this.$store.dispatch('getSocialReason', '', {root:true});
            await this.$store.dispatch('getCountry', '', {root:true});
            await this.$store.dispatch('getActivity', '', {root:true});
            await this.$store.dispatch('getNumEmployers', '', {root:true});
            await this.$store.dispatch('getPosition', '', {root:true});
            await this.$store.dispatch('getTreatmentText', '', {root:true});
            await this.$store.dispatch('resetProvinceByCountry', '', {root:true});
            await this.$store.dispatch('resetLocalityByProvince', '', {root:true});

            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);
            this.haveData = true;
        },
        methods:{
            back(){
                this.$store.dispatch('TO_LOGIN','',{root:true});
            },
            sendRegister(){
                if(this.Global.checkRequired('to_register') == 'OK') {
                    const formData = new FormData(document.getElementById('to_register'));
                    this.$store.dispatch('SET_REGISTER', {
                        formData: [...formData],
                    }, {root: true});
                }
            },
            searchProvince(){
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    this.$store.dispatch('getProvinceByCountry', {
                        country: country,
                    }, {root: true});
                }
            },
            searchLocality(){
                var province = this.$refs.refProvince.checkdata;
                province = Object.keys(province);

                if(province.length !== 0){
                    province = province[0];
                    this.$store.dispatch('getLocalityByProvince', {
                        province: province,
                    }, {root: true});
                }
            },
            setPersistance(ref){
                switch(ref){
                    case 'refCompanyName':
                        this.persistance.company.name = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCompanyCif':
                        this.persistance.company.cif = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCompanyFullAddress':
                        this.persistance.company.full_address = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refLocality':
                        this.persistance.company.locality = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCompanyCp':
                        this.persistance.company.cp = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCompanyEmail':
                        this.persistance.company.email = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCompanyTelephone':
                        this.persistance.company.telephone = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCompanyMoreInformation':
                        this.persistance.company.more_information = this.$refs[ref].hiddenValue;
                        break;
                    case 'refContactName':
                        this.persistance.contact.name = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refContactSurnames':
                        this.persistance.contact.surnames = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refContactEmail':
                        this.persistance.contact.email = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refContactTelephone':
                        this.persistance.contact.telephone = this.$refs[ref].$props.datainput.value;
                        break;
                }
            }
        }
    }
</script>

<style>
    #activity_selector_label{
        width: 150px!important;
    }
    #country_selector_label, #social_reason_register_selector_label, #province_selector_label, #position_selector_label{
        width: 150px !important;
        text-align: right;
    }

    #social_reason_data_select_SUMMARY {
        width: 250px !important;
    }

    #country_data_select_SUMMARY {
        width: 260px !important;
    }
    #province_data_select_SUMMARY{
        width:20% !important;
    }

    #identifier_country > div{
        min-width: 100px !important;
    }

    #identifier_province > div{
        min-width: 100px !important;
    }

    .no-min-width > div{
        min-width: unset;
    }
</style>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        counters: [],
    },
    mutations: {
        loadCounters(state, payload) {
            state.counters = payload;
        }
    },
    actions: {
        async getPermsGroups(state){
            await axios.get(
                API_URL + "perms/groups"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadCounters', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
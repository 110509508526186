<template>
    <div class="float_window">
        <template v-if="haveData">
            <GhTableCommon
               :extratable="{
                    id: 'bibliography',
                }"
               :header="header"
               :data="TabUserFile.records_data.permisions.length == 0 ? [] : TabUserFile.records_data.permisions"
            >
            </GhTableCommon>
        </template>
        <template v-else-if="!haveData">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </template>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_permisions",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapState(['TabUserFile'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'group_profile_name', field: 'permision', sorting: true, typeSort: 'code', style: 'min-width: 200px; width: 10%;'},
                    {text: 'companies', field: 'business', sorting: true, typeSort: 'string', style: 'width: 90%;'},
                ]
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getUserPermissionsData', '', {root: true});
                this.$parent.$parent.data[0].result = this.TabUserFile.records_data.permisions.length;
                this.haveData = true;
            }
        }
    }
</script>
<template>
    <div class="tooltip-container" @click="toggleTooltip" ref="container">
        <slot></slot>
        <div v-if="visible" class="tooltip-box T-text" :style="tooltipStyle" v-html="content" ref="tooltip"></div>
    </div>
</template>

<script>
    export default {
        name: 'gh_ce_custom_title',
        props: {
            content: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                visible: false,
                tooltipStyle: {}
            };
        },
        watch: {
            'content'(){
                this.visible = false;
                this.toggleTooltip()
            }
        },
        methods: {
            toggleTooltip() {
                if(this.content.length !== 0) {
                    this.visible = !this.visible;
                    if (this.visible) {
                        this.$nextTick(() => {
                            const container = this.$refs.container;
                            const containerRect = container.getBoundingClientRect();
                            const containerLeft = containerRect.left;
                            const containerRight = containerRect.right;
                            const containerWidth = containerRect.width;
                            const maxWidth = 800;
                            let minWidth = 0;

                            minWidth = this.content.length * 6.5;
                            if (minWidth > maxWidth) {
                                minWidth = maxWidth;
                            }

                            let tooltipLeft = '50%';
                            let tooltipRight = 'auto';

                            if (containerLeft < window.innerWidth / 2) {
                                tooltipLeft = '0';
                            } else if (containerRight > window.innerWidth / 2) {
                                tooltipLeft = 'auto';
                                tooltipRight = (window.innerWidth - containerLeft) > 180 ? (window.innerWidth - containerLeft - (containerWidth * 2)) + 'px' : (window.innerWidth - containerLeft - (containerWidth > 100 ? (containerWidth + 20) : (containerWidth * 1.7))) + 'px';
                            }

                            this.tooltipStyle = {
                                top: '100%',
                                left: tooltipLeft,
                                right: tooltipRight,
                                maxWidth: maxWidth + 'px',
                                minWidth: minWidth + 'px',
                                width: 'auto'
                            };
                        });
                        document.addEventListener('click', this.handleOutsideClick);
                    } else {
                        document.removeEventListener('click', this.handleOutsideClick);
                    }
                }
            },
            handleOutsideClick(event) {
                const container = this.$refs.container;
                const tooltipBox = this.$refs.tooltip;

                if (!container.contains(event.target) && (!tooltipBox || !tooltipBox.contains(event.target))) {
                    this.visible = false;
                    document.removeEventListener('click', this.handleOutsideClick);
                }
            }
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleOutsideClick);
        }
    };
</script>

<style>
    .tooltip-container {
        position: relative;
        display: inline-block;
    }

    .tooltip-box {
        position: absolute;
        background-color: white;
        color: black;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        font-family: IBMPlexSans-Regular;
        padding: 10px;
        border-radius: 4px;
        white-space: normal;
        z-index: 1000;
        font-size: 14px;
        height: auto;
        opacity: 1;
        transition: opacity 0.2s;
        box-sizing: border-box;
        margin-top: 10px;
    }
</style>

import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
// import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        counter: [],
        companies: [],
        data: [],
        refreshData: false
    },
    mutations: {
        loadAssignmentItineraryCount(state, payload){
            state.counter = payload;
        },
        loadCompaniesByItinerary(state,payload){
            state.companies = payload;
        },
        loadCompanyAssociatedByItinerary(state,payload){
            state.refreshData = !state.refreshData;
            state.data = payload;
        },
    },
    getters: {
        getterCompanyAssociatedByItinerary(state){
            const dummy = state.refreshData; // eslint-disable-line no-unused-vars
            return state.data;
        }
    },
    actions: {
        async getCountersByAssignmentItinerary(state, item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.id + "/count"
            ).then(
                response => {
                    state.commit('loadAssignmentItineraryCount', response.data.data);
                }
            )
        },
        async getCompaniesByItinerary(state,item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.id
            ).then(
                response => {
                    state.commit('loadCompaniesByItinerary', response.data.data);
                }
            )
        },
        async getCompanyAssociatedByItinerary(state, item){
            await axios.get(
                API_URL + 'assign/itinerary/' + item.itinerary_uuid + '/' + item.company_uuid
            ).then(
                response => {
                    state.commit('loadCompanyAssociatedByItinerary', response.data.data);
                }
            )
        },
        async setCompanyAssociatedByItinerary(state ,item){
            let request = API_URL + 'assign/itinerary/' + item.itinerary_uuid + '/' + item.company_uuid

            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        await this.dispatch('getCountersByAssignmentItinerary', {
                            id: item.itinerary_uuid
                        });

                        await this.dispatch('getCompaniesByItinerary', {
                            id: item.itinerary_uuid
                        });
                    }
                    return response;
                },
                error => {
                    console.log(error);
                }
            )

        }
    }
}

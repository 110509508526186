import Vue from "vue";
import {API_URL} from "@/common/config";
import vuex from "vuex";
import axios from "axios";
import router from "@/routes/router";
// import router from "../../../routes/router"
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        itemsPay: [],
        typeButton: false,
        processPay: false,
        payments: {},
    },
    mutations:{
        loadPayments: (state, payload) => (state.payments = payload),
        loadTypeButton: (state, payload) => (state.typeButton = payload),
        loadProcessPay: (state, payload) => (state.processPay = payload),
        loadItemsPay: (state, payload) => (state.itemsPay = payload),
    },
    actions: {
        getPayments(state,item) {
            let requestLogin = API_URL + "payments" + (item.id !== '' ? "/" + item.id : '');

            axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.commit("loadPayments", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async toNormalView(state){
            await this.dispatch('resetAppTabs');
            var id_redirect = router.options.GhTabs.find(n => n.value === 'my_itineraries')['id']

            localStorage.setItem('TabItem','my_itineraryes')
            localStorage.setItem('item','my_itineraryes')
            localStorage.setItem('RedirectPerm','programs')
            localStorage.setItem('id','C-' + id_redirect)
            state.rootState.Tabs.TabActive = 'programs';
            router.push({path: '/programs'});
        },
        async setPayments(state, item) {
            let requestLogin = API_URL + "payments" + (item.id !== '' ? "/" + item.id : '');

            let formData = new FormData();

            await axios({
                method: "post",
                data: formData,
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('resetAppTabs');
                        var id_redirect = router.options.GhTabs.find(n => n.value === 'my_itineraries')['id']

                        localStorage.setItem('TabItem','my_itineraries');
                        localStorage.setItem('item','my_itineraries');
                        localStorage.setItem('RedirectPerm','programs');
                        localStorage.setItem('id','C-' + id_redirect);
                        state.rootState.Tabs.TabActive = 'programs';
                        router.push({path: '/programs'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

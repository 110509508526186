<template>
    <div class="gh-row-content">
        <GhInputTextField
            :datalabel="{
                text: $t('name'),
                id: 'label_group_user_name',
                class: '',
                style:'width: 150px; text-align: right;',
            }"
            :datainput="{
                id: 'input_group_user_name',
                name: 'user[first_name]',
                type: 'text'
            }"
        />
        <GhInputTextField
            :datalabel="{
                text: $t('surname'),
                id: 'label_group_user_last_name',
                class: '',
                style:'width: 150px; text-align: right;',
            }"
            :datainput="{
                class: '',
                id: 'input_group_user_last_name',
                name: 'user[last_name]',
                type: 'text'
            }"
        />
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    export default {
        name: "view_assignment",
        components:{
            GhInputTextField,
        }
    }
</script>

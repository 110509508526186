var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'ccaa', title: _vm.$t('ccaa')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_ccaa"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[((!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined) || (!_vm.$root._route.params.view && _vm.ConfigCCAA.data !== undefined && _vm.ConfigCCAA.data.is_admin))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('code') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'ccaa_code',
                                    name: 'ccaa[code]',
                                    style: 'width: 200px',
                                    value: _vm.ConfigCCAA.data.code,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigCCAA.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[((!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined) || (!_vm.$root._route.params.view && _vm.ConfigCCAA.data !== undefined && _vm.ConfigCCAA.data.is_admin))?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'ccaa_name',
                                    name: 'ccaa[name]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.ConfigCCAA.data.name,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigCCAA.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{
                                text: _vm.$t('tax'),
                                style: '',
                                class: '',
                                id: ''
                            },"datainput":{
                                disabled: _vm.$root._route.params.view,
                                required: false,
                                selected: _vm.ConfigCCAA.data.tax === undefined || _vm.ConfigCCAA.data.tax === 1,
                                type: 'checkbox',
                                id: 'ccaa_tax',
                                value: 1,
                                name: 'ccaa[tax]',
                                style: 'margin-left: 162px;'
                            }}})],1),_c('div',{staticClass:"gh-row-content no-min-width"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"refCountry",attrs:{"datablock":{
                                    id: 'ccaa_country',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_country') + ':',
                                    text: _vm.$t('country') + '*',
                                    label: _vm.$t('country'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                },"datainput":{
                                    id: 'value_ccaa_country',
                                    name: 'ccaa[country]'
                                },"feed":_vm.getFeeds.feed_country,"selected_input":_vm.ConfigCCAA.data.country !== undefined ? _vm.ConfigCCAA.data.country : []},on:{"accept":_vm.searchProvince}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('country') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.ConfigCCAA.data.country).join()))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:'refresh_province_' + _vm.refreshProvince,ref:"refProvince",attrs:{"datablock":{
                                    id: 'ccaa_province',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_province') + ':',
                                    text: _vm.$t('province') + '*',
                                    label: _vm.$t('province'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                },"datainput":{
                                    id: 'value_ccaa_province',
                                    name: 'ccaa[province][]'
                                },"feed":_vm.getterProvinceByCountry !== undefined ? _vm.getterProvinceByCountry : [],"selected_input":_vm.ConfigCCAA.data.province !== undefined ? _vm.ConfigCCAA.data.province : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('province') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.ConfigCCAA.data.province).join()))])]],2)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitCCAA}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
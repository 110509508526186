var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{attrs:{"id":"form_business"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticClass:"gh-row-column-flex"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"20%","min-width":"165px !important","margin-right":"5px"}},[_vm._v(_vm._s(_vm.getterCompanyData.name))])]),_c('div',{staticClass:"gh-row-column-flex",staticStyle:{"width":"33%"}},[_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                            id: 'social_reason',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_social_reason') + ':',
                            text: _vm.$t('social_reason') + '*',
                            label: _vm.$t('social_reason'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px; width: 55%;'
                        },"datainput":{
                            id: 'value_social_reason',
                            name: 'business[company][social_reason]'
                        },"feed":_vm.getFeeds.feed_social_reason,"selected_input":_vm.getterCompanyData.social_reason}})],1),_c('div',{staticClass:"gh-row-column-flex"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('cif') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterCompanyData.cif))])])]),_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticClass:"gh-row-column-flex"},[_c('GhInputTextField',{ref:"refFullAddress",staticStyle:{"display":"contents !important"},attrs:{"datalabel":{
                            text: _vm.$t('full_adress') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_direction',
                            name: 'business[company][direction]',
                            style: 'width: 50%',
                            value: _vm.persistance.company.direction,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refFullAddress')}}})],1),_c('div',{staticClass:"gh-row-column-flex"},[_c('GhInputTextField',{ref:"refLocality",staticStyle:{"display":"contents !important"},attrs:{"datalabel":{
                            text: _vm.$t('locality') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_locality',
                            name: 'business[company][locality]',
                            style: 'width: 50%',
                            value: _vm.persistance.company.locality,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refLocality')}}})],1),_c('div',{staticClass:"gh-row-column-flex"},[_c('GhInputTextField',{ref:"refCp",staticStyle:{"display":"contents !important"},attrs:{"datalabel":{
                            text: _vm.$t('postal_code') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_cp',
                            name: 'business[company][cp]',
                            style: 'width: 80px',
                            value: _vm.persistance.company.cp,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refCp')}}})],1)]),_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticClass:"no-min-width",staticStyle:{"width":"33.33%"}},[_c('GhDataSelector',{ref:"refCountry",attrs:{"datablock":{
                            id: 'company_country',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_country') + ':',
                            text: _vm.$t('country') + '*',
                            label: _vm.$t('country'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_company_country',
                            name: 'business[company][country]'
                        },"feed":_vm.getFeeds.feed_country,"selected_input":_vm.getterCompanyData.country},on:{"accept":_vm.searchProvince}})],1),_c('div',{staticClass:"no-min-width",staticStyle:{"width":"66.66%"}},[_c('GhDataSelector',{key:'refresh_province_' + _vm.refreshProvince,ref:"refProvince",attrs:{"datablock":{
                            id: 'company_province',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_province') + ':',
                            text: _vm.$t('province') + '*',
                            label: _vm.$t('province'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_company_province',
                            name: 'business[company][province]'
                        },"feed":_vm.getterProvinceByCountry !== undefined ? _vm.getterProvinceByCountry : [],"selected_input":_vm.getterCompanyData.province}})],1)]),_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticStyle:{"width":"33.33%"}},[_c('GhInputTextField',{ref:"refEmail",attrs:{"datalabel":{
                            text: _vm.$t('email') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_email',
                            name: 'business[company][email]',
                            style: 'width: 50%',
                            value: _vm.persistance.company.email,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refEmail')}}})],1),_c('div',{staticStyle:{"width":"66.66%"}},[_c('GhInputTextField',{ref:"refTelephone",attrs:{"datalabel":{
                            text: _vm.$t('telephone') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_telephone',
                            name: 'business[company][telephone]',
                            style: 'width: 80px;',
                            value: _vm.persistance.company.telephone,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refTelephone')}}})],1)]),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                        id: 'business_activity',
                        class: 'T15_b',
                        subtitle: _vm.$t('select_activity_sector') + ':',
                        text: _vm.$t('activity_sector')+'*',
                        label: _vm.$t('activity_sector'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    },"datainput":{
                        id: 'value_business_activity',
                        name: 'business[company][activity]'
                    },"feed":_vm.getFeeds.feed_activity,"selected_input":_vm.getterCompanyData.activity}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                          id: 'num_employee',
                          class: 'T15_b',
                          subtitle: _vm.$t('select_number_employees') + ':',
                          text: _vm.$t('number_employees') + '*',
                          label: _vm.$t('number_employees'),
                          type: 'radio',
                          columns: 3,
                          required: true,
                          label_required: _vm.$t('number_employees'),
                          style: 'margin-left: -5px;',
                          popupStyle: 'min-width: 900px;width: 55%;'
                      },"datainput":{
                          id: 'value_num_employee',
                          name: 'business[company][num_employee]'
                      },"feed":_vm.getFeeds.feed_num_employers,"selected_input":_vm.getterCompanyData.num_employee}})],1)]),_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"-7px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('contact_person')))])]),_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticStyle:{"width":"33.33%"}},[_c('GhInputTextField',{ref:"refName",attrs:{"datalabel":{
                            text: _vm.$t('name') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_contact_person_name',
                            name: 'business[contact_person][name]',
                            style: 'width: 50%',
                            value: _vm.persistance.contact_person.name,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refName')}}})],1),_c('div',{staticStyle:{"width":"66.66%"}},[_c('GhInputTextField',{ref:"refSurnames",attrs:{"datalabel":{
                            text: _vm.$t('surnames') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_contact_person_surnames',
                            name: 'business[contact_person][surnames]',
                            style: 'width: 25%',
                            value: _vm.persistance.contact_person.surnames,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refSurnames')}}})],1)]),_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticStyle:{"width":"33.33%"}},[_c('GhInputTextField',{ref:"refContactPersonEmail",attrs:{"datalabel":{
                            text: _vm.$t('email') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'company_contact_person_email',
                            name: 'business[contact_person][email]',
                            style: 'width: 50%',
                            value: _vm.persistance.contact_person.email,
                            type: 'text'
                        }},on:{"blur":function($event){return _vm.setPersistance('refContactPersonEmail')}}})],1),_c('div',{staticClass:"no-min-width",staticStyle:{"width":"66.66%"}},[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'contact_person_position',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_position') + ':',
                            text: _vm.$t('position') + '*',
                            label: _vm.$t('position'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_contact_person_position',
                            name: 'business[contact_person][position]'
                        },"feed":_vm.getFeeds.feed_positions,"selected_input":_vm.getterCompanyData.contact_person.position_selector}})],1)]),_c('div',{staticClass:"gh-row-line-flex"},[_c('GhInputTextField',{ref:"refContactPersonTelephone",attrs:{"datalabel":{
                        text: _vm.$t('telephone') + '*',
                        style:'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'company_contact_person_telephone',
                        name: 'business[contact_person][telephone]',
                        style: 'width: 80px;',
                        value: _vm.persistance.contact_person.telephone,
                        type: 'text'
                    }},on:{"blur":function($event){return _vm.setPersistance('refContactPersonTelephone')}}})],1)])]),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"-10px","margin-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            },"type":"submit"},on:{"click":_vm.modifyBusiness}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":_vm.changeState}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="container-numeric-questionnaire">
        <div v-for="(item, key) in data" :key="'CARD_NUMERIC_QUESTIONNAIRE_' + key" :style="'width: ' + (100 / data.length) + '%; cursor: pointer; display: grid; ' + (key === 0 ? 'border-top-left-radius: 4px; border-bottom-left-radius: 4px;' : key === data.length - 1 ? 'border-top-right-radius: 4px; border-bottom-right-radius: 4px;' : '') + 'border: 1px solid #AFAFAF;' + (selected.answer === item.id ? 'background-color: #1b82c50f; border: 1px solid #1B82C5; box-shadow: 4px -1px 8px #1b82c570, -4px 4px 8px #1b82c570;' : '')" @click="checkBox(item); $emit('emiter')">
            <div style="padding: 12px; text-align: justify;">
                <label class="gh_text_field-label T-text" v-html="item.answer" style="cursor: pointer;"/>
            </div>
            <div style="display: block; align-self: self-end; height: 40px;" :style="selected.answer === item.id ? 'cursor: pointer;' : 'cursor: not-allowed !important;'">
                <div style="display: flex; width: 100%; height: 40px;" :class="selected.answer === item.id ? '' : 'disabled'" :style="selected.answer === item.id ? 'background-color: #fff;' : 'background-color: #AFAFAF99;'">
                    <div v-for="(values, keyValues) in item.values" class="centered" :style="(parseInt(selected.value) ===  parseInt(values) ? 'background-color: #1B82C599;' : '') + 'width: ' + (100 / Object.keys(item.values).length) + '%; border-top: 1px solid #AFAFAF;' + (keyValues !== Object.keys(item.values).length - 1 ? 'border-right: 1px solid #AFAFAF;' : '')" :key="'CARD_NUMERIC_QUESTIONNAIRE_VALUES_' + key + keyValues" @click="checkValue(values); $emit('emiter')">
                        <label class="gh_text_field-label" :class="selected.value ===  values ? 'T-subtitle-second' : 'T-text'" style="margin-right: unset" :style="selected.answer === item.id ? 'cursor: pointer;' : 'cursor: not-allowed !important;'">{{values}}</label>
                    </div>
                </div>
            </div>
        </div>
        <input :name="dataBlock.name + '[answer]'" type="hidden" :value="selected.answer"/>
        <input :name="dataBlock.name + '[value]'" type="hidden" :value="selected.value"/>
    </div>
</template>

<script>
    export default {
        name: 'gh-ce-numeric-questionnaire',
        props: ['data', 'dataBlock'],
        data(){
            return {
                executeValue: false,
                selected: {
                    answer: '',
                    value: '',
                }
            }
        },
        mounted() {
            this.selected.answer = this.dataBlock.selected.answer;
            this.selected.value = this.dataBlock.selected.value;
        },
        methods: {
            checkBox(item){
                if(!this.dataBlock.disabled) {
                    this.selected.answer = item.id;
                    if (!this.executeValue) {
                        this.selected.value = '';
                    }
                    this.executeValue = false;
                }
            },
            checkValue(value){
                if(!this.dataBlock.disabled) {
                    this.executeValue = true;
                    this.selected.value = value
                }
            }
        }
    }
</script>

<style>
    .container-numeric-questionnaire {
        border-radius: 4px;
        width: 100%;
        display: flex;
    }
</style>
<template>
    <GhNoDropSelector
        :datablock="{
            id: 'assign_companies_itinerary_' + id_itinerary,
            title: $t('companies'),
            label: ItineraryAssign.counter.itinerary + ' ' + $t('results'),
        }"
    >
        <template v-slot:content>
            <component v-bind:is="component.list_companies_by_itinerary" :id_itinerary="id_itinerary" />
        </template>
    </GhNoDropSelector>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import {mapState} from "vuex";

    import list_companies_by_itinerary from "@/view/assign/itinerary/templates/lists/list_companies_by_itinerary.vue";

    export default {
        name: "list_assign_itineraries",
        components: {
            GhNoDropSelector,
        },
        props:['id_itinerary'],
        computed:{
          ...mapState(['ItineraryAssign']),
        },
        data(){
            return{
                actualId: '',
                component: {
                    list_companies_by_itinerary: list_companies_by_itinerary
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                this.actualId = this.$props.id_itinerary;
                await this.$store.dispatch('getCountersByAssignmentItinerary', {
                    id: this.actualId
                }, {root: true});
            }
        },
        async updated(){
            if(this.actualId !== this.$props.id_itinerary){
                this.actualId = this.$props.id_itinerary;
                await this.$store.dispatch('getCountersByAssignmentItinerary', {
                    id: this.actualId
                }, {root: true});
            }
        }
    }
</script>

<style>
    [id^="assign_companies_itinerary_"] > div > div > .collapsible_section-label {
        padding-top: 3px;
    }
</style>
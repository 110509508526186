<template>
    <div v-if="haveData">
        <input type="hidden" name="search_supports[type]" :value="$root._route.params.idblock">
        <div class="gh-row-content">
            <GhTextarea
                :datalabel="{
                    text: $t('statement'),
                    class: 'T15_b',
                    styles: 'margin-left: 35px; padding-right: 10px;'
                }"
                :datainput="{
                    id: 'search_exercises_statement',
                    name: 'search_exercises[statement]',
                    value: '',
                    required: false
                }"
            />
        </div>
        <div class="gh-row-content">
            <div class="min-width-300">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'checkbox',
                        id: 'search_supports_exercise_type',
                        label: $t('type_of_exercise'),
                        title: $t('type_of_exercise'),
                        text: $t('select_type_of_exercise') + ':',
                        name: 'search_supports[type_of_exercise][]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: false,
                        style: '',
                        label_style: 'white-space: nowrap;',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :selected_input="[]"
                    :items="getFeeds.feed_type"
                />
            </div>
            <div class="min-width-300">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'checkbox',
                        id: 'search_supports_topic',
                        label: $t('topic'),
                        title: $t('topic'),
                        text: $t('select_topic') + ':',
                        name: 'search_supports[topic][]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: true,
                        checkparentdata: true,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    }"
                    :selected_input="[]"
                    :items="getFeeds.feed_topic"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhTextarea from 'fe-gh-textarea-lib';

    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "exercises_search",
        components:{
            GhTreeSelectorBlock,
            GhTextarea,
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                await this.$store.dispatch('getFeedType', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>

<template>
    <div style="margin-bottom: 10px;" :key="'KEY3' + 'view_elements_' + refreshView">
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('items_that_make_up_the_grouper') + ': ' + TabCreation.actual_grouper}}</div>
        </div>
        <div v-if="getterItemsComposed.length !== 0" :id="'items_composed_' + TabCreation.actual_grouper" style="padding-bottom: 10px;">
            <GhTreeActionBlock
                :dataBlock="{
                    id: 'tree_items_composed_' + TabCreation.actual_grouper,
                    actionWithoutChildren: true,
                    actionWithChildren: true,
                    extraLabelNumber: false,
                    extraLabelText: '',
                    loadStorageOpen: true
                }"
                :data="getterItemsComposed"
            >
                <template v-slot:Action="Action">
                    <div style="display: flex" v-if="$root._route.params.type !== 'enabled'">
                        <template v-if="Action.Action.Action.Action.show">
                            <GhAction
                                :dataction="{
                                    id: 'tree_associated_items_conditions_' + TabCreation.actual_grouper,
                                    class: 'T15_action',
                                    text: $t('conditions'),
                                    icon: Action.Action.Action.Action.condition ? require('../../../../assets/svg/tick.svg') : require('../../../../assets/gh_new_dessign/denied.svg')
                                }"
                                @click="checkConditions(Action.Action.Action.Action, $root._route.params.type !== undefined && $root._route.params.type !== 'elaboration')"
                            />
                        </template>
                        <template v-if="!$root._route.params.view && $root._route.params.type !== undefined && ($root._route.params.type === 'elaboration' || $root._route.params.type === 'pending')">
                            <GhAction
                                :dataction="{
                                    id: 'tree_associated_items_modify_' + TabCreation.actual_grouper,
                                    class: 'T15_action',
                                    text: $t('modify'),
                                    icon: require('../../../../assets/gh_new_dessign/edit.svg')
                                }"
                                @click="checkTypeModify(Action.Action.Action.Action)"
                            />
                            <GhAction
                                :dataction="{
                                    id: 'tree_associated_items_delete_' + TabCreation.actual_grouper,
                                    class: 'T15_action',
                                    text: $t('delete'),
                                    icon: require('../../../../assets/gh_new_dessign/trash.svg')
                                }"
                                @click="Global.deleteConfirm('delete_confirm','AssociateItem', {
                                    item_id: Action.Action.Action.Action.id,
                                    element_id: Action.Action.Action.Action.element_id,
                                    grouper_id: $root._route.params.grouper,
                                    id: $root._route.params.id
                                },{'name': Action.Action.Action.Action.name}, $t('delete_item'), $t('preparing_delete_item'), 'delete')"
                            />
                        </template>
                    </div>
                </template>
            </GhTreeActionBlock>
        </div>

        <div class="centered" v-if="!$root._route.params.view && $root._route.params.type !== undefined && ($root._route.params.type === 'elaboration' || $root._route.params.type === 'pending')">
            <template v-if="CreationGrouper.grouper_data.show_punctuation">
                <GhButton
                    :datainput="{
                        id: 'btn_punctuation_item',
                        text: $t('punctuation')
                    }"
                    @click="openPunctuation()"
                />
            </template>
            <template v-if="getterItemsComposed.length !== 0 && CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.saved !== undefined && CreationGrouper.grouper_data.saved">
                <GhButton
                    :datainput="{
                        id: 'btn_order_item',
                        text: $t('order')
                    }"
                    @click="Global.openPopUp('popup_order')"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'btn_add_item',
                    text: $t('add')
                }"
                @click="checkBeforeAssociate()"
            />
        </div>

        <GhDataSelector
            :datablock="{
                id: 'associate_item',
                class: 'T15_b',
                subtitle: $t('select_associate_item') + ':',
                text: $t('associate'),
                label: $t('associate_item'),
                type: 'radio',
                columns: 3,
                required: false,
                style: 'margin-left: 84px; display: none;',
                popupStyle: 'min-width: 900px;width: 55%;'
            }"
            :datainput="{
                id: 'value_associate_item',
                name: 'associate_item'
            }"
            :feed="getFeeds.feed_exercise_groupers"
            :selected_input="[]"
            @accept="associateSearch"
            @inputs="checkLoadAction"
            @close="checkCloseAssociate"
            ref="refAssociate"
            :key="'KEY4' + refreshSelector"
        />

        <div id="action_add_new_exercise" style="display: none">
            <div style="display: flex; margin-left: 50px;">
                <GhAction
                    :dataction="{
                        id: 'add_new_exercise',
                        text: $t('add_new'),
                        icon: require('../../../../assets/svg/add_plus.svg'),
                    }"
                    @click="openNewExercise"
                />
            </div>
        </div>

        <template v-if="CreationGrouper.grouper_data.show_punctuation">
            <component v-bind:is="components.punctuation"/>
        </template>
        <template v-if="getterItemsComposed.length !== 0 && CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.saved !== undefined && CreationGrouper.grouper_data.saved">
            <component v-bind:is="components.order"/>
        </template>
        <component v-bind:is="components.warning"/>
    </div>
</template>

<script>
    import GhTreeActionBlock from "fe-gh-tree-action-block-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapGetters, mapState} from "vuex";

    import popup_punctuation from "@/view/creation/templates/popup/punctuation.vue";
    import popup_order from "@/view/creation/templates/popup/order.vue";
    import popup_warning_reset_condition from "@/view/creation/templates/popup/warning_reset_condition.vue";

    export default {
        name: "include_elements",
        components:{
            GhTreeActionBlock,
            GhAction,
            GhButton,
            GhDataSelector
        },
        computed:{
            ...mapState(['TabCreation', 'CreationGrouper','CreationGrouperElements','getFeeds']),
            ...mapGetters(['getterItemsComposed', 'getterConditionsAssociated', 'getterItemsOrdered'])
        },
        data(){
            return{
                refreshSelector: 0,
                refreshView: 0,
                refreshTreeAssociatedItems: 0,
                searchHeaderExercise: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, keyFormat: 'style', typeSort: 'code', style: 'min-width: 120px; max-width: 120px; width: 120px;'},
                    {text: 'statement', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 75%;'},
                    {text: 'type_of_exercise', field: 'type_of_exercise', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                searchHeaderGrouper: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, keyFormat: 'style', typeSort: 'code', style: 'min-width: 120px; max-width: 120px; width: 120px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'code', style: 'min-width: 120px; max-width: 120px; width: 40%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                components: {
                    punctuation: popup_punctuation,
                    order: popup_order,
                    warning: popup_warning_reset_condition
                },
                actionsTable: {
                    exercise: [
                        {nameaction: 'view', function: 'seeExercise', icon: require('@/assets/gh_new_dessign/view.svg')}
                    ],
                    grouper: [
                        {nameaction: 'view', function: 'seeGrouper', icon: require('@/assets/gh_new_dessign/view.svg')}
                    ]
                },
                startAxios: false,
                timer: [],
                selectedSearch: ''
            }
        },
        mounted() {
            var that = this;
            window.seeExercise = function(items){
                that.Global.windowOpen(
                    that.Global.openSecondWindow(
                        'config_exercise_view',
                        {
                            id: items.id,
                            view: true,
                            type: items.type,
                            from: 'parent-exercise'
                        }
                    ),'config_example_windowed');
            };
            window.seeGrouper = function(items){
                that.Global.windowOpen(
                    that.Global.openSecondWindow(
                        'config_grouper_view',
                        {
                            id: items.id,
                            grouper: that.selectedSearch,
                            view: true,
                            type: items.type,
                            from: 'parent-grouper'
                        }
                    ),'config_grouper_view');
            };
        },
        methods: {
            async checkLoadAction(){
                var selected = undefined;
                this.$refs.refAssociate.$children[0].$children.forEach((i) => {
                    if(i.selected !== undefined && i.selected){
                        selected = i.datainput.value;
                    }
                });

                if(selected !== undefined) {
                    await this.$store.dispatch('getSearchType', {
                        id: selected
                    },{root:true});

                    var parent = document.getElementById('associate_item_data_selector_popup').children[0].children[1].children[0];
                    var children = '';
                    if(this.CreationGrouperElements.type_search_elements === 'exercise'){
                        if(document.getElementById('associate_item_data_selector_popup') !== null){
                            children = document.getElementById('action_add_new_exercise').children[0];
                            parent.appendChild(children);
                        }
                    }else if(parent.children[2] !== undefined){
                        children = parent.children[2];
                        document.getElementById('action_add_new_exercise').appendChild(children);
                    }
                }
            },
            checkCloseAssociate(){
                var parent = document.getElementById('associate_item_data_selector_popup').children[0].children[1].children[0];
                var children = '';
                if(parent.children[2] !== undefined){
                    children = parent.children[2];
                    document.getElementById('action_add_new_exercise').appendChild(children);
                }
            },
            openNewExercise(){
                var then = this;
                this.checkCloseAssociate();
                this.refreshSelector++;
                this.Global.windowOpen(
                    this.Global.openSecondWindow(
                        'config_exercise_from_grouper',
                        {
                            from: 'grouper-exercise',
                            from_id: then.$root._route.params.id,
                            grouper_id: then.$root._route.params.grouper
                        }
                    ),
                    'new_exercise' + Math.random(),
                    true
                );

                window.UpdatedSelect = async function(e){
                    if(e !== undefined) {
                        await then.$store.dispatch('getItemsComposed', {
                            grouper: then.$root._route.params.grouper,
                            id: then.$root._route.params.id
                        }, {root: true});
                    }
                }

                localStorage.removeItem('is_saved_element');
                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            checkBeforeAssociate(){
                let feed = Object.keys(this.getFeeds.feed_exercise_groupers);
                if(feed.length === 1){
                    this.associateSearch(feed[0])
                }else {
                    this.Global.openPopUp('associate_item_data_selector_popup');
                }
            },
            async associateSearch(checked) {
                var selected = Object.keys(this.$refs.refAssociate.checkdata)[0];
                this.refreshSelector++;
                if(selected === undefined){
                    selected = checked;
                }

                if(selected !== undefined) {
                    await this.$store.dispatch('getSearchType', {
                        id: selected
                    },{root:true});

                    await this.$store.dispatch('getSearchResult', {
                        type_id: selected,
                        grouper_id: this.$root._route.params.id
                    }, {root: true});

                    this.selectedSearch = selected;
                    this.searchAssociate(selected);
                }
            },
            searchAssociate(item){
                var then = this;
                var params = {
                    idblock: 'search_associate',
                    actionsSearch: then.actionsTable[then.CreationGrouperElements.type_search_elements],
                    actionsSelected: then.actionsTable[then.CreationGrouperElements.type_search_elements],
                    actionsNoDropSelected: '',
                    actionsNoDropSearch: '',
                    dataDisabled : [],
                    allmarkoption: false,
                    supportPermision: true,
                    textuserselected: this.getFeeds.feed_exercise_groupers[item] + ' ' + this.$t('selected').toLowerCase(),
                    img: require('../../../../assets/gh_new_dessign/floating.svg'),
                    textNofilter: this.$t('no_filter_has_been_applied'),
                    textNoResult: this.$t('no_results_found'),
                    nameSlot: 'associate_search',
                    nameStore: 'getFeeds',
                    ResultSearch: 'result_search_associate',
                    dataSelected: this.CreationGrouperElements.search_result !== undefined && this.CreationGrouperElements.search_result !== null ? this.CreationGrouperElements.search_result : [],
                    actionresult: 'loadResultSearchAssociate',
                    textlocation: this.$t('add') + ' ' + this.getFeeds.feed_exercise_groupers[item],
                    headersearch: JSON.stringify(this.CreationGrouperElements.type_search_elements === 'exercise' ? this.searchHeaderExercise : this.searchHeaderGrouper),
                    headerselected: JSON.stringify(this.CreationGrouperElements.type_search_elements === 'exercise' ? this.searchHeaderExercise : this.searchHeaderGrouper),
                    formId: 'search',
                    actionsearch: 'getSearchAssociate',
                    checkfilterselected: 1,
                    hiddencheck: false,
                    type: 'checkbox',
                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                }

                localStorage.setItem('Params_' + 'search_associate', JSON.stringify(params));

                this.Global.windowOpen(this.Global.openSecondWindow('GhContentWindowSearchViewExtra',{ idblock: params.idblock,idSearch:item }),'exercises_target', true);

                window.UpdatedSelect = async function (e) {
                    var params = {};
                    then.$parent.$children.forEach((i) => {
                        console.log(i);
                        if(i.$refs['refexamples'] !== undefined){
                            params = {
                                'examples': i.$refs['refexamples'][0].selectedData,
                                'bibliography': i.$refs['refbibliography'][0].selectedData,
                                'support_material': i.$refs['refsupport_material'][0].selectedData,
                                // 'videography': i.$refs['refvideography'][0].selectedData,
                                'good_practices': i.$refs['refgood_practices'][0].selectedData,
                            }
                        }
                    });

                    await then.$store.dispatch('setPreviewSelected', {params: params}, {root: true});

                    await then.$store.dispatch('setNewItemsComposed', {
                        data: e,
                        id: then.$root._route.params.id,
                        block: item
                    }, {root: true});

                    if(then.getterItemsOrdered.length !== 0){
                        await then.$store.dispatch('getDefaultSupport', {id: then.$root._route.params.id}, {root: true});
                    }
                }

                window.reloadwindow = function () {}
                window.closewindow = function () {
                    then.Global.state.window_open = false;
                }
                window.events = function () {}
                this.refreshView++;
            },
            async checkConditions(item, view){
                localStorage.removeItem('is_saved_element');
                await this.$store.dispatch('getInfoCondition', {element_id: item.element_id, item_id: item.item_id}, {root: true});
                await this.$store.dispatch('setConditionAssociate', {
                    grouper_id: this.$root._route.params.grouper,
                    id: this.$root._route.params.id,
                    element_id: item.element_id,
                    item_id: item.item_id
                }, {root: true});

                this.Global.windowOpen(this.Global.openSecondWindow('create_dependences', {id:item.id, view: view}),'create_dependences', true);

                this.startAxios = false;
                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            async checkTypeModify(item){
                localStorage.removeItem('is_saved_element');
                await this.$store.dispatch('getTypeModify',{
                    element_id: item.element_id,
                    item_id: item.item_id,
                }, {root: true}).then((response) => {
                    if(response.data.data.exercise){
                        this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_id_version_float',{
                            parent_grouper_id: this.$root._route.params.grouper,
                            parent_id: this.$root._route.params.id,
                            element_id: item.element_id,
                            id: item.item_id,
                            version: true,
                            type: response.data.data.type
                        }),'view_version', true);
                    }else{
                        this.Global.windowOpen(this.Global.openSecondWindow('config_grouper_id_version_float',{
                            parent_grouper_id: this.$root._route.params.grouper,
                            parent_id: this.$root._route.params.id,
                            grouper: item.element_id,
                            id: item.item_id,
                            version: true,
                            type: response.data.data.type
                        }),'view_version', true);
                    }

                    this.startAxios = true;
                    this.timer = setInterval(this.checkChildWindow, 1000);
                });
            },
            async checkChildWindow() {
                if (this.Global.state.window.closed) {
                    this.Global.state.window_open = false;

                    if(this.startAxios) {
                        if(localStorage.getItem('is_saved_element') == 'true' || localStorage.getItem('is_saved_element') == true) {
                            await this.$store.dispatch('getItemsComposed', {
                                grouper: this.$root._route.params.grouper,
                                id: this.$root._route.params.id
                            }, {root: true});
                        }else if(localStorage.getItem('is_saved_element') !== null && localStorage.getItem('is_saved_element') !== undefined){
                            await this.$store.dispatch('destroyVersionNotSaved', {
                                id: localStorage.getItem('is_saved_element')
                            }, {root: true});
                            localStorage.removeItem('is_saved_element');
                        }
                    }

                    clearInterval(this.timer);
                }
            },
            async openPunctuation(){
                this.Global.openPopUp('popup_punctuation');

                await this.$store.dispatch('getPunctuation', {
                    id: this.$root._route.params.id,
                    grouper: this.$root._route.params.grouper
                }, {root: true});
            }
        }
    }
</script>
<template>
    <div>
        <div class="gh-row-content">
            <GhTextarea
                :datalabel="{
                    text: $t('comment') + '*',
                    class: 'gh_text_field-label T15_b',
                    styles: 'text-align: right; width: 150px !important; min-width: 150px !important;',
                }"
                :datainput="{
                    id: 'action_comment',
                    name: 'action[comment]',
                    value: '',
                    styles: 'width: 100%',
                    required: true
                }"
                minlength="10"
                ref="RefComment"
            />
        </div>
        <div class="gh-row-content">
            <GhEasyUpload
                :extrablock="{
                    id: 'action_attach',
                    class: '',
                    name_hidden: 'action[attach]',
                    id_hidden: 'action_attach',
                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                }"
                :datalabel="{
                    id: '',
                    style: 'margin-right: -15px'
                }"
                :datainput="{
                    view: false,
                    text: $t('attach_send_mail') + '*',
                    accept: '.pdf, .eml',
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: '',
                    file_path: '',
                    file_download: '',
                    required: true
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhTextarea from "fe-gh-textarea-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    export default {
        name: 'action_mail',
        components: {
            GhEasyUpload,
            GhTextarea
        },
        data(){
            return {
                haveData: false
            }
        }
    }
</script>

<style>
    #btn_attach_action_attach_attach_file{
        width: 150px;
        text-align: right;
        margin-right: 25px;
    }
</style>
import ListExercises from "@/view/base_creation/exercises/list_exercises.vue";

import Exercise from './exercises/route';

const route = [
    {
        path: '/config_exercises',
        name: 'config_exercises',
        component: ListExercises,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Exercise
]

export default routes;
import Login from "@/view/login";
import Register from "@/view/register";
import Collaboration from "@/view/request_collaboration/list_request_collaboration.vue";
import AuthService from '@/services/AuthService';
const routes = [
    {
        path: '/sso',
        beforeEnter: async (to, from, next) => {
            try {
                await AuthService.doAuthSSO();
            } catch (error) {
                next(false);
            }
        }
    },
    {
        path: '',
        name: 'login',
        props: {showMenu: false},
        component: Login,
        beforeEnter: async (to, from, next) => {
            try {
                // Call the method from AuthService to get authentication params
                const authParams = await AuthService.getAuthType();
                if (authParams?.auth_type === 'SSO') {
                    AuthService.checkLocalStorageAndCookie(authParams?.token_name, authParams?.cookie_name, authParams?.callback_url);
                }else{
                    next();
                }
            } catch (error) {
                console.error('Error during authentication:', error);
                next(false);
            }
        }
    },
    {
        path: '/register',
        name: 'register',
        props: {showMenu: false},
        component: Register
    },
    {
        path: '/colaboration',
        name: 'colaboration',
        props: {showMenu: false},
        component: Collaboration
    },
];

export default routes;
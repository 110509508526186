var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('type') + ': ' + (_vm.$root._route.params.view ? Object.values(_vm.ConfigTypeExercise.data.type[0])[1] : _vm.$parent.$parent.$refs['RefTypeExercise'].datasaved.text)))])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefAnswers",attrs:{"datalabel":{
                    text: _vm.$t('answers') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_n_answers',
                    name: 'type_exercise[n_answers]',
                    style: 'width: 50px',
                    value: _vm.getterPossibleNumericQuestions.n_answers,
                    type: 'int',
                    limitNumMin: 1
                }},on:{"focusout":_vm.checkNumberPossibleAnswers}}),_c('GhInputTextField',{ref:"RefInterval",attrs:{"datalabel":{
                    text: _vm.$t('interval') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_interval',
                    name: 'type_exercise[interval]',
                    style: 'width: 50px',
                    value: _vm.getterPossibleNumericQuestions.interval,
                    type: 'int',
                    limitNumMin: 1
                }},on:{"focusout":_vm.checkInterval}}),_c('GhInputTextField',{ref:"RefMinRange",attrs:{"datalabel":{
                    text: _vm.$t('min_range') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_min_range',
                    name: 'type_exercise[min_range]',
                    style: 'width: 50px',
                    value: _vm.getterPossibleNumericQuestions.range.min,
                    type: 'int',
                    limitNumMin: 0
                }},on:{"focusout":_vm.checkRange}}),_c('GhInputTextField',{ref:"RefMaxRange",attrs:{"datalabel":{
                    text: _vm.$t('max').toLowerCase() + '*',
                    style: 'margin-left: 10px;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_max_range',
                    name: 'type_exercise[max_range]',
                    style: 'width: 50px',
                    value: _vm.getterPossibleNumericQuestions.range.max,
                    type: 'int',
                    limitNumMin: 1
                }},on:{"focusout":_vm.checkRange}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('answers') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPossibleNumericQuestions.n_answers))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('interval') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPossibleNumericQuestions.interval))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('min_range') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPossibleNumericQuestions.range.min))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('max').toLowerCase() + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPossibleNumericQuestions.range.max))])]],2),(_vm.getterPossibleNumericQuestions.answers.length !== 0)?_c('div',{staticStyle:{"padding-bottom":"10px","margin-top":"5px","padding-left":"160px"},style:(_vm.$root._route.params.view ? '' : 'margin-top: -5px;')},[_c('GhTableCommon',{ref:"RefTableQuestion",staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                id: 'possible_numeric_question_answers'
            },"header":_vm.header,"data":_vm.getterPossibleNumericQuestions.answers},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp, labelProp}){return [(labelProp.field === 'answers')?_c('div',[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:'RefPossibleAnswer' + itemProp.id,attrs:{"datalabel":{
                                text: '',
                                class: '',
                            },"datainput":{
                                id: 'type_exercise_possible_answer_' + itemProp.id,
                                name: 'type_exercise[answers]['+itemProp.id+'][answer]',
                                value: itemProp.answers,
                                required: false,
                                edited: false
                            }},on:{"focusout":function($event){return _vm.setPossibleAnswer(itemProp.id)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",domProps:{"innerHTML":_vm._s(itemProp.answers)}})]],2):_vm._e(),(labelProp.field === 'range')?_c('div',{staticStyle:{"display":"flex","vertical-align":"sub","height":"100%"}},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:'RefPossibleAnswerRangeMin' + itemProp.id,attrs:{"datalabel":{},"datainput":{
                                required: false,
                                id: 'type_exercise_possible_answer_' + itemProp.id,
                                name: 'type_exercise[answers]['+itemProp.id+'][range_min]',
                                value: itemProp.range !== undefined && itemProp.range.min !== undefined ? itemProp.range.min : '',
                                type: 'int',
                                limitNumMin: _vm.getterPossibleNumericQuestions.range.min,
                                limitNumMax: _vm.getterPossibleNumericQuestions.range.max
                            },"disabled":itemProp.id === 0},on:{"focusout":function($event){return _vm.setPossibleAnswer(itemProp.id)}}}),(itemProp.id === 0)?[_c('input',{attrs:{"type":"hidden","name":'type_exercise[answers]['+itemProp.id+'][range_min]'},domProps:{"value":_vm.first}})]:_vm._e(),_c('GhInputTextField',{ref:'RefPossibleAnswerRangeMax' + itemProp.id,attrs:{"datalabel":{},"datainput":{
                                required: false,
                                id: 'type_exercise_possible_answer_' + itemProp.id,
                                name: 'type_exercise[answers]['+itemProp.id+'][range_max]',
                                value: itemProp.range !== undefined && itemProp.range.max !== undefined ? itemProp.range.max : '',
                                type: 'int',
                                limitNumMax: _vm.getterPossibleNumericQuestions.range.max
                            },"disabled":itemProp.id === _vm.getterPossibleNumericQuestions.answers.length - 1},on:{"focusout":function($event){return _vm.setPossibleAnswer(itemProp.id)}}}),(itemProp.id === _vm.getterPossibleNumericQuestions.answers.length - 1)?[_c('input',{attrs:{"type":"hidden","name":'type_exercise[answers]['+itemProp.id+'][range_max]'},domProps:{"value":_vm.last}})]:_vm._e()]:[_c('label',{staticClass:"gh_text_field-label T15 centered",staticStyle:{"width":"35px"}},[_vm._v(_vm._s(itemProp.range !== undefined && itemProp.range.min !== undefined ? itemProp.range.min : ''))]),_c('label',{staticClass:"gh_text_field-label T15"},[_vm._v(_vm._s('-'))]),_c('label',{staticClass:"gh_text_field-label T15 centered",staticStyle:{"width":"35px"}},[_vm._v(_vm._s(itemProp.range !== undefined && itemProp.range.max !== undefined ? itemProp.range.max : ''))])]],2):_vm._e()]}},{key:"actions",fn:function({itemProp}){return [_c('div',{staticStyle:{"display":"ruby-text","vertical-align":"sub","height":"100%"}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'delete_possible_answer_' + itemProp['id'],
                            text: _vm.$t('delete'),
                            icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                            item_prop: itemProp
                        }},on:{"click":function($event){return _vm.deletePossibleAnswer(itemProp)}}})],1)]}}],null,false,80693251)})],1):_vm._e(),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('GhChecks',{ref:"GhCheckConfigEvidence",attrs:{"datalabel":{text: _vm.$t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[0] === 1,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_evidence',
                    value: 1,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}}),_c('GhChecks',{ref:"GhCheckConfigEvidenceRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[1] === 2,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_required_evidence',
                    value: 2,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)}}})],1)]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.feedback[0] === 1,
                disabled: _vm.$root._route.params.view
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('subquestion'),
                style: 'width: 130px',
                id: 'label_type_exercise_subquestion'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_subquestion',
                value: 1,
                name: 'type_exercise[subquestion][]',
                selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                disabled: _vm.$root._route.params.view
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
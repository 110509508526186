import ConfigNumberEmployees from "@/view/config/company/number_employees/template/number_employees.vue";

const routes = [
    {
        path: '/config/company/number-employees',
        name: 'config_number_employees',
        component: ConfigNumberEmployees,
        props: {id: null, showMenu: true, otherTitle: 'number_employees'},
        children: [
            {
                path: '/config/company/number-employees/:id',
                name: 'config_number_employees_id',
                component: ConfigNumberEmployees,
                props: {id: null, showMenu: true, otherTitle: 'number_employees'},
            },
            {
                path: '/config/company/number-employees/:id/:view',
                name: 'config_number_employees_id_view',
                component: ConfigNumberEmployees,
                props: {id: null, view: true, showMenu: true, otherTitle: 'number_employees'},
            }
        ]
    }
];

export default routes;
<template>
    <div>
        <template v-if="haveData">
            <div v-for="(label, labelIndex) in getterProgramsAssociate" :key="'SECTION_PROGRAM_ASSOCIATE' + labelIndex">
                <GhDropSelector
                    :datablock="{
                        id: 'program_associate_' + label.id,
                        title: $t(label['name']),
                        second_title: '',
                        num_results: ''
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="programs_associate_companies" :itinerary="label.id" />
                    </template>
                </GhDropSelector>
            </div>
        </template>
        <template v-else-if="!haveData">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapGetters} from "vuex";

    import programs_associate_companies from "@/view/assign/programs_associate/template/programs_associate_companies.vue";

    export default {
        name: 'list_programs_associate',
        components: {
            GhDropSelector,
        },
        computed: {
            ...mapGetters(['getterProgramsAssociate'])
        },
        data(){
            return {
                haveData: false,
                programs_associate_companies: programs_associate_companies
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getProgramsAssociate', '', {root: true});
            this.haveData = true;
        }
    }
</script>
<template>
    <div class="float_window" style="padding-bottom: 20px;">
        <GhNoDropSelector
            :datablock="{
                id: 'section_clients',
                title: $t('clients')
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <GhTableCommon
                        style="margin-top: 10px;"
                        :extratable="{
                            id: 'table_clients'
                        }"
                        :header="header"
                        :data="getterListClients"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <template v-if="labelProp === 'client'">
                                <router-link :to="{name: 'client_id', params: {
                                    id: itemProp.id
                                }}">
                                    <a class="table-cell_link T13">{{itemProp[labelProp]}}</a>
                                </router-link>
                            </template>
                            <template v-else>
                                <GhChecks
                                    :datainput="{
                                        id: 'partner_club_' + itemProp.id,
                                        value: 1,
                                        type: 'checkbox',
                                        name: 'partner_club[' + itemProp.id + ']',
                                        selected: itemProp[labelProp],
                                        disabled: false
                                    }"
                                    :datalabel="{class: '', id: '', text: ''}"
                                    :ref="'refPartnerClub' + itemProp.id"
                                    @click="setPartnerClub('refPartnerClub' + itemProp.id,itemProp.id)"
                                />
                            </template>
                        </template>
                        <template v-slot:actions="{itemProp}">
                            <router-link :to="{
                                name: 'client_id',
                                params: {id: itemProp.id}
                            }">
                                <GhAction
                                    :dataction="{
                                        id: 'view_client',
                                        text: $t('view'),
                                        icon: require('../../assets/gh_new_dessign/view.svg')
                                    }"
                                />
                            </router-link>
                        </template>
                    </GhTableCommon>
                </template>
                <template v-else>
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </template>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'list_clients',
        components: {
            GhChecks,
            GhNoDropSelector,
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterListClients'])
        },
        data(){
            return {
                header: [
                    {text: 'client', field: 'client', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: 'partner_club', field: 'partner_club', sorting: false, urlRedirect: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getClients', '', {root: true});

                this.haveData = true;
            }
        },
        methods: {
            async setPartnerClub(ref,id){
                await this.$store.dispatch('setPartnerClub', {
                    check: this.$refs[ref]['selected'],
                    id: id
                }, {root: true});
            }
        }
    }
</script>
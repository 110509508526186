<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: getterQuestionnaireEvaluation.name,
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="gh-content-global-questionnaire">
            <div class="question-body">
                <div class="gh-statement-content T15">
                    <div class="T-subtitle" style="margin-left: 10px; margin-top: 2px;">{{$t('oportunity_hard_points')}}</div>
                </div>
                <div class="separator-line" style="margin-top: unset;"></div>
                <form id="form_evaluate_questionnaire">
                    <component :is="component.oportunity_hard_points"/>
                </form>
            </div>
            <div class="centered" style="margin-top: -10px;">
                <GhButton
                    style="margin-bottom: 20px; margin-top: -10px;"
                    class="button-questionnaire-secondary"
                    :datainput="{
                        id: 'btn_close_rate_questionnaire',
                        text: $t('close')
                    }"
                    :title="$t('close')"
                    @click="Global.windowClose()"
                />
                <template v-if="$root._route.params.complete === undefined || $root._route.params.complete === null">
                    <GhButton
                        style="margin-bottom: 20px; margin-top: -10px;"
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_save_evaluation_questionnaire',
                            text: $t('save')
                        }"
                        :title="$t('save')"
                        @click="saveEvaluation()"
                    />
                    <GhButton
                        style="margin-bottom: 20px; margin-top: -10px;"
                        class="button-questionnaire-primary"
                        :datainput="{
                            id: 'btn_finish_rate_questionnaire',
                            text: $t('finish')
                        }"
                        :disabled="!(getterQuestionnaireEvaluation.valorations.hard_points.length !== 0 && getterQuestionnaireEvaluation.valorations.hard_points[0] !== undefined && getterQuestionnaireEvaluation.valorations.hard_points[0].description !== '')"
                        :title="$t('finish')"
                        @click="finishEvaluation()"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import oportunity_hard_points from "@/view/shared/template/oportunity_hard_points.vue";

    export default {
        name: 'view_evaluation',
        components: {
            GhLocation,
            GhButton
        },
        computed: {
            ...mapGetters(['getterQuestionnaireEvaluation'])
        },
        data(){
            return {
                disabledFinish: false,
                component: {
                    oportunity_hard_points: oportunity_hard_points
                },
                haveData: false
            }
        },
        async beforeMount(){
            if(this.$root._route.params.user_id !== undefined && this.$root._route.params.user_id !== ''){
                await this.$store.dispatch('getEvaluationByUser', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    user_id: this.$root._route.params.user_id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then((response) => {
                    if (response.status === 200) {
                        this.haveData = true;
                    }
                });
            }else{
                await this.$store.dispatch('getStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then((response) => {
                    if (response.status === 200) {
                        this.haveData = true;
                    }
                });
            }

            this.disabledFinish = this.getterQuestionnaireEvaluation.valorations.hard_points.length !== 0 && this.getterQuestionnaireEvaluation.valorations.hard_points[0] !== undefined && this.getterQuestionnaireEvaluation.valorations.hard_points[0].description !== ''
        },
        methods: {
            async saveEvaluation(){
                const formData = new FormData(document.getElementById('form_evaluate_questionnaire'));
                await this.$store.dispatch('setStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    type_action: 'save',
                    formData: [...formData],
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then(async (response) => {
                    if (response.status === 200) {
                        this.Global.openPopUp('popup_warning_save');
                        await this.$store.dispatch('getStartEvaluation', {
                            id: this.$root._route.params.id,
                            actual_id: this.$root._route.params.actual_id,
                            type: this.$root._route.params.type,
                            extra_id: this.$root._route.params.extra_id
                        }, {root: true}).then((response) => {
                            if (response.status === 200) {
                                this.refreshComponent++;
                            }
                        });
                    }
                });
            },
            async finishEvaluation(){
                const formData = new FormData(document.getElementById('form_evaluate_questionnaire'));
                await this.$store.dispatch('setStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    type_action: 'finish',
                    formData: [...formData],
                    extra_id: this.$root._route.params.extra_id
                }, {root: true}).then(async (response) => {
                    if (response.status === 200) {
                        this.Global.windowClose();
                    }
                });
            }
        }
    }
</script>
<template>
    <div class="float_window">
        <GhNoDropSelector
            :datablock="{
                id: 'drop_selector_section_itinerary',
                title: $t('assignment')
            }"
        >
            <template v-slot:content v-if="haveData">
                <GhChartTable
                    :extratable="{
                        id: 'itinerary_table_assign'
                    }"
                    :header="header"
                    :data="AssignItinerary.records_data ? AssignItinerary.records_data.dataChartTable : []"
                    :footer="AssignItinerary.records_data ? AssignItinerary.records_data.footerChartTable : []"
                >
                    <template v-slot:actions="{itemProp}">
                        <GhAction
                            :dataction="{
                                id: 'action_assign_' + itemProp.id,
                                text: $t('assignment'),
                                key_show_condition: 'action',
                                value_show_condition: false,
                                item_prop: itemProp
                            }"
                            @click="openAssignPopup(itemProp)"
                        />
                    </template>
                </GhChartTable>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                :datainput="{
                    id: 'btn_user_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'assign_popup',
                title: $t('assignment'),
                type: 'warning',
                style: 'width: 600px; min-width:600px;',
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important;'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="T21" style="margin-left: 15px; margin-right: 20px;">
                    {{$t('to_assign_itinerary') + ':'}}
                </div>
                <div class="gh_delete_confirm-content" style="padding: 0px; margin-left: -10px; margin-right: -10px;">
                    <div style="display: flex; margin-top: 3px; padding-top: 10px; padding-bottom: 10px;">
                        <div class=" T15 label_span-2" style="text-align: right;">
                            {{$t('itinerary') + ':'}}
                        </div>
                        <div class=" T18" style="padding-left: 10px;width: calc(100% / 4 * 3);">
                            {{itinerario.title}}
                        </div>
                    </div>
                </div>
                <form id="form_assign_users" :key="cleanPopUp">
                    <div class="gh-content-global">
                        <input type="hidden" name="assign[id]" :value="itinerario.id">
                        <div class="gh-row-content">
                            <GhMultipleSearch
                                :datalabel="{
                                    text: $t('execution') + '*',
                                    style: 'min-width: 95px; width: 95px; text-align: right',
                                    class: 'T15_b',
                                    id: 'label_assign_ejecution'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'assign_execution_user',
                                    name: 'assign[execution][name]',
                                    nameS:'assign[execution][surname]',
                                    nameId:'assign[execution][id]',
                                    style: 'width: 40%',
                                    value: AssignItinerary.popup_data ? AssignItinerary.popup_data.execution.first_name : '',
                                    valueId: AssignItinerary.popup_data ? AssignItinerary.popup_data.execution.id : '',
                                    valueS: AssignItinerary.popup_data ? AssignItinerary.popup_data.execution.last_name : '',
                                    placeholder: $t('name'),
                                    placeholderS: $t('surnames'),
                                    result_1: 'first_name',
                                    result_2: 'last_name',
                                    result_id: 'id',
                                    href: dispatchUserExecution
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhMultipleSearch
                                :datalabel="{
                                    text: $t('check_2') + '*',
                                    style: 'min-width: 95px; width: 95px; text-align: right',
                                    class: 'T15_b',
                                    id: 'label_assign_revision'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'assign_revision_user',
                                    name:'assign[revision][name]',
                                    nameS:'assign[revision][surname]',
                                    nameId:'assign[revision][id]',
                                    style: 'width: 40%',
                                    value: AssignItinerary.popup_data ? AssignItinerary.popup_data.revision.first_name : '',
                                    valueId: AssignItinerary.popup_data ? AssignItinerary.popup_data.revision.id : '',
                                    valueS: AssignItinerary.popup_data ? AssignItinerary.popup_data.revision.last_name : '',
                                    placeholder: $t('name'),
                                    placeholderS: $t('surnames'),
                                    result_1: 'first_name',
                                    result_2: 'last_name',
                                    result_id: 'id',
                                    href: dispatchUserRevision
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhMultipleSearch
                                :datalabel="{
                                    text: $t('validation') + '*',
                                    style: 'min-width: 95px; width: 95px; text-align: right',
                                    class: 'T15_b',
                                    id: 'label_assign_validation'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'assign_validation_user',
                                    name:'assign[validation][name]',
                                    nameS:'assign[validation][surname]',
                                    nameId:'assign[validation][id]',
                                    style: 'width: 40%',
                                    value: AssignItinerary.popup_data ? AssignItinerary.popup_data.validation.first_name : '',
                                    valueId: AssignItinerary.popup_data ? AssignItinerary.popup_data.validation.id : '',
                                    valueS: AssignItinerary.popup_data ? AssignItinerary.popup_data.validation.last_name : '',
                                    placeholder: $t('name'),
                                    placeholderS: $t('surnames'),
                                    result_1: 'first_name',
                                    result_2: 'last_name',
                                    result_id: 'id',
                                    href: dispatchUserValidation
                                }"
                            />
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_accept_assign_popup',
                        text: $t('assign')
                    }"
                    @click="saveAsignation"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_assign_popup',
                        text: $t('close')
                    }"
                    @click="Global.closePopUp('assign_popup');"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhChartTable from 'fe-gh-chart-table-lib';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhButton from 'fe-gh-button-lib';
    import GhPopUp from 'fe-gh-popup-lib';
    import GhMultipleSearch from 'fe-gh-multiple-search-lib';
    import {mapState} from "vuex";
    export default {
        name: "assignment_view",
        components: {
            GhNoDropSelector,
            GhChartTable,
            GhAction,
            GhButton,
            GhPopUp,
            GhMultipleSearch
        },
        computed: {
            ...mapState(['AssignItinerary'])
        },
        data(){
            return{
                haveData: false,
                cleanPopUp: 0,
                itinerario: {
                    id: '',
                    title: ''
                },
                dispatchUserExecution: 'getExecutionUserSearch',
                dispatchUserRevision: 'getRevisionUserSearch',
                dispatchUserValidation: 'getValidationUserSearch',
                header:[
                  {text: this.$t('title'), field: 'title', style: 'max-width: 50px; width: 25%;', show_drag: true},
                  {text: '', field: 'chart', chart: true, ruler: true, value_ruler: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5}, style: 'max-width: 50px; width: 30%;'},
                  {text: '', field: 'advice', advice: true, style: 'min-width: 50px; width: 1%;'},
                  {text: this.$t('title_column_1'), field: 'col1', sup: 'sup1', style: 'max-width: 50px; width: 15%;', class: 'centered'},
                  {text: this.$t('title_column_2'), field: 'col2', sup: 'sup2', style: 'max-width: 50px; width: 15%;', class: 'centered'},
                  {text: this.$t('title_column_3'), field: 'col3', sup: 'sup3', style: 'max-width: 50px; width: 15%;', class: 'centered'},
                  {text: '', field: 'actions'}
                ],
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getListAssignment', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async openAssignPopup(itemProp){
                this.itinerario.id = itemProp.id;
                this.itinerario.title = itemProp.title;
                await this.$store.dispatch('getAssign', {
                    id: itemProp.id,
                    itinerary_id: this.$root._route.params.id
                }, {root: true});
                this.Global.openPopUp('assign_popup');
            },
            async saveAsignation(){
                if(this.Global.checkRequired('form_assign_users') == 'OK') {
                    const formData = new FormData(document.getElementById('form_assign_users'));
                    await this.$store.dispatch('setUserAssign', {
                        itinerary_id: this.$root._route.params.id,
                        formData: [...formData]
                    }, {root: true}).then(
                        response => {
                            this.Global.closePopUp('assign_popup');
                            this.cleanPopUp++;
                            console.log(response);
                        }
                    )
                }
            }
        }
    }
</script>
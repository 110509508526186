import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        companies: [],
        refreshCompanies: false,
        data_general_companies: [],
        list_group_users:[],
    },
    mutations: {
        loadListCompanies(state, payload){
            state.refreshCompanies = !state.refreshCompanies;
            state.companies = payload;
        },
        loadCompanyDataGeneral(state,payload){
            state.data_general_companies = payload;
        },
        loadGroupUsers(state, payload){
            state.list_group_users = payload;
        },
    },
    getters: {
        getterListPermissionCompanies(state) {
            const dummy = state.refreshCompanies; // eslint-disable-line no-unused-vars
            return state.companies;
        },
    },
    actions: {
        async getListPermCompanies(state){
            let  requestLogin = API_URL + "tree_company";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadListCompanies", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCompanyData(state, items){
            let request = API_URL + "user/company/" + items.id + "/groups";
            await axios({
                method: "get",
                url: request,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadCompanyDataGeneral", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGroupUsers(state,items){
            let  request = API_URL + "user/workers/company/" + items.id;
            let formData = new FormData();
            formData.append("id", items.id);
            await axios({
                method: "get",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadGroupUsers", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setCompaniesGroup(state,item){
            let requestCompaniesGroup =  item.id ? API_URL + "user/company/" + item.id + "/groups" : API_URL + "company";
            let formData = new FormData();

            var defaultArray = [];
            var formArray = [];

            item.defaultData.forEach((i) => {
                defaultArray[i.id] = [];
                i.selected.forEach((x) => {
                    defaultArray[i.id].push(x);
                });

                item.formData.forEach((f) => {
                    var spliter = f[0].split('company[groups][')[1].split(']')[0];
                    if(formArray[spliter] === undefined){
                        formArray[spliter] = [];
                    }

                    if(formArray[spliter].find((a) => a == f[1]) === undefined) {
                        formArray[spliter].push(f[1]);
                    }
                })

            });

            Object.keys(defaultArray).forEach((key) => {
                defaultArray[key].forEach((d) => {
                    if(formArray[key].find((f) => f == d.id) === undefined){
                        formData.append('remove[company][groups][' + key + '][]', d.id);
                    }
                })
            });

            let enter = false;

            Object.values(defaultArray).forEach((i) => {
                if(i.length !== 0){
                    enter = true;
                }
            });

            Object.keys(formArray).forEach((key) => {
                if(enter){
                    defaultArray[key].forEach((d) => {
                        console.log(d);
                        if (formArray[key].find((f) => f == d) === undefined) {
                            formData.append('add[company][groups][' + key + '][]', d);
                        }
                    })
                }else {
                    formArray[key].forEach((d) => {
                        if(defaultArray[key].find((f) => f == d) === undefined){
                            formData.append('add[company][groups][' + key + '][]', d);
                        }
                    });
                }
            });

            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        router.push({name: 'perms'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};

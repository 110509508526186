<template>
    <div class="gh-popup-ellipsis-title">
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_confirm',
                title: $t('reset'),
                type: 'warning',
                style: 'font-size: 20px; width: 400px; min-width: 400px; max-width: 400px;overflow-y: auto !important;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
            ref="RefPopupConfirm"
        >
            <template v-slot:body>
                <div class="T21 centered" style="margin-top: 15px; margin-left: 20px; margin-right: 20px; margin-bottom: 15px;">
                    {{$t(text)}}
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button_accept_popup_confirm',
                        text: $t('accept'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="$emit('accept')"
                />
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_confirm',
                        text: $t('close'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="Global.closePopUp('popup_confirm');"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'popup_advice',
        props: ['text'],
        components: {
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapGetters(['getterAdvice'])
        },
        data(){
            return{

            }
        },
    }
</script>

<style>
    .gh-popup-ellipsis-title > div > div > header > .modal-header > .collapsible-title{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 10px;
    }
</style>
<template>
    <div class="float_window" style="padding-bottom: 20px;">
        <GhNoDropSelector
            :datablock="{
                id: 'section_date_company',
                title: $t('date_company')
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <component :is="component.view_client"/>
                </template>
                <template v-else>
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </template>
            </template>
        </GhNoDropSelector>

        <div v-for="(label, labelIndex) in sections" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'section_' + label.id,
                    title: $t(label['name']),
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>

        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    import view_client from '@/view/business/template/view.vue';
    import view_people from './persons.vue';
    import view_programs from './programs.vue';
    import view_evaluations from './evaluations.vue';

    export default {
        name: 'template_clients',
        components: {
            GhNoDropSelector,
            GhDropSelector,
            GhButton
        },
        data(){
            return {
                haveData: false,
                component: {
                    view_client: view_client
                },
                sections: [
                    {id: 1, name: 'people', component: view_people, result: '', show: true},
                    {id: 2, name: 'programs', component: view_programs, result: '', show: true},
                    {id: 3, name: 'evaluations_and_stamps', component: view_evaluations, result: '', show: true},
                ]
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getActionCompanyData', {
                    id: this.$root._route.params.id
                }, {root: true});

                this.haveData = true;
            }
        }
    }
</script>
import SituationList from "@/view/situation/list_situation";

const routes = [
    {
        path: '/my_situation',
        name: 'my_situation',
        component: SituationList,
        props: {showMenu: true},
        children:[
            {
                path: '/my_situation/:id/:view',
                name: 'my_situation_view',
                component: SituationList,
                props: {id: null, showMenu: true,view:true,  otherTitle: 'my_situation'},
            },
        ]
    }
];

export default routes;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.hideButtons === undefined || (_vm.hideButtons && (_vm.files.length === 0 || _vm.files[0].file_name === '')))?[_c('GhButton',_vm._b({class:_vm.block.class,attrs:{"datainput":{
                id: 'btn_upload_attach_' + _vm.block.id,
                text: _vm.$t(_vm.block.text),
                style: 'display: unset !important'
            }},on:{"click":function($event){return _vm.loadInputClick('input_upload_attach_' + _vm.block.id)}}},'GhButton',_vm.$attrs,false))]:_vm._e(),_c('input',_vm._g(_vm._b({staticStyle:{"display":"none"},attrs:{"accept":_vm.datainput.accept,"id":'input_upload_attach_' + _vm.block.id,"type":"file"},on:{"change":function($event){return _vm.uploadDocument($event)}}},'input',_vm.$attrs,false),_vm.$listeners)),_vm._l((_vm.files),function(item,index){return _c('span',{key:'FILE' + index,staticStyle:{"display":"contents","line-height":"2"}},[_c('span',{staticStyle:{"display":"inline-block"}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                    id: 'input_remove_attach_' + _vm.block.id + index,
                    text: item.file_name,
                }},on:{"click":function($event){return _vm.downloadDocument(item.file_download)}}}),(_vm.datainput.remove)?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"-10px","padding-right":"5px"},attrs:{"dataction":{
                        id: 'input_remove_attach_' + _vm.block.id + index,
                        text: _vm.$t(_vm.datainput.removeText === '' ? '' : _vm.datainput.removeText),
                        icon: _vm.datainput.removeSvg
                    }},on:{"click":function($event){_vm.$attrs.disabled ? '' : _vm.deleteDocument(index)}}})]:_vm._e(),_c('input',{attrs:{"type":"hidden","name":_vm.datainput.name + '['+ index + '][file_name]'},domProps:{"value":item.file_name}}),_c('input',{attrs:{"type":"hidden","name":_vm.datainput.name + '['+ index + '][file_download]'},domProps:{"value":item.file_download}}),_c('input',{attrs:{"type":"hidden","name":_vm.datainput.name + '['+ index + '][file_path]'},domProps:{"value":item.file_path}})],2),(_vm.imageAccept && item.file_name !== '')?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"12px","padding-right":"5px"},attrs:{"dataction":{
                    id: 'input_show_attach_image_' + _vm.block.id + index,
                    text: '',
                    icon: _vm.datainput.lupaSvg
                }},on:{"click":function($event){return _vm.Global.openPopUp('popup_show_image')}}})]:_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import ConfigChannel from "@/view/config/evaluators/channel/template/channel.vue";

const routes = [
    {
        path: '/config/evaluator/channel',
        name: 'config_canal',
        component: ConfigChannel,
        props: {id: null, showMenu: true, otherTitle: 'canal'},
        children: [
            {
                path: '/config/evaluator/channel/:id',
                name: 'config_canal_id',
                component: ConfigChannel,
                props: {id: null, showMenu: true, otherTitle: 'canal'},
            },
            {
                path: '/config/evaluator/channel/:id/:view',
                name: 'config_canal_id_view',
                component: ConfigChannel,
                props: {id: null, view: true, showMenu: true, otherTitle: 'canal'},
            }
        ]
    }
];

export default routes;
<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'profiles',
            }"
            :header="header"
            :data="getterListProfiles.length === 0 ? [] : getterListProfiles"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'permission_profiles_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="itemProp.show">
                    <router-link :to="{name: 'permission_profiles_id', params: {id: itemProp['id']}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_profile_' + itemProp['id'],
                                text: $t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }"
                        />
                    </router-link>
                    <GhAction
                        :dataction="{
                            id: 'btn_delete_profile_' + itemProp['id'],
                            text: $t('delete'),
                            icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                        }"
                        @click="Global.deleteConfirm('delete_confirm','Profile', itemProp.id,{'name': itemProp.name, 'description': itemProp.description, 'groups': itemProp.groups}, $t('delete_profile'), $t('preparing_delete_profile'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'permission_profiles'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_profile',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import GhAction from "fe-gh-action-lib";
import GhButton from "fe-gh-button-lib";

import {mapGetters} from "vuex";



export default {
    name: 'list_profiles',
    components: {
        GhTableCommon,
        GhAction,
        GhButton
    },
    computed: {
        ...mapGetters(['getterListProfiles'])
    },
    data(){
        return {
            header: [
                {text: 'name', field: 'name',urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 20%;'},
                {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 20%;'},
                {text: 'groups', field: 'groups', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 60%;'},
                {text: '', field: 'actions', sorting: false},
            ],
            haveData: false,
        }
    },
    async beforeMount(){
        const token = localStorage.getItem("token");
        if (token) {
            await this.$store.dispatch('getListProfiles', '', {root: true});
            this.haveData = true;
        }
    }
}
</script>
<template>
    <div>
        <GhNoDropSelector
            :key="'REFRESH_BENCHMARK_' + refreshBenchmark"
            :datablock="{
                id: 'sector_benchmark',
                title: $t('benchmark') + ' - ' + (ConfigBenchmark.data !== undefined && ConfigBenchmark.data.country !== undefined ? ConfigBenchmark.data.country : '') + ' - ' + (ConfigBenchmark.data !== undefined && ConfigBenchmark.data.evaluation !== undefined ? ConfigBenchmark.data.evaluation : ''),
            }"
        >
            <template v-slot:actions>
                <GhAction
                    style="margin-left: -27.5vw;"
                    :dataction="{
                        id: 'assign_advisory_evaluator_',
                        text: $t('download_excel'),
                        icon: require('@/assets/gh_new_dessign/descargar.svg')
                    }"
                    @click="$store.dispatch('easyDownload', {url: API_URL +'benchmark/' + $root._route.params.id + '/export'}, {root: true});"
                />
                <form id="form_attach_benchmark" :key="'REFRESH_UPLOAD_' + refreshUpload" style="margin-top: -17px;">
                    <input type="hidden" id="allow_attach_type" value=".xml, .xlsx">
                    <GhEasyUpload
                        :extrablock="{
                            id: 'benchmarck_attach',
                            class: '',
                            name_hidden: 'benchmark[attach]',
                            id_hidden: 'benchmark_attach',
                            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                        }"
                        :datalabel="{
                            id: 'benchmark_attach',
                            style: 'color: red'
                        }"
                        :datainput="{
                            view: $root._route.params.view,
                            accept: '.xml, .xlsx',
                            text: $t('attach_excel'),
                            show_image: false,
                            delete: false,
                            href: 'easyUpload',
                            downloadHref: 'easyDownload',
                            file_name: '',
                            file_path: '',
                            file_download: '',
                            required: false
                        }"
                        ref="RefAttachBenchmark"
                        @change="doAttachFile"
                    />
                </form>
            </template>
            <template v-slot:content>
                <form id="form_benchmark">
                    <input type="hidden" name="benchmarck[country_id]" :value="ConfigBenchmark.data !== undefined && ConfigBenchmark.data.country_id !== undefined ? ConfigBenchmark.data.country_id : ''">
                    <GhTableCommon
                        style="margin-top: 20px;"
                        :extratable="{
                            id: 'table_benchmark_sectors',
                        }"
                        :header="header"
                        :data="ConfigBenchmark.data !== undefined && ConfigBenchmark.data.data !== undefined && ConfigBenchmark.data.data.sectors !== undefined && ConfigBenchmark.data.data.sectors.length !== 0 ? ConfigBenchmark.data.data.sectors : []"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <GhInputTextField
                                :datalabel="{
                                    text: '',
                                    style:'',
                                    class: ''
                                }"
                                :datainput="{
                                    required: true,
                                    id: '',
                                    name: 'benchmarck[sectors][' + itemProp.id +'][' + header.find(item => item.field === labelProp)['id'] +']',
                                    style: '',
                                    value: itemProp[labelProp],
                                    type: 'float',
                                    label_required: $t('column') + ': ' + header.find(item => item.field === labelProp)['text'] + ' ' + $t('row') + ': ' + itemProp['name'],
                                }"
                            />
                        </template>
                    </GhTableCommon>

                    <GhTableCommon
                        style="margin-top: 20px;"
                        :extratable="{
                            id: 'table_benchmark_num_employees',
                        }"
                        :header="header"
                        :data="ConfigBenchmark.data !== undefined && ConfigBenchmark.data.data !== undefined && ConfigBenchmark.data.data.num_employeees !== undefined && ConfigBenchmark.data.data.num_employeees.length !== 0 ? ConfigBenchmark.data.data.num_employeees : []"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <GhInputTextField
                                :datalabel="{
                                    text: '',
                                    style:'',
                                    class: ''
                                }"
                                :datainput="{
                                    required: true,
                                    id: '',
                                    name: 'benchmarck[num_employees][' + itemProp.id +'][' + header.find(item => item.field === labelProp)['id'] +']',
                                    style: '',
                                    value: itemProp[labelProp],
                                    type: 'text',
                                    label_required: $t('column') + ': ' + header.find(item => item.field === labelProp)['text'] + ' ' + $t('row') + ': ' + itemProp['name'],
                                }"
                            />
                        </template>
                    </GhTableCommon>
                </form>
            </template>
        </GhNoDropSelector>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'process_file',
                title: $t('process_file'),
                type: 'add',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <template v-if="!loadFile">
                    <div class="centered">
                        <img :src="require('@/assets/gh_new_dessign/loader.gif')" alt="">
                    </div>
                </template>
                <template v-else>
                    <label class="T15_b gh_text_field-label" style="margin-left: 20px;">{{$t('load_file_correct')}}</label>
                </template>
            </template>
            <template v-slot:footer>
                <template v-if="loadFile">
                    <GhButton
                        :datainput="{
                            id: 'btn_accept',
                            text: $t('accept'),
                        }"
                        @click="submitFile"
                    />
                    <GhButton
                        :datainput="{
                            id: 'button',
                            text: $t('close'),
                            class: 'T19 container-md_button_content',
                            style: 'display: flex;'
                        }"
                        @click="Global.closePopUp('process_file'); refreshUpload++"
                    />
                </template>
            </template>
        </GhPopUp>

        <div class="centered" style="margin-top: 15px; padding-bottom: 15px;">
            <GhButton
                :datainput="{
                    id: 'btn_accept',
                    text: $t('accept'),
                }"
                @click="submit"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="$router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhAction from "fe-gh-action-lib";

    import {API_URL} from "@/common/config";

    export default {
        name: 'view_benchmark',
        components: {
            GhAction,
            GhPopUp,
            GhEasyUpload,
            GhInputTextField,
            GhTableCommon,
            GhButton,
            GhNoDropSelector
        },
        computed: {
            ...mapState(['ConfigBenchmark', 'UploadFiles'])
        },
        data(){
            return{
                haveData: false,
                refreshBenchmark: 0,
                refreshUpload: 0,
                loadFile: 0,
                API_URL: API_URL,
                header: [
                    {text: '', field: 'name', sorting: false, typeSort: 'string', style: 'min-width: 150px; max-width: 150px;'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getBenchmarkData', {id: this.$root._route.params.id}, {root: true});

            if(this.ConfigBenchmark.data !== undefined && this.ConfigBenchmark.data.header !== undefined){
                var num_columns = this.ConfigBenchmark.data.header.length;
                this.ConfigBenchmark.data.header.forEach(item => {
                    var head = {text: item.name, id: item.id, field: item.field, sorting: false, urlRedirect: true, typeSort: 'string', style: 'width: ' + (100/num_columns) + '%'}
                    this.header.push(head);
                });
            }

            this.haveData = true;
        },
        watch: {
            async 'UploadFiles.data'(){
                let that = this;
                setTimeout(async function(){
                    that.loadFile = true;
                }, 200)
            }
        },
        methods:{
            async submit(){
                if(this.Global.checkRequired('form_benchmark') === 'OK') {
                    const formData = new FormData(document.getElementById('form_benchmark'));
                    await this.$store.dispatch('setBenchmarkData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            doAttachFile(){
                this.loadFile = false;
                this.Global.openPopUp('process_file')
            },
            async submitFile(){
                let formData = new FormData(document.getElementById('form_attach_benchmark'));
                await this.$store.dispatch('setBenchmarkUploadAttach', {
                    formData: [...formData],
                    id: this.$root._route.params.id
                }, {root: true}).then(async (response) => {
                    if(response.status === 200) {
                        this.header = [
                            {text: '', field: 'name', sorting: false, typeSort: 'string', style: 'min-width: 150px; max-width: 150px;'},
                        ];

                        await this.$store.dispatch('getBenchmarkData', {id: this.$root._route.params.id}, {root: true});

                        if (this.ConfigBenchmark.data !== undefined && this.ConfigBenchmark.data.header !== undefined) {
                            var num_columns = this.ConfigBenchmark.data.header.length;
                            this.ConfigBenchmark.data.header.forEach(item => {
                                var head = {
                                    text: item.name,
                                    id: item.id,
                                    field: item.field,
                                    sorting: false,
                                    urlRedirect: true,
                                    typeSort: 'string',
                                    style: 'width: ' + (100 / num_columns) + '%'
                                }
                                this.header.push(head);
                            });
                        }

                        this.refreshBenchmark++;

                        this.Global.closePopUp('process_file');
                    }
                });
            }
        }
    }
</script>

<style>
    #easy_upload_attach_benchmarck_attach {
        flex-direction: row !important;
    }
</style>
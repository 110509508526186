<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'general'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigCreation.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>
<script>
    import list_topics from './topics/list_topics.vue';
    import list_types_exercieses from "./types_exercise/list_types_exercises";
    import list_groupers from "./groupers/list_groupers.vue";
    import list_seals from "./seals/list_seals.vue";
    import list_certificates from "./certificates/list_certificates.vue";
    import list_responsibles from "./responsibles/list_responsibles.vue";
    import list_weighting_group from "./weighting_group/list_weighting_group.vue";
    import list_weighting_element from "./weighting_element/list_weighting_element.vue";
    import list_evaluation from './evaluation/list_evaluation.vue';
    import list_evaluation_basis from "./evaluation_basis/list_evaluation_basis.vue";

    import GhDropSelector from 'fe-gh-dropselector-lib';

    import {mapState} from "vuex";

    export default {
        name: "list_creation",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['ConfigCreation','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'topics', component: list_topics, result: 'topics', show: false , second_text: 'results'},
                    {id: 2, name: 'types_of_exercises', component: list_types_exercieses, result: 'type_exercice', show: false , second_text: 'results'},
                    {id: 3, name: 'groupers', component: list_groupers, result: 'groupers', show: false , second_text: 'results'},
                    {id: 4, name: 'stamps', component: list_seals, result: 'stamps', show: false , second_text: 'results'},
                    {id: 5, name: 'certificates', component: list_certificates, result: 'certificates', show: false , second_text: 'results'},
                    {id: 6, name: 'responsibles', component: list_responsibles, result: 'responsibles', show: false , second_text: 'results'},
                    {id: 7, name: 'weighting_group', component: list_weighting_group, result: 'weighting_group', show: false , second_text: 'results'},
                    {id: 7, name: 'weighting_element', component: list_weighting_element, result: 'weighting_element', show: false , second_text: 'results'},
                    {id: 8, name: 'assessment', component: list_evaluation, result: 'assessment', show: false , second_text: 'results'},
                    {id: 9, name: 'evaluation_basis', component: list_evaluation_basis, result: 'evaluation_basis', show: false , second_text: 'results'},
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getConfigCreationCounters', '', {root: true});
            }

            this.haveData = true;

            if(this.Login.tabs.length !== 0){
                this.Login.tabs[4]['sub-modules'][0]['sub-sections'].forEach((subroute,subkey) => {
                    if(this.data[subkey] !== undefined) {
                        this.data[subkey].show = subroute.have_permission;
                    }
                });
            }
        },
    }
</script>

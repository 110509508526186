<template>
    <div v-if="haveData">
        <GhTableCommon v-if="haveData"
            :extratable="{id: 'conf_supp_categories'}"
            :header="header"
            :data="ConfigCategories.records_data.length == 0 ? [] : ConfigCategories.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'category_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13">{{itemProp[labelProp]}}</a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'category_id', params: {id: itemProp.id}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_conf_supp_categories',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_conf_supp_categories',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }"
                    @click="Global.deleteConfirm('delete_confirm','Category', itemProp.id,{'category': itemProp.name}, $t('delete_category'), $t('preparing_delete_category') + ':', 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'category'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_conf_supp_categories',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    export default {
        name: "list_categories",
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed:{
            ...mapState(['ConfigCategories'])
        },
        data(){
            return{
                haveData:false,
                header:[
                  {text: 'category', field: 'name', urlRedirect: true, sorting: true, typeSort: 'string', style: 'width:60%;'},
                  {text: 'flow', field: 'flow', sorting: true, typeSort: 'string', style: 'width:20%;'},
                  {text: 'priority', field: 'priority', sorting: true, typeSort: 'string', style: 'width:20%;'},
                  {text: '', field: 'actions', style: 'width:10%;'},
                ]
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getCategories', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
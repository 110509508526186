<template>
    <div class="gh-content-global" :style="$route.matched[0].props.default.questionnaireBackground !== undefined ? 'margin-left: 5px;' : 'padding-top: 0px;'">
        <template v-if="getterQuestionnaireEvaluation.valorations !== undefined">
            <template v-if="getterQuestionnaireEvaluation.valorations.hard_points !== undefined">
                <div v-for="(itemHardPoints, indexHardPoints) in getterQuestionnaireEvaluation.valorations.hard_points" :key="'VALORATION_HARD_POINTS_' + indexHardPoints + refreshValorations">
                    <div class="gh-row-content radio-check-new-style">
                        <div>
                            <div class="radio-check-new-style" style="width: 80%; display: flex">
                                <template v-if="indexHardPoints === 0">
                                    <label class="gh_text_field-label T-subtitle-second">{{$t('hard_points')}}</label>
                                </template>
                            </div>
                            <div style="width: 20%; text-align-last: right;">
                                <template v-if="getterQuestionnaireEvaluation.valorations.hard_points.length !== 1 && read !== true && !($root._route.params.complete !== undefined && $root._route.params.complete !== null)">
                                    <GhAction
                                        class="color-light-blue T-subtitle-second underline-light-blue"
                                        :dataction="{
                                            id: 'remove_hard_points_' + indexHardPoints,
                                            text: $t('delete'),
                                            icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                        }"
                                        @click="removeValoration(indexHardPoints, 'hard_points')"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="read || ($root._route.params.complete !== undefined && $root._route.params.complete !== null)">
                            <label class="gh_text_field-label T-text">{{itemHardPoints.description}}</label>
                        </template>
                        <template v-else>
                            <GhTextarea
                                :datalabel="{
                                    text: '',
                                    class: 'T-subtitle-second',
                                    styles: ''
                                }"
                                :datainput="{
                                    id: 'evaluate_' + indexHardPoints + '_description_hard_points',
                                    name: 'evaluate[hard_points][' + indexHardPoints + ']',
                                    required: indexHardPoints === 0,
                                    value: itemHardPoints.description,
                                    class: 'new-format-textarea'
                                }"
                                :ref="'RefTextareaDescriptionHardPoints' + indexHardPoints"
                                @focusout="updateContentValoration(indexHardPoints, 'hard_points')"
                            />
                        </template>
                    </div>
                </div>
                <div v-if="!$root._route.params.view && !read && !($root._route.params.complete !== undefined && $root._route.params.complete !== null)" class="gh-row-content" style="margin-left: 7px;">
                    <GhAction
                        class="color-light-blue T-subtitle-second underline-light-blue"
                        :dataction="{
                            id: 'add_more_valorations',
                            text: $t('add_more'),
                            icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                        }"
                        @click="addValoration('hard_points')"
                    />
                </div>
            </template>
            <template v-if="getterQuestionnaireEvaluation.valorations.improvement_opportunities !== undefined">
                <div v-for="(itemImprovementOpportunities, indexImprovementOpportunities) in getterQuestionnaireEvaluation.valorations.improvement_opportunities" :key="'VALORATION_IMPROVEMENT_OPPORTUNITIES_' + indexImprovementOpportunities + refreshValorations">
                    <div class="gh-row-content radio-check-new-style">
                        <div>
                            <div class="radio-check-new-style" style="width: 80%; display: flex">
                                <template v-if="indexImprovementOpportunities === 0">
                                    <label class="gh_text_field-label T-subtitle-second">{{$t('improvement_opportunities')}}</label>
                                </template>
                            </div>
                            <div style="width: 20%; text-align-last: right;">
                                <template v-if="getterQuestionnaireEvaluation.valorations.improvement_opportunities.length !== 1 && read !== true && !($root._route.params.complete !== undefined && $root._route.params.complete !== null)">
                                    <GhAction
                                        class="color-light-blue T-subtitle-second underline-light-blue"
                                        :dataction="{
                                            id: 'remove_improvement_opportunities_' + indexImprovementOpportunities,
                                            text: $t('delete'),
                                            icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                        }"
                                        @click="removeValoration(indexImprovementOpportunities, 'improvement_opportunities')"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="read || ($root._route.params.complete !== undefined && $root._route.params.complete !== null)">
                            <label class="gh_text_field-label T-text">{{indexImprovementOpportunities.description}}</label>
                        </template>
                        <template v-else>
                            <GhTextarea
                                :datalabel="{
                                    text: '',
                                    class: 'T-subtitle-second',
                                    styles: ''
                                }"
                                :datainput="{
                                    id: 'evaluate_' + indexImprovementOpportunities + '_description_improvement_opportunities',
                                    name: 'evaluate[improvement_opportunities][' + indexImprovementOpportunities + ']',
                                    required: false,
                                    value: itemImprovementOpportunities.description,
                                    class: 'new-format-textarea'
                                }"
                                :ref="'RefTextareaDescriptionImprovementOpportunities' + indexImprovementOpportunities"
                                @focusout="updateContentValoration(indexImprovementOpportunities, 'improvement_opportunities')"
                            />
                        </template>
                    </div>
                </div>
                <div v-if="!$root._route.params.view && !read && !($root._route.params.complete !== undefined && $root._route.params.complete !== null)" class="gh-row-content" style="margin-left: 7px;">
                    <GhAction
                        class="color-light-blue T-subtitle-second underline-light-blue"
                        :dataction="{
                            id: 'add_more_improvement_opportunities',
                            text: $t('add_more'),
                            icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                        }"
                        @click="addValoration('improvement_opportunities')"
                    />
                </div>
            </template>
            <template v-if="getterQuestionnaireEvaluation.valorations.good_practices !== undefined">
                <div v-for="(itemGoodPractices, indexGoodPractices) in getterQuestionnaireEvaluation.valorations.good_practices" :key="'VALORATION_GOOD_PRQACTICES_' + indexGoodPractices + refreshValorations">
                    <div class="gh-row-content radio-check-new-style">
                        <div>
                            <div class="radio-check-new-style" style="width: 80%; display: flex">
                                <template v-if="indexGoodPractices === 0">
                                    <label class="gh_text_field-label T-subtitle-second">{{$t('good_practices')}}</label>
                                </template>
                            </div>
                            <div style="width: 20%; text-align-last: right;">
                                <template v-if="getterQuestionnaireEvaluation.valorations.good_practices.length !== 1 && read !== true && !($root._route.params.complete !== undefined && $root._route.params.complete !== null)">
                                    <GhAction
                                        class="color-light-blue T-subtitle-second underline-light-blue"
                                        :dataction="{
                                            id: 'remove_good_practices_' + indexGoodPractices,
                                            text: $t('delete'),
                                            icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                        }"
                                        @click="removeValoration(indexGoodPractices, 'good_practices')"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="read || ($root._route.params.complete !== undefined && $root._route.params.complete !== null)">
                            <label class="gh_text_field-label T-text">{{itemGoodPractices.description}}</label>
                        </template>
                        <template v-else>
                            <GhTextarea
                                :datalabel="{
                                    text: '',
                                    class: 'T-subtitle-second',
                                    styles: ''
                                }"
                                :datainput="{
                                    id: 'evaluate_' + indexGoodPractices + '_description_good_practices',
                                    name: 'evaluate[good_practices][' + indexGoodPractices + ']',
                                    required: false,
                                    value: itemGoodPractices.description,
                                    class: 'new-format-textarea'
                                }"
                                :ref="'RefTextareaDescriptionGoodPractices' + indexGoodPractices"
                                @focusout="updateContentValoration(indexGoodPractices, 'good_practices')"
                            />
                        </template>
                    </div>
                </div>
                <div v-if="!$root._route.params.view && !read && !($root._route.params.complete !== undefined && $root._route.params.complete !== null)" class="gh-row-content" style="margin-left: 7px;">
                    <GhAction
                        class="color-light-blue T-subtitle-second underline-light-blue"
                        :dataction="{
                            id: 'add_more_good_practices',
                            text: $t('add_more'),
                            icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                        }"
                        @click="addValoration('good_practices')"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";
    import GhTextarea from "fe-gh-textarea-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'template_oportunity_hard_points',
        props: ['read'],
        components: {
            GhAction,
            GhTextarea
        },
        computed: {
            ...mapGetters(['getterQuestionnaireEvaluation'])
        },
        data(){
            return {
                refreshValorations: 0
            }
        },
        watch: {
            'getterQuestionnaireEvaluation'(){
                this.refreshValorations++;
            }
        },
        methods: {
            addValoration(type){
                this.$store.dispatch('setLineValoration', {
                    type: type
                }, {root: true});
            },
            removeValoration(key, type){
                this.$store.dispatch('setRemoveLineValoration', {
                    key: key,
                    type: type
                }, {root: true});

                this.refreshValorations++;
            },
            updateContentValoration(key, type){
                let ref = type === 'hard_points' ? 'RefTextareaDescriptionHardPoints' + key : type === 'improvement_opportunities' ? 'RefTextareaDescriptionImprovementOpportunities' + key : 'RefTextareaDescriptionGoodPractices' + key;
                let params = {
                    type: type,
                    description: this.$refs[ref] === undefined || this.$refs[ref][0] === undefined ? '' : this.$refs[ref][0].hiddenValue
                }

                this.$store.dispatch('updateContentValoration', {
                    key: key,
                    params: params
                }, {root: true});
            }
        }
    }
</script>

<style>
    .new-format-textarea{
        padding: 10px !important;
        height: 60px !important;
        margin-top: -5px;
    }

    #add_more_valorations > img {
        margin-top: 0px;
    }

    [id^="remove_more_valorations_"] > img {
        margin-top: 1px;
    }
</style>
import ListGroups from "@/view/perms/groups/list_groups.vue";
import Group from './groups/route';
import Profiles from './profiles/route';

const route = [
    {
        path: '/perms_groups',
        name: 'perms_groups',
        component: ListGroups,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Group,
    ...Profiles
]

export default routes;
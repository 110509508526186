<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'activity_sector', title: $t('activity_sector')}">
            <template v-slot:content>
                <form id="form_activity_sector" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'activity_sector_code',
                                        name: 'activity_sector[code]',
                                        style: 'width: 200px',
                                        value: ConfigActivitySector.data.code,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigActivitySector.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'activity_sector_name',
                                        name: 'activity_sector[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigActivitySector.data.name,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigActivitySector.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 164px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id: 'activity_sector_description',
                                        name: 'activity_sector[description]',
                                        value: ConfigActivitySector.data.description
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigActivitySector.data.description}}</label>
                            </template>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitActivitySector"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "activity_sector_template",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhButton
        },
        computed: {
            ...mapState(['ConfigActivitySector', 'getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getActivitySectorData', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async submitActivitySector(){
                if(this.Global.checkRequired('form_activity_sector') === 'OK') {
                    const formData = new FormData(document.getElementById('form_activity_sector'));
                    this.$store.dispatch('setActivitySectorData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
// import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        historicCompany: [],
        refreshHistoricCompany: false,
        data_historic: [],
        sections:[],
        records:{
            added: [],
            deleted: [],
            selected_state_call: [],
        },

    },
    mutations: {
        loadHistoricCompany(state,payload){
            state.refreshHistoricCompany = !state.refreshHistoricCompany;
            state.historicCompany = payload;
        },
        loadActionDataHistoric(state,payload){
            state.data_historic = payload;
        },
        loadSectionsActions(state, payload) {
            state.sections = payload;
        }
    },
    getters: {
        getterHistoricActionCompany(state){
            const refreshHistoricCompany = state.refreshHistoricCompany; // eslint-disable-line no-unused-vars
            return state.historicCompany;
        }
    },
    actions: {
        async getSectionsActions(state){
            await axios.get(
                API_URL + "commercial-management/company-states"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadSectionsActions', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        exportResultActionHistorical(state,items){
            let requestLogin = API_URL + 'commercial-management' + (items.type === 'indicators' ? '/indicators' : '') + '/search-export';
            let params = {};
            var count_state = 0;
            items.formData.forEach((item) => {
                if(item[0] == 'historical[state][]'){
                    params['historical[state]['+count_state+']'] = item[1];
                    count_state++;
                }else {
                    params[item[0]] = item[1];
                }
            });
            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    let filename = 'downloaded-file.xlsx'; // Default filename

                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActionCompanyData(state,items){
            let requestLogin = API_URL + "user/company/" + items.id;

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        await this.dispatch('setDataCompany', {
                            data: response.data.data
                        });
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getHistoricByCompanyActions(state,items){
            let requestLogin = API_URL + "commercial-management/companies/" + items.id + "/actions";

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadHistoricCompany", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActionDataHistoric(state,items){
            let requestLogin = API_URL + "commercial-management/companies/"+items.id_company+"/actions/"+items.id_register;

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadActionDataHistoric", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setHistoricCompany(state,items){
            let requestLogin = API_URL + "commercial-management/companies/" + items.id + "/actions";
            let formData = new FormData();

            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getHistoricByCompanyActions', {
                            id: items.id
                        });
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";


//import axios from "axios";
//import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: '',
    },
    mutations: {
        loadgetSchedule(state,payload){
            state.records_data=payload
        },
        loadScheduleData(state, payload){
            state.data = payload;
        }
    },
    actions: {
        async getSchedule(state){
            await axios.get(
                API_URL + "support/schedules"
            ).then(
                response => {
                    state.commit('loadgetSchedule', response.data.data);
                }
            )
        },
        getScheduleData(state, items){
            if(items.id === undefined){
                state.commit("loadScheduleData", '');
            }else {
                let requestLogin = API_URL + "support/schedules/"+items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadScheduleData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setScheduleData(state, item){
            let requestLogin = API_URL + "support/schedules/"+item.id;
            let formData = new FormData();
            formData.append('schedule[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });
            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        state.commit("loadScheduleData", []);
                        this.dispatch('getSchedule');
                    }
                    router.push({name: 'configuration'});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

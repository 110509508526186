import ProgramsAssociate from "@/view/assign/programs_associate/list_programs_associate.vue";

const routes = [
    {
        path: '/programs_associate',
        name: 'assign_programs_associate',
        component: ProgramsAssociate,
        props: {showMenu: true}
    },
];

export default routes;
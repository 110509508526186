var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'table_evaluations'
        },"header":_vm.header,"data":_vm.getterListEvaluationsClients},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'joint_map', params: {id: itemProp.id, type: 'initial'}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'view_evaluation',
                        text: _vm.$t('view'),
                        icon: require('@/assets/gh_new_dessign/view.svg')
                    }}})],1),(itemProp.show_action)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'download_report',
                        text: _vm.$t('report'),
                        icon: require('@/assets/gh_new_dessign/doc.svg')
                    }},on:{"click":function($event){return _vm.$store.dispatch('easyDownload', {url: itemProp.file_download}, {root: true})}}})]:_vm._e()]}}],null,false,791424981)})],1):_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_realized_action',
            title: $t('realized_action'),
            type: 'add',
            style: 'width: 50%; min-width:920px;',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_realized_action" :key="'REALIZED_ACTION' + refreshPopup">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15_b" style="text-align: right; width: 150px;">{{$t('realized_action') + '*'}}</label>
                        <GhChecks
                            v-bind:datainput="{
                                id: 'action_type_call',
                                value: 1,
                                type: 'radio',
                                name: 'action[type]',
                                style: 'cursor:pointer;',
                                required: true,
                                label_required: $t('realized_action') + '*'
                            }"
                            v-bind:datalabel="{
                                style: '',
                                class: '',
                                id: '',
                                text: ''
                            }"
                            ref="RefRealizedActionCall"
                            @click="setRealizedAction"
                        />
                        <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('telephone_call')}}</label>
                        <GhChecks
                            v-bind:datainput="{
                                id: 'action_type_mail',
                                value: 2,
                                type: 'radio',
                                name: 'action[type]',
                                style: 'cursor:pointer;',
                                required: true,
                                label_required: $t('realized_action') + '*'
                            }"
                            v-bind:datalabel="{
                                style: '',
                                class: '',
                                id: '',
                                text: ''
                            }"
                            ref="RefRealizedActionMail"
                            @click="setRealizedAction"
                        />
                        <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('mail_send')}}</label>
                    </div>
                    <template v-if="realizedAction === 1">
                        <component :is="component.call"/>
                    </template>
                    <template v-if="realizedAction === 2">
                        <component :is="component.mail"/>
                    </template>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_accept',
                    text: $t('accept'),
                }"
                @click="saveAction()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close'),
                }"
                @click="refreshPopup++; realizedAction = ''; Global.closePopUp('popup_realized_action')"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    import call from './template/call.vue';
    import mail from './template/mail.vue';

    export default {
        name: 'popup_realized_action',
        components: {
            GhPopUp,
            GhChecks,
            GhButton
        },
        data(){
            return{
                refreshPopup: 0,
                realizedAction: '',
                component: {
                    call: call,
                    mail: mail
                }
            }
        },
        methods:{
            setRealizedAction(){
                if(this.$refs['RefRealizedActionCall'].selected){
                    this.realizedAction = this.$refs['RefRealizedActionCall'].datainput.value;
                }

                if(this.$refs['RefRealizedActionMail'].selected){
                    this.realizedAction = this.$refs['RefRealizedActionMail'].datainput.value;
                }
            },
            saveAction(){
                let comment = new Array();
                if(this.$children[0].$children[this.$children[0].$children.length - 1].$refs['RefComment'] !== undefined){
                    let counter = this.$children[0].$children[this.$children[0].$children.length - 1].$refs['RefComment'].hiddenValue.trim().length;
                    if(counter < 10 && counter > 0){
                        comment.push(this.$t('comment') + ' (' + this.$t('min') + ' 10 ' + this.$t('characters').toLowerCase() + ')*');
                    }
                }

                if(this.Global.checkRequired('form_realized_action', comment) === 'OK') {
                    const formData = new FormData(document.getElementById('form_realized_action'));

                    this.$store.dispatch('setHistoricCompany', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.Global.closePopUp('popup_realized_action');

                    this.refreshPopup++;
                    this.realizedAction = '';
                }
            },
        }
    }
</script>
import ListBenchmark from "@/view/config/benchmark/list_benchmark";
import ConfigBenchmark from './benchmarck/route'

const route = [
    {
        path: '/config_benchmark',
        name: 'config_benchmark',
        component: ListBenchmark,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...ConfigBenchmark
]

export default routes;
<template>
    <div v-if="haveData" style="margin-top: 20px;">
        <div style="width: 100%; display: flex;">
            <div class="card-container">
                <template v-for="(itemCard, keyCard) in ToPay.payments">
                    <div :key="'KEY'+ keyCard">
                        <GhCECard
                            style="cursor: default;"
                            :key="'CARD' + keyCard"
                            :block="{
                                image: '',
                                progressBar: true,
                                colors: [
                                    '#0099CC',
                                    '#005392'
                                ],
                                colorsHover: [
                                    '#B7C0C5',
                                    '#B7C0C5'
                                ]
                            }"
                            :data="itemCard"
                        />
                    </div>
                </template>
            </div>
        </div>
        <div class="centered" style="margin-top: 20px;">
            <GhButton
                :datainput="{
                    id: 'btn_contract_elements',
                    text: $t('contract_programs')
                }"
                @click="openContractElement"
            />
            <GhButton
                :datainput="{id: 'btn_back',text: $t('back')}"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhCECard from "@/components/GhCECard.vue";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "to_payment",
        components:{
            GhCECard,
            GhButton,
        },
        computed: {
            ...mapState(["ToPay"]),
        },
        data(){
            return{
                haveData: false
            }
        },
        async mounted() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPayments', {id:this.$root._route.params !== undefined && this.$root._route.params.id !== undefined ? this.$root._route.params.id : ''}, {root: true});

                var r = document.querySelector(':root');
                var color = '#DDDDDD';

                r.style.setProperty('--body_color', color);
                this.haveData = true;
            }
        },
        methods:{
            openContractElement(){
                this.Global.windowOpen(this.Global.openSecondWindow('platform_pay', {type: 'recomended-elements'}),'preview_exercise');

                let that = this;
                window.UpdatedSelect = async function(e){
                    if(e.response === true){
                        await that.$store.dispatch('setPayments', {
                            id: that.$root._route.params !== undefined && that.$root._route.params.id !== undefined ? that.$root._route.params.id : ''
                        }, {root: true});
                    }
                }
            },
            back(){
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
<template>
    <div :style="$store.state.ActionIndicators.searchData && $store.state.ActionIndicators.searchData != [] ? 'margin-top: 12px;' : ''">
        <div v-for="(item, itemIndex) in ActionIndicators.searchData" :key="itemIndex">
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_result_indicators' + item.id,
                    style: 'margin-left: 20px; margin-bottom: 10px; margin-top:10px;',
                    title: item.name + '' + item.date_start + ' - ' + '' + item.date_end,
                    subTitle: '',
                    isDrop: false
                }"
            >
                <template v-slot:content>
                    <div style="margin-right: 20px;">
                        <component :is="view_result_action_indicators" :dataTable="item.data" :parentId="item.id" :where="'list_result_action_indicators'"/>
                    </div>
                </template>
            </GhDropDownField>
        </div>
    </div>
</template>
<script>
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import view_result_action_indicators from "@/view/actions/indicators/result_content/view/result_action_indicators.vue";

    import {mapState} from "vuex";
    export default {
        name: "result_action_indicators",
        computed:{
            ...mapState(['ActionIndicators'])
        },
        components:{
            GhDropDownField
        },
        data(){
            return{
                view_result_action_indicators: view_result_action_indicators,
            }
        },
        async beforeMount(){
            // await this.$store.dispatch('searchActionsIndicatorsData', '', {root: true});
        },
    }
</script>

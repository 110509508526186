<template>
    <div class="float_window">
        <GhDropSelector
            :datablock="{
                id: 'list_around',
                title: $t('environment'),
                second_title: '',
                num_results: '',
            }"
        >
            <template v-slot:content>
                <form id="form_around" v-if="haveData">
                    <div style="display: flex;">
                        <div class="gh-content-global" style="width: 170px;">
                            <div style="width: 168px; height: 168px;">
                                <img :src="around.image" width="168" style="border-radius: 6px; max-height: 168px;">
                            </div>
                            <div class="other-image">
                                <GhEasyUpload
                                    :extrablock="{
                                        id: 'business_logo',
                                        class: '',
                                        name_hidden: 'around[image]',
                                        id_hidden: 'bibliography_other_image',
                                        icon_attach: require('../../../assets/gh_new_dessign/attach.svg')
                                    }"
                                    :datalabel="{
                                        id: 'business_logo',
                                        style: ''
                                    }"
                                    :datainput="{
                                        view: $root._route.params.view,
                                        accept: '.png, .jpg, .jpeg',
                                        text: $t(haveImage ? 'change_logo' : 'attach_logo'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: ConfigAround.data.file_name,
                                        file_path: ConfigAround.data.file_path,
                                        file_download: ConfigAround.data.file_download,
                                        required: false
                                    }"
                                />
                            </div>
                        </div>
                        <div class="gh-content-global" style="padding-left: 50px;">
                            <div class="gh-row-content selector-no-margin-bottom">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'label_around_language',
                                        class: 'T15_b',
                                        subtitle: $t('select_environment_language') + ':',
                                        text: $t('environment_language') + '*',
                                        label: $t('environment_language'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: true,
                                    }"
                                    :datainput="{
                                        id: 'value_around_language',
                                        name: 'around[language]'
                                    }"
                                    :selected_input="ConfigAround.data.language ? ConfigAround.data.language : []"
                                    :feed="getFeeds.feed_languages"
                                />
                            </div>
                            <div class="gh-row-content T15_b">
                                <label class="gh_text_field-label T14">{{$t('environment_palette') + ':'}}</label>
                            </div>
                            <div class="gh-row-content">
                                <div v-for="(pallette, keyPallette) in getFeeds.feed_pallette" :key="keyPallette">
                                    <div style="width: 80px; border-radius: 7px; margin-right: 10px; cursor: pointer;" :style="'border: 4px solid ' + (selectedPallette === pallette.id ? '#b3dbff' : 'black') + ';'" @click="checkPallette(pallette.id)">
                                        <template v-for="(colorPallette, keyColorPallette) in pallette.pallette">
                                            <div style="height: 10px;" :style="'background-color: ' + colorPallette + '; ' + ((pallette.pallette.length - 1) === keyColorPallette ? 'border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;' : keyColorPallette === 0 ? 'border-top-left-radius: 3px; border-top-right-radius: 3px;' : '')" :key="pallette.id + keyColorPallette"/>
                                        </template>
                                    </div>
                                </div>
                                <input type="hidden" name="around[pallette]" :value="selectedPallette">
                            </div>
                            <div class="gh-row-content T15_b">
                                <label class="gh_text_field-label T14">{{$t('environment_font') + ':'}}</label>
                            </div>
                            <div class="gh-row-content">
                                <div v-for="(font, keyFont) in getFeeds.feed_font" :key="keyFont">
                                    <GhChecks
                                        style="margin-right: 10px;"
                                        :datalabel="{
                                            text: '',
                                            style: '',
                                            class: '',
                                            id: ''
                                        }"
                                        :datainput="{
                                            disabled: false,
                                            required: false,
                                            selected: ConfigAround.data.font === font.id,
                                            type: 'radio',
                                            id: 'value_around_font',
                                            value: font.id,
                                            name: 'around[font]'
                                        }"
                                    />
                                    <div style="margin-right: 25px;">
                                        <div>
                                            <label class="T15" :style="'font-family:\'' + font.font + '-Regular\'' +  '!important;'"> {{$t('text_example')}}</label>
                                        </div>
                                        <div>
                                            <label class="T15" :style="'font-family:\'' + font.font + '-SemiBold\'' +  '!important;'"> {{$t('text_example')}}</label>
                                        </div>
                                        <div>
                                            <label class="T15" :style="'font-family:\'' + font.font + '-Bold\'' +  '!important;'"> {{$t('text_example')}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="centered_footer" style="margin-bottom: 10px;">
                    <GhButton v-if="!$root._route.params.view"
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save'),
                        }"
                        @click="preSubmitAround"
                    />
                </div>
            </template>
        </GhDropSelector>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'save_around_popup',
                title: $t('save'),
                type: 'info',
                style: 'width: 500px!important; min-width: 500px!important'
            }"
            :bodyConfiguration="{id: 'save_around_body_popup', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''}"
            :footerConfiguration="{id: 'save_around_footer_popup', class: '', style: ''}"
        >
            <template v-slot:body>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="T15_b">{{$t('this_election_redifined_parametres')}}</label>
                    </div>
                    <div class="gh-row-content">
                        <label class="T15_b">{{$t('are_you_sure')}}</label>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_save_around',
                        text: $t('save'),
                    }"
                    @click="submitAround"
                />
                <GhButton
                    :datainput="{
                        id: 'around_close_btn_popup',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('save_around_popup')"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";
    import GhPopUp from "fe-gh-popup-lib";
    export default {
        name: "list_around",
        components: {
            GhPopUp,
            GhChecks,
            GhDropSelector,
            GhDataSelector,
            GhEasyUpload,
            GhButton
        },
        computed: {
            ...mapState(['ConfigAround','getFeeds','Login', 'UploadFiles'])
        },
        data(){
            return {
                haveData: false,
                around:{
                    image : null
                },
                haveImage: false,
                selectedPallette: 1
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getLanguages', '', {root: true});
                await this.$store.dispatch('getPallette', '', {root: true});
                await this.$store.dispatch('getFont', '', {root: true});
                await this.$store.dispatch('getAroundData', '', {root: true});

                this.around.image = this.ConfigAround.data.image_path !== null ? this.ConfigAround.data.image_path : require('../../../assets/gh_new_dessign/no-image.svg');
                this.selectedPallette = this.ConfigAround.data.pallette;
                this.haveImage = this.ConfigAround.data.image_path !== null;

                this.haveData = true;
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.around.image = data.image;
                this.haveImage = true;
            }
        },
        methods: {
            checkPallette(id){
                this.selectedPallette = id;
            },
            async submitAround(){
                if(this.Global.checkRequired('form_around') == 'OK') {
                    const formData = new FormData(document.getElementById('form_around'));
                    this.$store.dispatch('setAroundData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            preSubmitAround(){
                if(this.Global.checkRequired('form_around') == 'OK') {
                    this.Global.openPopUp('save_around_popup')
                }
            }
        }
    }
</script>

<style>
    #easy_upload_attach_bibliography_other_image{
        flex-flow: wrap-reverse;
        margin-left: -2px;
        margin-bottom: 8px;
    }
     .other-image > .easy_upload_block{
         flex-direction: initial;
     }
    .other-image > div > #business_logo_easy_download_block{
        display: none !important;
    }
</style>

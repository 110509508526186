<template>
    <div style="padding-bottom: 12px;">
        <GhNoDropSelector
            :datablock="{
                id: 'company_file',
                title: $t('company_file'),
                num_results: '',
                label: ''
            }"
        >
            <template v-slot:content>
                <template v-if="haveData">
                    <component v-bind:is="component.CompanyFile" :id="$root._route.params.id" />
                </template>
                <template v-else>
                    <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                        <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </div>
                </template>
            </template>
        </GhNoDropSelector>

        <GhDropSelector
            :datablock="{
                id: 'historic_company',
                title: $t('historic')
            }"
        >
            <template v-slot:content>
                <component v-bind:is="component.CompanyHistoric" :id="$root._route.params.id" />
            </template>
        </GhDropSelector>

        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_realized_action',
                    text: $t('realized_action'),
                }"
                @click="Global.openPopUp('popup_realized_action')"
            />
            <GhButton
                :datainput="{
                    id: 'btn_mail',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>

        <template  v-if="!$root._route.params.view">
            <component v-bind:is="component.PopupRealizedAction"/>
        </template>
    </div>
</template>
<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";

    import {mapState} from "vuex";

    import CompanyFile from '@/view/business/template/view.vue';
    import CompanyHistoric from './company_historic/view_company_historic.vue';
    import PopupRealizedAction from './popup/popup_realized_action.vue';

    export default {
        name: "company_file",
        components: {
            GhNoDropSelector,
            GhDropSelector,
            GhPopUp,
            GhButton
        },
        computed:{
            ...mapState(['TabAction'])
        },
        data(){
            return{
                haveData:true,
                component: {
                    CompanyFile: CompanyFile,
                    CompanyHistoric: CompanyHistoric,
                    PopupRealizedAction: PopupRealizedAction
                }
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getActionCompanyData', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },

    }
</script>


<template>
    <div v-if="haveData">
        <div style="margin-left: 15px; margin-right: 15px; margin-bottom: 10px; margin-top: 10px;">
            <GhTreeActionBlock
                :dataBlock="{
                    id: 'tree_benchmark',
                    actionWithoutChildren: true,
                    actionWithChildren: false,
                    extraLabelNumber: false,
                    extraLabelText: '',
                    loadStorageOpen: true
                }"
                :data="ConfigBenchmark.records_data"
            >
                <template v-slot:Action="Action">
                    <router-link :to="{name: 'config_benchmark_id', params: {id: Action.Action.Action.Action.id}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_config_benchmark',
                                text: $t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }"
                        />
                    </router-link>
                </template>
            </GhTreeActionBlock>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>

    import {mapState} from "vuex";
    import GhTreeActionBlock from "fe-gh-tree-action-block-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'list_benchmark',
        components: {
            GhAction,
            GhTreeActionBlock

        },
        computed: {
            ...mapState(['ConfigBenchmark'])
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getBenchmark', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
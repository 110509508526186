<template>
    <div>
        <form id="form_business">
            <div class="gh-content-global">
                <div class="gh-row-line-flex">
                    <div class="gh-row-column-flex">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="width: 20%; min-width: 165px !important; margin-right: 5px;">{{getterCompanyData.name}}</label>
                    </div>
                    <div class="gh-row-column-flex" style="width: 33%;">
                        <GhDataSelector
                            :datablock="{
                                id: 'social_reason',
                                class: 'T15_b',
                                subtitle: $t('select_social_reason') + ':',
                                text: $t('social_reason') + '*',
                                label: $t('social_reason'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px; width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_social_reason',
                                name: 'business[company][social_reason]'
                            }"
                            :feed="getFeeds.feed_social_reason"
                            :selected_input="getterCompanyData.social_reason"
                            class="none_margin"
                        />
                    </div>
                    <div class="gh-row-column-flex">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('cif') + ':'}}</label>
                        <label class="gh_text_field-label T15_b">{{getterCompanyData.cif}}</label>
                    </div>
                </div>
                <div class="gh-row-line-flex">
                    <div class="gh-row-column-flex">
                        <GhInputTextField
                            style="display: contents !important;"
                            :datalabel="{
                                text: $t('full_adress') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_direction',
                                name: 'business[company][direction]',
                                style: 'width: 50%',
                                value: persistance.company.direction,
                                type: 'text'
                            }"
                            ref="refFullAddress"
                            @blur="setPersistance('refFullAddress')"
                        />
                    </div>
                    <div class="gh-row-column-flex">
                        <GhInputTextField
                            style="display: contents !important;"
                            :datalabel="{
                                text: $t('locality') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_locality',
                                name: 'business[company][locality]',
                                style: 'width: 50%',
                                value: persistance.company.locality,
                                type: 'text'
                            }"
                            ref="refLocality"
                            @blur="setPersistance('refLocality')"
                        />
                    </div>
                    <div class="gh-row-column-flex">
                        <GhInputTextField
                            style="display: contents !important;"
                            :datalabel="{
                                text: $t('postal_code') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_cp',
                                name: 'business[company][cp]',
                                style: 'width: 80px',
                                value: persistance.company.cp,
                                type: 'text'
                            }"
                            ref="refCp"
                            @blur="setPersistance('refCp')"
                        />
                    </div>
                </div>
                <div class="gh-row-line-flex">
                    <div style="width: 33.33%;" class="no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'company_country',
                                class: 'T15_b',
                                subtitle: $t('select_country') + ':',
                                text: $t('country') + '*',
                                label: $t('country'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_company_country',
                                name: 'business[company][country]'
                            }"
                            :feed="getFeeds.feed_country"
                            :selected_input="getterCompanyData.country"
                            @accept="searchProvince"
                            ref="refCountry"
                        />
                    </div>
                    <div style="width: 66.66%;" class="no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'company_province',
                                class: 'T15_b',
                                subtitle: $t('select_province') + ':',
                                text: $t('province') + '*',
                                label: $t('province'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_company_province',
                                name: 'business[company][province]'
                            }"
                            :feed="getterProvinceByCountry !== undefined ? getterProvinceByCountry : []"
                            :selected_input="getterCompanyData.province"
                            ref="refProvince"
                            :key="'refresh_province_' + refreshProvince"
                        />
                    </div>
                </div>
                <div class="gh-row-line-flex">
                    <div style="width: 33.33%;">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('email') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_email',
                                name: 'business[company][email]',
                                style: 'width: 50%',
                                value: persistance.company.email,
                                type: 'text'
                            }"
                            ref="refEmail"
                            @blur="setPersistance('refEmail')"
                        />
                    </div>
                    <div style="width: 66.66%;">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('telephone') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_telephone',
                                name: 'business[company][telephone]',
                                style: 'width: 80px;',
                                value: persistance.company.telephone,
                                type: 'text'
                            }"
                            ref="refTelephone"
                            @blur="setPersistance('refTelephone')"
                        />
                    </div>
                </div>
                <div class="gh-row-content">
                    <GhDataSelector
                        :datablock="{
                            id: 'business_activity',
                            class: 'T15_b',
                            subtitle: $t('select_activity_sector') + ':',
                            text: $t('activity_sector')+'*',
                            label: $t('activity_sector'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_business_activity',
                            name: 'business[company][activity]'
                        }"
                        :feed="getFeeds.feed_activity"
                        :selected_input="getterCompanyData.activity"
                        class="none_margin"
                    />
                </div>
                <div class="gh-row-content">
                    <GhDataSelector
                        :datablock="{
                              id: 'num_employee',
                              class: 'T15_b',
                              subtitle: $t('select_number_employees') + ':',
                              text: $t('number_employees') + '*',
                              label: $t('number_employees'),
                              type: 'radio',
                              columns: 3,
                              required: true,
                              label_required: $t('number_employees'),
                              style: 'margin-left: -5px;',
                              popupStyle: 'min-width: 900px;width: 55%;'
                          }"
                        :datainput="{
                              id: 'value_num_employee',
                              name: 'business[company][num_employee]'
                          }"
                        :feed="getFeeds.feed_num_employers"
                        :selected_input="getterCompanyData.num_employee"
                        class="none_margin"
                    />
                </div>
            </div>
            <div class="section_line" style="margin-top: -7px; ">
                <div class="T15_b" style="padding-left:13px;">{{$t('contact_person')}}</div>
            </div>
            <div class="gh-content-global">
                <div class="gh-row-line-flex">
                    <div style="width: 33.33%; ">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('name') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_contact_person_name',
                                name: 'business[contact_person][name]',
                                style: 'width: 50%',
                                value: persistance.contact_person.name,
                                type: 'text'
                            }"
                            ref="refName"
                            @blur="setPersistance('refName')"
                        />
                    </div>
                    <div style="width: 66.66%;">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('surnames') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_contact_person_surnames',
                                name: 'business[contact_person][surnames]',
                                style: 'width: 25%',
                                value: persistance.contact_person.surnames,
                                type: 'text'
                            }"
                            ref="refSurnames"
                            @blur="setPersistance('refSurnames')"
                        />
                    </div>
                </div>
                <div class="gh-row-line-flex">
                    <div style="width: 33.33%;">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('email') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'company_contact_person_email',
                                name: 'business[contact_person][email]',
                                style: 'width: 50%',
                                value: persistance.contact_person.email,
                                type: 'text'
                            }"
                            ref="refContactPersonEmail"
                            @blur="setPersistance('refContactPersonEmail')"
                        />
                    </div>
                    <div style="width: 66.66%;" class="no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'contact_person_position',
                                class: 'T15_b',
                                subtitle: $t('select_position') + ':',
                                text: $t('position') + '*',
                                label: $t('position'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_contact_person_position',
                                name: 'business[contact_person][position]'
                            }"
                            :feed="getFeeds.feed_positions"
                            :selected_input="getterCompanyData.contact_person.position_selector"
                        />
                    </div>
                </div>
                <div class="gh-row-line-flex">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('telephone') + '*',
                            style:'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'company_contact_person_telephone',
                            name: 'business[contact_person][telephone]',
                            style: 'width: 80px;',
                            value: persistance.contact_person.telephone,
                            type: 'text'
                        }"
                        ref="refContactPersonTelephone"
                        @blur="setPersistance('refContactPersonTelephone')"
                    />
                </div>
            </div>
        </form>
        <div class="centered_footer" style="margin-top: -10px; margin-bottom: 10px;">
            <GhButton
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                type="submit"
                @click="modifyBusiness"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="changeState"
            />
        </div>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters, mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "edit_business",
        components:{
            GhButton,
            GhDataSelector,
            GhInputTextField
        },
        data(){
            return {
                refreshProvince: 0,
                persistance: {
                    company: {
                        direction: '',
                        locality: '',
                        cp: '',
                        email: '',
                        telephone: ''
                    },
                    contact_person: {
                        name: '',
                        surnames: '',
                        email: '',
                        telephone: ''
                    },
                }
            }
        },
        computed: {
            ...mapState(['TabBusiness', 'getFeeds']),
            ...mapGetters(['getterCompanyData', 'getterProvinceByCountry'])
        },
        watch: {
            'getterProvinceByCountry'(){
                this.refreshProvince++;
            },
        },
        async beforeMount(){
            this.persistance.company.direction = this.getterCompanyData.direction;
            this.persistance.company.locality = this.getterCompanyData.locality;
            this.persistance.company.cp = this.getterCompanyData.postal_code;
            this.persistance.company.email = this.getterCompanyData.email;
            this.persistance.company.telephone = this.getterCompanyData.telephone;
            this.persistance.contact_person.name = this.getterCompanyData.contact_person.name;
            this.persistance.contact_person.surnames = this.getterCompanyData.contact_person.surnames;
            this.persistance.contact_person.email = this.getterCompanyData.contact_person.email;
            this.persistance.contact_person.telephone = this.getterCompanyData.contact_person.telephone;
        },
        methods: {
            searchProvince(){
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    this.$store.dispatch('getProvinceByCountry', {
                        country: country,
                    }, {root: true});
                }
            },
            changeState(){
                this.$parent.$parent.view = !this.$parent.$parent.view;
            },
            async modifyBusiness(){
                if(this.Global.checkRequired('form_business') == 'OK') {
                    const formData = new FormData(document.getElementById('form_business'));
                    this.$store.dispatch('setBusinessData', {formData: [...formData]},{root:true});
                    this.$parent.$parent.changeState();
                }
            },
            setPersistance(ref) {
                switch (ref) {
                    case 'refFullAddress':
                        this.persistance.company.direction = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refLocality':
                        this.persistance.company.locality = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refCp':
                        this.persistance.company.cp = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refEmail':
                        this.persistance.company.email = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refTelephone':
                        this.persistance.company.telephone = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refName':
                        this.persistance.contact_person.name = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refSurnames':
                        this.persistance.contact_person.surnames = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refContactPersonEmail':
                        this.persistance.contact_person.email = this.$refs[ref].$props.datainput.value;
                        break;
                    case 'refContactPersonTelephone':
                        this.persistance.contact_person.telephone = this.$refs[ref].$props.datainput.value;
                        break;
                }
            }
        }
    }
</script>

<style>
    .none_margin {
        min-width: 100px !important;
        width: 29% !important;
    }

    #social_reason_selector_label, #business_activity_selector_label, #num_employee_selector_label, #company_country_selector_label, #company_province_selector_label, #contact_person_position_selector_label{
        min-width: 150px !important;
        text-align: right;
    }

    #country_feed_selector_label{
        min-width: 150px !important;
        text-align: right;
    }

    #country_selector_label{
        min-width: 50px !important;
        text-align: right;
    }
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window",staticStyle:{"padding-bottom":"20px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'section_clients',
            title: _vm.$t('clients')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                        id: 'table_clients'
                    },"header":_vm.header,"data":_vm.getterListClients},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [(labelProp === 'client')?[_c('router-link',{attrs:{"to":{name: 'client_id', params: {
                                id: itemProp.id
                            }}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(_vm._s(itemProp[labelProp]))])])]:[_c('GhChecks',{ref:'refPartnerClub' + itemProp.id,attrs:{"datainput":{
                                    id: 'partner_club_' + itemProp.id,
                                    value: 1,
                                    type: 'checkbox',
                                    name: 'partner_club[' + itemProp.id + ']',
                                    selected: itemProp[labelProp],
                                    disabled: false
                                },"datalabel":{class: '', id: '', text: ''}},on:{"click":function($event){return _vm.setPartnerClub('refPartnerClub' + itemProp.id,itemProp.id)}}})]]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{
                            name: 'client_id',
                            params: {id: itemProp.id}
                        }}},[_c('GhAction',{attrs:{"dataction":{
                                    id: 'view_client',
                                    text: _vm.$t('view'),
                                    icon: require('../../assets/gh_new_dessign/view.svg')
                                }}})],1)]}}],null,false,1288088775)})]:[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="float_window">
        <form id="form_search" :key="refresh">
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_supervised_evaluated_start_date',
                            text: $t('date_start'),
                            style: 'width:112px; min-width:112px; text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_supervised_evaluated_start_date',
                            name: 'supervised_evaluated[start_date]',
                            value: ''
                        }"
                    />
                    <GhCalendar
                        :datalabel="{
                            id: 'label_supervised_evaluated_date_end',
                            text: $t('date_end'),
                            style: 'width:85px;text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_ssupervised_evaluated_end_date',
                            name: 'supervised_evaluated[end_date]',
                            value: ''
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhMultipleSearch
                        :datalabel="{
                            text: $t('evaluated_tutored_by'),
                            style: 'width: 179px; min-width: 179px; text-align: right; margin-right: 12px;',
                            class: 'T15_b',
                            id: 'label_supervised_evaluated_user'
                        }"
                        :datainput="{
                            id: 'supervised_evaluated_user',
                            name: 'supervised_evaluated[user][name]',
                            nameS:'supervised_evaluated[user][surname]',
                            nameId:'supervised_evaluated[user][id]',
                            style: 'min-width: 200px;',
                            placeholder: $t('name'),
                            placeholderS: $t('surnames'),
                            result_1: 'first_name',
                            result_2: 'last_name',
                            result_id: 'id',
                            href: dispatchUserSupervisedEvaluated
                        }"
                    />
                </div>
                <div class="gh-row-content" style="">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_supervised_evaluated_and',
                            value: 'and',
                            type: 'radio',
                            name: 'supervised_evaluated[criterio]',
                            style: 'margin-left:40px; cursor:pointer;',
                            selected: true,
                        }"
                        v-bind:datalabel="{
                            style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered')
                        }"
                        @click="selectRadio('GhRadioAND')"
                        ref="GhRadioAND"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_supervised_evaluated_or',
                            value: 'or',
                            type: 'radio',
                            name: 'supervised_evaluated[criterio]',
                            style: 'cursor:pointer;',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                        ref="GhRadioOR"
                        @click="selectRadio('GhRadioOR')"
                    />
                </div>
            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_search', class: 'T19 container-md_button_content', text: $t('search')}" @click="search()"/>
            <GhButton :datainput="{id: 'btn_clean',class: 'T19 container-md_button_content', text: $t('clean')}" @click="clean()"/>
        </div>
    </div>
</template>

<script>
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhMultipleSearch from 'fe-gh-multiple-search-lib';
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "view_search",
        components: {
            GhCalendar,
            GhMultipleSearch,
            GhChecks,
            GhButton
        },
        data() {
            return {
                refresh: 0,
                dispatchUserSupervisedEvaluated: 'getUserSupervisedEvaluated'
            }
        },
        methods: {
            selectRadio(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }
            },
            clean(){
                this.$store.dispatch('destroyedResultSupervisedEvaluatedData','',{root:true});
                document.getElementById("form_search").reset();
                this.refresh++;
            },
            async search(){
                const formData = new FormData(document.getElementById('form_search'));
                await this.$store.dispatch('resultSupervisedEvaluatedData',{formData: [...formData]},{root:true});
            }
        }
    }
</script>
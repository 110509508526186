<template>
    <div>
        <div class="gh-row-content">
            <GhCalendar
                :datalabel="{
                    id: 'label_search_start_date',
                    text: $t('date_from'),
                    style: 'min-width: 125px !important;  width: 125px !important; text-align: right;'
                }"
                :datainput="{
                    id: 'search_start_date',
                    name: 'historical[start_date]',
                    value: '',
                }"
            />
            <GhCalendar
                :datalabel="{
                    id: 'label_search_date_end',
                    text: $t('date_until'),
                    style: 'margin-right: 25px margin-left: 20px; min-width: 77px; !important; width: 40px !important;',
                }"
                :datainput="{
                    id: 'search_end_date',
                    name: 'historical[end_date]',
                    value: '',
                }"
            />
        </div>
        <div class="gh-row-content">
            <GhSimpleSearch
                :datalabel="{
                    text: $t('company'),
                    style: 'width: 102px; text-align:right',
                    class: 'T15_b',
                    id: 'label_search_business'
                }"
                :datainput="{
                    required: false,
                    class: 'inputs',
                    id: 'first_name',
                    name: 'historical[business]',
                    style: 'width: 15%',
                    value: '',
                    placeholder: '',
                    href: dispatchBusiness
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhSimpleSearch from 'fe-gh-simple-search-lib';

    export default {
        name: "view_search",
        components: {
            GhCalendar,
            GhSimpleSearch
        },
        data(){
            return{
                dispatchBusiness: 'getBusinessSearch'
            }
        }
    }
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global",style:(_vm.$route.matched[0].props.default.questionnaireBackground !== undefined ? 'margin-left: 5px;' : 'padding-top: 0px;')},[(_vm.getterQuestionnaireEvaluation.valorations !== undefined)?[(_vm.getterQuestionnaireEvaluation.valorations.hard_points !== undefined)?[_vm._l((_vm.getterQuestionnaireEvaluation.valorations.hard_points),function(itemHardPoints,indexHardPoints){return _c('div',{key:'VALORATION_HARD_POINTS_' + indexHardPoints + _vm.refreshValorations},[_c('div',{staticClass:"gh-row-content radio-check-new-style"},[_c('div',[_c('div',{staticClass:"radio-check-new-style",staticStyle:{"width":"80%","display":"flex"}},[(indexHardPoints === 0)?[_c('label',{staticClass:"gh_text_field-label T-subtitle-second"},[_vm._v(_vm._s(_vm.$t('hard_points')))])]:_vm._e()],2),_c('div',{staticStyle:{"width":"20%","text-align-last":"right"}},[(_vm.getterQuestionnaireEvaluation.valorations.hard_points.length !== 1 && _vm.read !== true && !(_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                                        id: 'remove_hard_points_' + indexHardPoints,
                                        text: _vm.$t('delete'),
                                        icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                    }},on:{"click":function($event){return _vm.removeValoration(indexHardPoints, 'hard_points')}}})]:_vm._e()],2)])]),_c('div',{staticClass:"gh-row-content"},[(_vm.read || (_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?[_c('label',{staticClass:"gh_text_field-label T-text"},[_vm._v(_vm._s(itemHardPoints.description))])]:[_c('GhTextarea',{ref:'RefTextareaDescriptionHardPoints' + indexHardPoints,refInFor:true,attrs:{"datalabel":{
                                text: '',
                                class: 'T-subtitle-second',
                                styles: ''
                            },"datainput":{
                                id: 'evaluate_' + indexHardPoints + '_description_hard_points',
                                name: 'evaluate[hard_points][' + indexHardPoints + ']',
                                required: indexHardPoints === 0,
                                value: itemHardPoints.description,
                                class: 'new-format-textarea'
                            }},on:{"focusout":function($event){return _vm.updateContentValoration(indexHardPoints, 'hard_points')}}})]],2)])}),(!_vm.$root._route.params.view && !_vm.read && !(_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"7px"}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                        id: 'add_more_valorations',
                        text: _vm.$t('add_more'),
                        icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                    }},on:{"click":function($event){return _vm.addValoration('hard_points')}}})],1):_vm._e()]:_vm._e(),(_vm.getterQuestionnaireEvaluation.valorations.improvement_opportunities !== undefined)?[_vm._l((_vm.getterQuestionnaireEvaluation.valorations.improvement_opportunities),function(itemImprovementOpportunities,indexImprovementOpportunities){return _c('div',{key:'VALORATION_IMPROVEMENT_OPPORTUNITIES_' + indexImprovementOpportunities + _vm.refreshValorations},[_c('div',{staticClass:"gh-row-content radio-check-new-style"},[_c('div',[_c('div',{staticClass:"radio-check-new-style",staticStyle:{"width":"80%","display":"flex"}},[(indexImprovementOpportunities === 0)?[_c('label',{staticClass:"gh_text_field-label T-subtitle-second"},[_vm._v(_vm._s(_vm.$t('improvement_opportunities')))])]:_vm._e()],2),_c('div',{staticStyle:{"width":"20%","text-align-last":"right"}},[(_vm.getterQuestionnaireEvaluation.valorations.improvement_opportunities.length !== 1 && _vm.read !== true && !(_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                                        id: 'remove_improvement_opportunities_' + indexImprovementOpportunities,
                                        text: _vm.$t('delete'),
                                        icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                    }},on:{"click":function($event){return _vm.removeValoration(indexImprovementOpportunities, 'improvement_opportunities')}}})]:_vm._e()],2)])]),_c('div',{staticClass:"gh-row-content"},[(_vm.read || (_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?[_c('label',{staticClass:"gh_text_field-label T-text"},[_vm._v(_vm._s(indexImprovementOpportunities.description))])]:[_c('GhTextarea',{ref:'RefTextareaDescriptionImprovementOpportunities' + indexImprovementOpportunities,refInFor:true,attrs:{"datalabel":{
                                text: '',
                                class: 'T-subtitle-second',
                                styles: ''
                            },"datainput":{
                                id: 'evaluate_' + indexImprovementOpportunities + '_description_improvement_opportunities',
                                name: 'evaluate[improvement_opportunities][' + indexImprovementOpportunities + ']',
                                required: false,
                                value: itemImprovementOpportunities.description,
                                class: 'new-format-textarea'
                            }},on:{"focusout":function($event){return _vm.updateContentValoration(indexImprovementOpportunities, 'improvement_opportunities')}}})]],2)])}),(!_vm.$root._route.params.view && !_vm.read && !(_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"7px"}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                        id: 'add_more_improvement_opportunities',
                        text: _vm.$t('add_more'),
                        icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                    }},on:{"click":function($event){return _vm.addValoration('improvement_opportunities')}}})],1):_vm._e()]:_vm._e(),(_vm.getterQuestionnaireEvaluation.valorations.good_practices !== undefined)?[_vm._l((_vm.getterQuestionnaireEvaluation.valorations.good_practices),function(itemGoodPractices,indexGoodPractices){return _c('div',{key:'VALORATION_GOOD_PRQACTICES_' + indexGoodPractices + _vm.refreshValorations},[_c('div',{staticClass:"gh-row-content radio-check-new-style"},[_c('div',[_c('div',{staticClass:"radio-check-new-style",staticStyle:{"width":"80%","display":"flex"}},[(indexGoodPractices === 0)?[_c('label',{staticClass:"gh_text_field-label T-subtitle-second"},[_vm._v(_vm._s(_vm.$t('good_practices')))])]:_vm._e()],2),_c('div',{staticStyle:{"width":"20%","text-align-last":"right"}},[(_vm.getterQuestionnaireEvaluation.valorations.good_practices.length !== 1 && _vm.read !== true && !(_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                                        id: 'remove_good_practices_' + indexGoodPractices,
                                        text: _vm.$t('delete'),
                                        icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                    }},on:{"click":function($event){return _vm.removeValoration(indexGoodPractices, 'good_practices')}}})]:_vm._e()],2)])]),_c('div',{staticClass:"gh-row-content"},[(_vm.read || (_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?[_c('label',{staticClass:"gh_text_field-label T-text"},[_vm._v(_vm._s(itemGoodPractices.description))])]:[_c('GhTextarea',{ref:'RefTextareaDescriptionGoodPractices' + indexGoodPractices,refInFor:true,attrs:{"datalabel":{
                                text: '',
                                class: 'T-subtitle-second',
                                styles: ''
                            },"datainput":{
                                id: 'evaluate_' + indexGoodPractices + '_description_good_practices',
                                name: 'evaluate[good_practices][' + indexGoodPractices + ']',
                                required: false,
                                value: itemGoodPractices.description,
                                class: 'new-format-textarea'
                            }},on:{"focusout":function($event){return _vm.updateContentValoration(indexGoodPractices, 'good_practices')}}})]],2)])}),(!_vm.$root._route.params.view && !_vm.read && !(_vm.$root._route.params.complete !== undefined && _vm.$root._route.params.complete !== null))?_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"7px"}},[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",attrs:{"dataction":{
                        id: 'add_more_good_practices',
                        text: _vm.$t('add_more'),
                        icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                    }},on:{"click":function($event){return _vm.addValoration('good_practices')}}})],1):_vm._e()]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="float_window">
        <GhNoDropSelector
            :datablock="{
                  id: 'drop_selector_section_situation',
                  title: $t('Cuestionario'),
              }"
        >
            <template v-slot:content v-if="haveData">
                <div class="gh-content-global" style="margin-top: 20px;">
                    <form id="form_quizz" ref="form">
                        <component v-bind:is="quizz" />
                    </form>
                </div>
            </template>
        </GhNoDropSelector>
        <GhPopUp v-show="false" :key="video.refresh"
            :generalConfiguration="{
                id: 'video_quizz_popup',
                title: video.name,
                type: 'info',
                style: 'width: 1024px; min-width: 1024px'
            }"
            :bodyConfiguration="{id: 'video_body_quizz_popup', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''}"
            :footerConfiguration="{id: 'video_footer_quizz_popup', class: '', style: ''}"
        >
            <template v-slot:body>
                <video width="1024" height="400" controls>
                    <source :src="video.url">
                </video>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'video_close_btn_popup',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="closeVideo()"
                />
            </template>
        </GhPopUp>
        <div class="centered_footer" style="margin-top: 10px;">
            <GhButton v-if="(stateQuestion === 2 && TabTodo.finished.finish) || (stateQuestion === 1 && !pendingChildrens)"
                :datainput="{
                    id: 'btn_end',
                    text: $t('finish'),
                    class: '',
                    style: ''
                }"
                @click="finish();"
            />
            <GhButton v-if="stateQuestion === 0"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                    class: '',
                    style: ''
                }"
                @click="save();"
            />
            <GhButton
                :datainput="{id: 'btn_back',text: $t('back'),class: '', style: ''}"
                @click="$router.go(-1)"
            />
        </div>
        <div class="gh_text_field-label T15_b centered" style="padding-top: 10px; height: 20px; color: green;">
            <span v-if="TabTodo.finished.finish">{{$t('all_itineraries_exercises_finish')}}</span>
            <span v-else-if="stateQuestion === 2">{{$t('exercise_finish')}}</span>
            <span v-else-if="pendingChildrens">{{$t('to_finish_exercise')}}</span>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    import GhPopUp from "fe-gh-popup-lib";

    import {mapState} from "vuex";

    import quizz from "./quizz.vue";

    export default {
        name: "ejecute_itinerary",
        components: {
            GhNoDropSelector,
            GhButton,
            GhPopUp
        },
        computed: {
            ...mapState(['TabTodo'])
        },
        data(){
            return {
                haveData: false,
                refreshChildren: 0,
                stateQuestion: 0,
                pendingChildrens: false,
                haveDataRefreshed: false,
                toUpdate: true,
                video: {
                    name: '',
                    url: '',
                    refresh: 0
                },
                quizz: quizz
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getExecuteTodo', {
                    id: this.$root._route.params.id,
                    question_id: this.$root._route.params.question_id
                }, {root: true});
                await this.$store.dispatch('getExecuteTodoSupport', {
                    id: this.$root._route.params.id,
                    question_id: this.$root._route.params.question_id
                }, {root: true});
                await this.$store.dispatch('getExecuteTodoFinished', {
                    id: this.$root._route.params.id,
                    question_id: this.$root._route.params.question_id
                }, {root: true});
                this.haveData = true;
                this.haveDataRefreshed = true;
            }
        },
        methods:{
            finish(){
                this.TabTodo.actual_page =  this.setLabelRequired();

                if(this.Global.checkRequired('form_quizz') == 'OK') {
                    const formData = new FormData(document.getElementById('form_quizz'));
                    this.$store.dispatch('setQuizzDataTodo', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        question_id: this.$root._route.params.question_id,
                        finish: true,
                    }, {root: true});
                }
            },
            save(){
                this.TabTodo.actual_page =  this.setLabelRequired();

                if(this.Global.checkRequired('form_quizz') == 'OK') {
                    const formData = new FormData(document.getElementById('form_quizz'));
                    this.$store.dispatch('setQuizzDataTodo', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        question_id: this.$root._route.params.question_id,
                        finish: false,
                    }, {root: true});
                }
            },
            setLabelRequired(){
                var actualPage = this.$refs.form.children[0].__vue__.$refs.quiz.actualPage

                var text = this.TabTodo.data.questionary[actualPage].text;

                var tagHTML = 'input';
                var extraName = '';
                if(this.TabTodo.data.questionary[actualPage].type === 'textarea'){
                    tagHTML = 'textarea';
                }else if(this.TabTodo.data.questionary[actualPage].type === 'checkbox'){
                    extraName = '[]';
                }

                document.querySelectorAll(tagHTML + '[name="quizz[' + this.TabTodo.data.questionary[actualPage].type + '][' + this.TabTodo.data.questionary[actualPage].id + ']' + extraName + '"]').forEach((item) => {
                    item.setAttribute('label_required', this.stripHtml(text));
                });

                return actualPage;
            },
            stripHtml(html){
                let tmp = document.createElement("DIV");
                tmp.innerHTML = html;
                var text = tmp.textContent || tmp.innerText || "";
                tmp.remove();
                return text;
            },
            closeVideo(){
                this.video.name = '';
                this.video.url = '';
                this.video.refresh++;
                this.Global.closePopUp('video_quizz_popup');
            }
        }
    }
</script>
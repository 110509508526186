import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        records_data: '',
        counters: []
    },
    mutations: {
        loadUserCounters(state, payload) {
            state.counters = payload;
        },
        loadUsers(state, payload) {
            state.records_data = payload;
        },
    },
    actions: {
        async getUserCounters(state){
            await axios.get(
                API_URL + "user/worker/count"
            ).then(
                response => {
                    state.commit('loadUserCounters', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUsers(state){
            await axios.get(
                API_URL + "user/workers/company"
            ).then(
                response => {
                    state.commit('loadUsers', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeCompanyUser(state, item){
            let requestLogin = API_URL + "user/worker/" + item.id + "/company";
            let formData = new FormData();

            await axios({
                method: "delete",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getUserCounters');
                        await this.dispatch('getUsers');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async toBuyUsers(state, items){
            let requestLogin = API_URL + "users/payment" + (items.redsys !== undefined && items.redsys ? "/redsys" : "");
            let formData = new FormData();

            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                data: formData,
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        return response;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};

<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'practice_evaluator',
            }"
            :header="header"
            :data="getterPracticeEvaluator"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <span>{{itemProp[labelProp] === 1 ? $t('leader') : (itemProp[labelProp] === 2 ? $t('evaluator') : '')}}</span>
            </template>
            <template v-slot:actions="{itemProp}">
                <GhAction
                    :dataction="{
                        id: 'assign_practice_evaluator_' + itemProp.id,
                        text: $t('assign'),
                        icon: require('../../../../assets/gh_new_dessign/tick.svg')
                    }"
                    @click="assignAction(itemProp.id)"
                />
            </template>
        </GhTableCommon>
    </div>
    <div v-else class="centered" style="padding-bottom: 10px; padding-top: 10px">
        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapGetters} from "vuex";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'practice_evaluator',
        components: {
            GhAction,
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterPracticeEvaluator'])
        },
        data(){
            return{
                actualYear: '',
                pastYear: '',
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 17.30%;'},
                    {text: 'punctuation', field: 'punctuation', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 80px;'},
                    {text: 'ccaa', field: 'ccaa', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 17.30%;'},
                    {text: '', field: 'ev_ep_actual', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 80px;'},
                    {text: '', field: 'ev_ep_past', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 80px;'},
                    {text: 'ev_company', field: 'ev_company', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 80px;'},
                    {text: 'assignment', field: 'assignment', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 65.40%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if(token){
                await this.$store.dispatch('getPracticeEvaluator', {
                    id: this.$root._route.params.id,
                    itinerary: this.$root._route.params.itinerary,
                }, {root: true});

                if(this.$root._route.params.view){
                    delete this.header[7];
                    this.header = this.header.filter(n => n);
                }

                this.actualYear = new Date().getFullYear();
                this.header[3].text = this.$t('ev_ep') + ' ' + this.actualYear;
                this.header[4].text = this.$t('ev_ep') + ' ' + (this.actualYear - 1);
                this.haveData = true;
            }
        },
        methods: {
            assignAction(id){
                this.Global.windowOpen(this.Global.openSecondWindow('assign_evaluator_by_assign', {
                    id: id,
                    view: true,
                    windowed: true,
                    assigned: true,
                    practice: true
                }),'window_evaluator');

                var that = this;

                window.UpdatedSelect = async function(e){
                    if(e !== undefined) {
                        await that.$store.dispatch('setAssignTemporalUsers', {
                            program_id: that.$root._route.params.id,
                            company_id: that.$root._route.params.itinerary,
                            user_id: id,
                            type: e.type
                        }, {root: true}).then(async (response) => {
                            if(response.status === 200){
                                await that.$store.dispatch('getLeadManager', {
                                    id: that.$root._route.params.id,
                                    itinerary: that.$root._route.params.itinerary,
                                }, {root: true});

                                await that.$store.dispatch('getAdvisoryEvaluator', {
                                    id: that.$root._route.params.id,
                                    itinerary: that.$root._route.params.itinerary,
                                }, {root: true});

                                await that.$store.dispatch('getPracticeEvaluator', {
                                    id: that.$root._route.params.id,
                                    itinerary: that.$root._route.params.itinerary,
                                }, {root: true});

                                await that.$store.dispatch('getUsersAssigned', {
                                    id: that.$root._route.params.id,
                                    itinerary: that.$root._route.params.itinerary,
                                }, {root: true}).then((users) => {
                                    if(users.status === 200){
                                        that.$parent.$parent.leaderText = users.data.data.leader;
                                        that.$parent.$parent.evaluatorText = users.data.data.evaluators;
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }
    }
</script>
<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in TabEvaluation.sections" :key="labelIndex">
            <GhDropSelector
                :datablock="{
                    id: 'evaluation_' + label.id,
                    title: $t(label.name),
                    second_title: $t(label.second_text),
                    num_results: label.result,
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="table_evaluations" :id="label.id"></component>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import table_evaluations from './template/table_evaluations.vue';
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_evaluations",
        components: {
            GhDropSelector
        },
        computed: {
            ...mapState(['TabEvaluation','Login'])
        },
        data(){
            return {
                haveData: false,
                havePermission: false,
                table_evaluations: table_evaluations
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getEvaluationSections', '', {root: true});

                this.haveData = true;
            }
        }
    }
</script>
import Tutor from "@/view/tutor/template/tutor.vue";

const routes = [
    {
        path: '/tutor/:id',
        name: 'tutor_id',
        component: Tutor,
        props: {id: null, showMenu: false,background:true, otherTitle: 'tutor'},
    },
];

export default routes;
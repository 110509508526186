import ConfigAssgnment from "@/view/config/support/assignment/template/assignment";
import Formsearchcompany from "@/view/config/support/assignment/template/search_content/view_assignment";

const routes = [
    {
        path: '/config/support/assignment',
        name: 'new_assignment',
        component: ConfigAssgnment,
        props: {id: null, showMenu: true, otherTitle: 'assignment'},
        children: [
            {
                path: '/config/support/assignment/:id',
                name: 'assignment_id',
                component: ConfigAssgnment,
                props: {id: null, showMenu: true,  otherTitle: 'assignment'},
            },
            {
                path: '/config/support/assignment/:id/:view',
                name: 'assignment_view',
                component: ConfigAssgnment,
                props: {id: null, showMenu: true,  otherTitle: 'assignment'},
            },
        ]
    },
    {
        path: '/view_assignment',
        name: 'view_assignment',
        component: Formsearchcompany,
        props: {id: null,showMenu: true, otherTitle: 'assignment'}
    },
];
export default routes;

<template>
    <div style="padding-bottom: 8px;" v-if="getterTypeExercise.support !== undefined && (getterTypeExercise.support.metrics.view || getterTypeExercise.support.examples.view || getterTypeExercise.support.bibliography.view || getterTypeExercise.support.videography.view || getterTypeExercise.support.support_material.view || getterTypeExercise.support.good_practices.view) && haveData">
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('support_material_2')}}</div>
        </div>
        <div class="grid-container-2" v-if="supports.length !== 0">
            <div class="grid-item min-width-490" v-for="(supportItem, supportKey) in supports" :key="'KEY64' + supportKey">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhSearchWindow
                        :dataBlock="{
                            label: {
                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                            },
                            ResultSearch: 'result_search_supports',
                            nameStore: 'getFeeds',
                            imagelocation: require('../../../../../../assets/gh_new_dessign/floating.svg'),
                            id: supportItem.id,
                            text: $t(supportItem.name) + (getterTypeExercise.support !== undefined  && getterTypeExercise.support[supportItem.id] !== undefined && getterTypeExercise.support[supportItem.id].required ? '*' : ''),
                            textlocation: $t('add') + ' ' + $t(supportItem.name),
                            actionresult: 'loadResultSearchSupports',
                            nameResult: 'specialty[' + supportItem.id + ']',
                            supportPermision: false,
                            loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif'),
                            required: getterTypeExercise.support !== undefined  && getterTypeExercise.support[supportItem.id] !== undefined ? getterTypeExercise.support[supportItem.id].required : false
                        }"
                        :mainSelector="{
                            name: 'support_search',
                            formId: 'search',
                            actionsearch: 'getSearchSupports'
                        }"
                        :tableResolt="{
                            header: header[supportItem.id],
                            actionsSearch: actionsTable[supportItem.id],
                            data: []
                        }"
                        :tableSelected="{
                            headersummary: summary[supportItem.id],
                            actionsSearch: actionsTable[supportItem.id],
                            actionsSelected: actionsTable[supportItem.id],
                            disabled: [],
                            selected: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data[supportItem.id] !== undefined ? BaseCreationExerciseTable.exercise_data[supportItem.id] : [],
                        }"
                        :ref="'ref' + supportItem.id"
                    />
                </template>
                <template v-else>
                    <div style="display: flex;">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px!important; min-width: 150px!important; text-align: right;">{{$t(supportItem.id) + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data[supportItem.id] !== undefined ? Object.values(viewSummary[supportItem.id]).join(', ') : ''}}</label>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import GhSearchWindow from "fe-gh-float-window-lib";

import {mapGetters, mapState} from "vuex";

import metrics from "@/view/base_creation/exercises/exercises/template/shared/metrics.vue";

export default {
    name: 'support_section',
    components: {
        GhSearchWindow
    },
    computed: {
        ...mapState(['BaseCreationExerciseTable']),
        ...mapGetters(['getterTypeExercise'])
    },
    data(){
        return {
            haveData: false,
            toUpdate: true,
            components: {
                metrics: metrics
            },
            supports: [],
            actionsTable: {
                examples: [
                    {nameaction: 'view', function: 'seeExamples', icon: require('@/assets/gh_new_dessign/view.svg')}
                ],
                bibliography: [
                    {nameaction: 'view', function: 'seeBibliography', icon: require('@/assets/gh_new_dessign/view.svg')}
                ],
                support_material: [
                    {nameaction: 'view', function: 'seeSupportMaterial', icon: require('@/assets/gh_new_dessign/view.svg')}
                ],
                good_practices: [
                    {nameaction: 'view', function: 'seeGoodPractices', icon: require('@/assets/gh_new_dessign/view.svg')}
                ],
            },
            header:{
                examples: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'title', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'author', field: 'author', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'editor', field: 'editor', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'publication_date', field: 'publication_date', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                    {text: 'references', field: 'references', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                bibliography: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'title', field: 'title', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'author', field: 'author', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'editor', field: 'editor', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'publication_date', field: 'publication_date', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                    {text: 'references', field: 'references', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                support_material: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'title', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'author', field: 'author', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'editor', field: 'editor', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'publication_date', field: 'publication_date', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                    {text: 'references', field: 'references', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                good_practices: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'title', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'author', field: 'author', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'editor', field: 'editor', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'publication_date', field: 'publication_date', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                    {text: 'references', field: 'references', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
            },
            summary: {
                examples: {
                    0: 'name'
                },
                bibliography: {
                    0: 'title'
                },
                support_material: {
                    0: 'name'
                },
                good_practices: {
                    0: 'name'
                },
            },
            viewSummary: {
                examples: [],
                bibliography: [],
                support_material: [],
                good_practices: [],
            },
        }
    },
    beforeMount() {
        this.supports = [];
        if (this.getterTypeExercise.support !== undefined) {
            if (this.getterTypeExercise.support.good_practices !== undefined && this.getterTypeExercise.support.good_practices.view) {
                this.supports.push({'id': 'good_practices','name': 'good_practices'});
            }

            if (this.getterTypeExercise.support.examples !== undefined && this.getterTypeExercise.support.examples.view) {
                this.supports.push({'id': 'examples','name': 'examples'});
            }

            if (this.getterTypeExercise.support.bibliography !== undefined && this.getterTypeExercise.support.bibliography.view) {
                this.supports.push({'id': 'bibliography','name': 'bibliography'});
            }

            if (this.getterTypeExercise.support.support_material !== undefined && this.getterTypeExercise.support.support_material.view) {
                this.supports.push({'id': 'support_material','name': 'complementary'});
            }
        }

        if(this.$root._route.params.view || this.$root._route.params.type === 'enabled'){
            Object.entries(this.summary).forEach((item) => {
                if(this.BaseCreationExerciseTable.exercise_data !== undefined && this.BaseCreationExerciseTable.exercise_data[item[0]] !== undefined){
                    this.BaseCreationExerciseTable.exercise_data[item[0]].forEach((subItem) => {
                        this.viewSummary[item[0]].push(subItem[item[1][0]]);
                    });
                }
            });
        }

        this.haveData = true;
    },
    watch:{
        'getterTypeExercise'() {
            this.supports = [];
            if (this.getterTypeExercise.support !== undefined) {
                if (this.getterTypeExercise.support.good_practices !== undefined && this.getterTypeExercise.support.good_practices.view) {
                    this.supports.push({'id': 'good_practices','name': 'good_practices'});
                }

                if (this.getterTypeExercise.support.examples !== undefined && this.getterTypeExercise.support.examples.view) {
                    this.supports.push({'id': 'examples','name': 'examples'});
                }

                if (this.getterTypeExercise.support.bibliography !== undefined && this.getterTypeExercise.support.bibliography.view) {
                    this.supports.push({'id': 'bibliography','name': 'bibliography'});
                }

                if (this.getterTypeExercise.support.support_material !== undefined && this.getterTypeExercise.support.support_material.view) {
                    this.supports.push({'id': 'support_material','name': 'complementary'});
                }
            }
            this.haveData = true
        }
    },
    mounted(){
        var that = this;
        window.seeExamples = function(items){
            that.Global.windowOpen(that.Global.openSecondWindow('config_example_id_view_windowed',{id: items.id, view: true, windowed: true}),'config_example_windowed');
        };
        window.seeBibliography = function(items){
            that.Global.windowOpen(that.Global.openSecondWindow('config_bibliography_id_view_windowed',{id: items.id, view: true, windowed: true}),'config_bibliography_windowed');
        };
        window.seeSupportMaterial = function(items){
            that.Global.windowOpen(that.Global.openSecondWindow('config_support_material_id_view_windowed',{id: items.id, view: true, windowed: true}),'config_support_material_windowed');
        };
        window.seeGoodPractices = function(items){
            that.Global.windowOpen(that.Global.openSecondWindow('config_good_practices_id_view_windowed',{id: items.id, view: true, windowed: true}),'config_good_practices_windowed');
        };
    },
    methods: {

    }
}
</script>
<template>
    <div v-if="haveData">
        <GhTableCommon
            style="margin-top: 10px;"
            :extratable="{
                id: 'evaluators',
            }"
            :header="header"
            :data="getterListPermissionEvaluators.length === 0 ? [] : getterListPermissionEvaluators"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'permission_users_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";

import {mapGetters} from "vuex";

export default {
    name: 'list_evaluators',
    components: {
        GhTableCommon,
    },
    computed: {
        ...mapGetters(['getterListPermissionEvaluators'])
    },
    data(){
        return {
            header: [
                {text: 'name', field: 'firstname', sorting: true, urlRedirect: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                {text: 'surnames', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                {text: 'group', field: 'group', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'},
            ],
            haveData: false,
        }
    },
    async beforeMount(){
        const token = localStorage.getItem("token");
        if (token) {
            await this.$store.dispatch('getListPermissionEvaluators', '', {root: true});
            this.haveData = true;
        }
    }
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.getterTypeExercise.responsible.view)?_c('div',{staticClass:"gh-row-content",style:(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' ? 'margin-top: -10px;margin-bottom: 10px;' : '')},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'radio',
                    id: 'label_exercise_close_one_correct_responsible',
                    label: _vm.$t('responsible') + (_vm.getterTypeExercise.responsible.required ? '*' : ''),
                    title: _vm.$t('responsible'),
                    text: _vm.$t('select_responsible') + ':',
                    name: 'exercise[close_one_correct][responsible]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: _vm.getterTypeExercise.responsible.required,
                    checkparentdata: false,
                    style: '',
                    label_style: 'width: 149px',
                    popupStyle: 'min-width: 900px; width: 55%;'
                },"selected_input":_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.responsible ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.responsible : [],"items":_vm.getFeeds.feed_responsible}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.responsible && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.responsible[0] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.responsible[0].text : ''))])]],2):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhInputTextField',{staticStyle:{"margin-top":"-5px"},attrs:{"datalabel":{
                        text: _vm.$t('name') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'exercise_close_one_correct_statement',
                        name: 'exercise[close_one_correct][statement]',
                        style: 'width: 600px',
                        value: _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.statement : '',
                    },"maxlength":"100"}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.statement : '')}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhTextarea',{staticStyle:{"width":"100%"},attrs:{"datalabel":{
                        text: _vm.$t('description'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_close_one_correct_description',
                        name: 'exercise[close_one_correct][description]',
                        value: _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.description : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.description : '')}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhTextarea',{staticStyle:{"width":"100%"},attrs:{"datalabel":{
                        text: _vm.$t('statement'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_close_one_correct_real_statement',
                        name: 'exercise[close_one_correct][real_statement]',
                        value: _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.real_statement : '',
                        required: false,
                        edited: false
                    }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('statement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.real_statement : '')}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhTextarea',{staticStyle:{"width":"100%"},attrs:{"datalabel":{
                        text: _vm.$t('substatement'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_close_one_correct_substatement',
                        name: 'exercise[close_one_correct][substatement]',
                        value: _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.substatement : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('substatement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.substatement : '')}})]],2),(_vm.getterTypeExercise.information.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('information') + (_vm.getterTypeExercise.information.required ? '*' : ''),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_close_one_correct_information',
                    name: 'exercise[close_one_correct][information]',
                    value: _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.information : '',
                    required: _vm.getterTypeExercise.information.required,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('information') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.information : '')}})]],2):_vm._e(),(_vm.getterTypeExercise.score_legend.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('score_legend') + (_vm.getterTypeExercise.score_legend.required ? '*' : ''),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_close_one_correct_score_legend',
                    name: 'exercise[close_one_correct][score_legend]',
                    value: _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.score_legend : '',
                    required: _vm.getterTypeExercise.score_legend.required,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('score_legend') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.score_legend : '')}})]],2):_vm._e(),_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"5px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(''))])]),(_vm.getterTypeExercise.possible_answers.length !== 0)?[_c('GhTableCommon',{staticStyle:{"margin-bottom":"10px"},attrs:{"extratable":{
                id: 'answers'
            },"header":_vm.header,"data":_vm.getterTypeExercise.possible_answers},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp, labelProp}){return [(labelProp.field == 'answer')?_c('div',[(itemProp.view)?[_vm._v(" "+_vm._s(itemProp.answer)+" ")]:[_c('GhInputTextField',{staticStyle:{"margin-left":"-4px"},attrs:{"datalabel":{},"datainput":{
                                required: labelProp.required,
                                id: 'exercise_close_one_correct_answer_answer_' + itemProp.id,
                                name: 'exercise[close_one_correct][answer][answer]['+itemProp.id+']',
                                value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.answer !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.answer[itemProp.id] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.answer[itemProp.id] : '',
                            }}})]],2):_vm._e(),(labelProp.field == 'punctuation')?_c('div',[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhInputTextField',{attrs:{"datalabel":{},"datainput":{
                                type: 'int',
                                required: labelProp.required,
                                id: 'exercise_close_one_correct_answer_punctuation_' + itemProp.id,
                                name: 'exercise[close_one_correct][answer][punctuation]['+itemProp.id+']',
                                value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.punctuation !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.punctuation[itemProp.id] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.punctuation[itemProp.id] : '',
                            }}})]:[_vm._v(" "+_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.punctuation !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.punctuation[itemProp.id] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.punctuation[itemProp.id] : '')+" ")]],2):_vm._e()]}}],null,false,3499392274)})]:_vm._e(),(_vm.getterTypeExercise.evidence.view)?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('punctuation') + ':'))]),(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('evidence') + (_vm.getterTypeExercise.autoevaluation.checked ? '*' : ''),
                    style: '',
                    class: 'T15_b'
                },"datainput":{
                    required: _vm.getterTypeExercise.autoevaluation.checked,
                    id: 'exercise_close_one_correct_punctutation_evidence',
                    name: 'exercise[close_one_correct][punctuation][evidence]',
                    style: 'width: 50px',
                    value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.punctuation !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.punctuation.evidence !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.punctuation.evidence : '',
                    type: 'int'
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('evidence') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.punctuation !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.punctuation.evidence !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.punctuation.evidence : ''))])]],2):_vm._e(),(_vm.getterTypeExercise.evidence.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('text_before_download') + (_vm.getterTypeExercise.evidence.required ? '*' : ''),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_close_one_correct_text_evidence_before_download',
                    name: 'exercise[close_one_correct][text_evidence][before_download]',
                    value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.before_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.before_download : '',
                    required: _vm.getterTypeExercise.evidence.required,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                },"maxlength":"128"}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('text_before_download') + ''))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.before_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.before_download : '')}})]],2):_vm._e(),(_vm.getterTypeExercise.evidence.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('attach_template') + (_vm.getterTypeExercise.evidence.required ? '*' :'')))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'easy_upload_template',
                    class: '',
                    style: 'flex-direction: row !important;',
                    name_hidden: 'exercise[close_one_correct][text_evidence][template]',
                    id_hidden: 'example',
                    icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                },"datalabel":{
                    id: 'label_easy_upload_template',
                    style: ''
                },"datainput":{
                    text: _vm.$t('attach'),
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== null && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_name !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_name : '',
                    file_path: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== null && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_path !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_path : '',
                    file_download: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== null && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_download : '',
                    required: _vm.getterTypeExercise.evidence.required,
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('attach_template') + ''))]),_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'easy_upload_template',
                    class: '',
                    style: 'flex-direction: row !important;',
                    name_hidden: 'exercise[close_one_correct][text_evidence][template]',
                    id_hidden: 'example',
                    icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                },"datalabel":{
                    id: 'label_easy_upload_template',
                    style: ''
                },"datainput":{
                    view: true,
                    text: _vm.$t('attach'),
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== null && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_name !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_name : '',
                    file_path: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== null && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_path !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_path : '',
                    file_download: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template !== null && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.template.file_download : '',
                    required: _vm.getterTypeExercise.evidence.required,
                }}})]],2):_vm._e(),(_vm.getterTypeExercise.evidence.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('text_after_download') + (_vm.getterTypeExercise.evidence.required ? '*' : ''),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_close_one_correct_text_evidence_after_download',
                    name: 'exercise[close_one_correct][text_evidence][after_download]',
                    value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.after_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.after_download : '',
                    required: _vm.getterTypeExercise.evidence.required,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                },"maxlength":"128"}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('text_after_download') + ''))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.after_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.text_evidence.after_download : '')}})]],2):_vm._e(),(_vm.getterTypeExercise.feedback.view)?_vm._l((_vm.getterTypeExercise.possible_answers),function(item,index){return _c('div',{key:'KEY47' + index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('feedback_answer') + ' ' + item.id,
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_close_one_correct_answer_feedback_' + item.id,
                        name: 'exercise[close_one_correct][answer][feedback]['+item.id+']',
                        value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.feedback !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.feedback[item.id] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.feedback[item.id] : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('feedback_answer') + ' ' + item.id + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.feedback !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.feedback[item.id] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.answer.feedback[item.id] : '')}})]],2)}):_vm._e(),((_vm.getterTypeExercise.evidence.view && _vm.getterTypeExercise.feedback.view) || (_vm.getterTypeExercise.conditions !== undefined && _vm.getterTypeExercise.conditions.length !== 0))?[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('feedback_evidence'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_close_one_correct_feedback_evidence',
                        name: 'exercise[close_one_correct][feedback][evidence][answer]',
                        value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.evidence !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.evidence : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('feedback_evidence') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.evidence !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.evidence : '')}})]],2)]:_vm._e(),(_vm.getterTypeExercise.not_apply.checked && _vm.getterTypeExercise.feedback.view)?[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('feedback_not_apply'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_close_one_correct_feedback_not_apply',
                        name: 'exercise[close_one_correct][feedback][not_apply][answer]',
                        value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.not_apply !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.not_apply : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('feedback_not_apply') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback !== undefined && _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.not_apply !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.close_one_correct.feedback.not_apply : '')}})]],2)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import ConfigBibliography from "@/view/base_creation/support_material/bibliography/template/bibliography.vue";

const routes = [
    {
        path: '/config/creation/bibliography',
        name: 'config_bibliography',
        component: ConfigBibliography,
        props: {id: null, showMenu: true, otherTitle: 'bibliography'},
        children: [
            {
                path: '/config/creation/bibliography/:id',
                name: 'config_bibliography_id',
                component: ConfigBibliography,
                props: {id: null, showMenu: true, otherTitle: 'bibliography'},
            },
            {
                path: '/config/creation/bibliography/:id/:view',
                name: 'config_bibliography_id_view',
                component: ConfigBibliography,
                props: {id: null, view: true, showMenu: true, otherTitle: 'bibliography'},
            },
        ]
    },
    {
        path: '/config/creation/bibliography/:id/:view/:windowed',
        name: 'config_bibliography_id_view_windowed',
        component: ConfigBibliography,
        props: {id: null, view: true, windowed: true, showMenu: false, background: true, otherTitle: 'bibliography'},
    }
];

export default routes;
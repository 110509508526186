<template>
    <div v-if="haveData">
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'seals'
            }"
            :header="header"
            :data="ConfigSeals.records_data.length === 0 ? [] : ConfigSeals.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_seal_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'config_seal_id', params: {id: itemProp.id}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_config_seal',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg')
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_config_seal',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg')
                    }"
                    @click="Global.deleteConfirm('delete_confirm','Seal', itemProp.id,{'code': itemProp.code, 'name': itemProp.name, 'description': itemProp.description, 'topic': itemProp.topic}, $t('delete_stamp'), $t('preparing_delete_stamp'), 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'config_seal'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_config_seal',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from 'fe-gh-action-lib';
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_seals",
        components: {
            GhTableCommon,
            GhButton,
            GhAction
        },
        computed: {
            ...mapState(['ConfigSeals'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getSeals', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
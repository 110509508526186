import ConfigScoreRange from "@/view/config/evaluators/score_range/template/score_range.vue";

const routes = [
    {
        path: '/config/evaluator/score-range',
        name: 'config_score_range',
        component: ConfigScoreRange,
        props: {id: null, showMenu: true, otherTitle: 'score_range'},
        children: [
            {
                path: '/config/evaluator/score-range/:id',
                name: 'config_score_range_id',
                component: ConfigScoreRange,
                props: {id: null, showMenu: true, otherTitle: 'score_range'},
            },
            {
                path: '/config/evaluator/score-range/:id/:view',
                name: 'config_score_range_id_view',
                component: ConfigScoreRange,
                props: {id: null, view: true, showMenu: true, otherTitle: 'score_range'},
            }
        ]
    }
];

export default routes;
<template>
    <div v-if="haveData">
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'elaboration'
            }"
            :header="header"
            :data="CreationGrouper.elaboration_data !== undefined && CreationGrouper.elaboration_data.length !== 0 ? CreationGrouper.elaboration_data : []"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'view_grouper_id_view', params: {grouper: id_grouper, id: itemProp.id, view: true, type: 'elaboration'}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'view_grouper_id', params: {grouper: id_grouper, id: itemProp.id, type: 'elaboration'}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_config_grouper',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_config_grouper',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }"
                    @click="Global.deleteConfirm('delete_confirm','CreationGrouper', itemProp.id,{'name': itemProp.name, 'description': itemProp.description, 'responsible': itemProp.responsible, 'topic': itemProp.topic, 'impartation': itemProp.impartation}, $t('delete_grouper'), $t('preparing_delete_grouper'), 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
<!--                    <router-link :to="{name: 'view_grouper', params:{grouper: id_grouper, type: 'elaboration'}}">-->
                        <GhButton
                            :datainput="{
                                id: 'btn_add_config_grouper',
                                text: $t('add')
                            }"
                            @click="newGrouper(id_grouper, 'elaboration')"
                        />
<!--                    </router-link>-->
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_elaboration",
        components: {
            GhButton,
            GhAction,
            GhTableCommon
        },
        props:['id_grouper'],
        computed:{
            ...mapState(['CreationGrouper','TabCreation']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: 'responsible', field: 'responsible', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'impartation', field: 'impartation', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        watch:{
            async 'TabCreation.actual_grouper'(){
                await this.$store.dispatch('getElaborationByGrouper',{id:this.id_grouper},{root:true});
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getElaborationByGrouper', {id: this.id_grouper}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async newGrouper(grouper, type){
                await this.$store.dispatch('setNewUUIDGrouper', {grouper: grouper, type: type}, {root: true});
            }
        }
    }
</script>
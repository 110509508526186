import PermstList from "@/view/perms/list_perms";
import Formsearchusers from "@/view/perms/users/users/template/form_search.vue";

import Group from './groups/route';
// import Company from './company/route';
import User from './user/route';
import Evaluators from './evaluators/route';

const route = [
    {
        path: '/perms',
        name: 'perms',
        component: PermstList,
        props: {showMenu: true}
    },
    {
        path: '/Formsearchusers',
        name: 'Formsearchusers',
        component: Formsearchusers,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Group,
    // ...Company,
    ...User,
    ...Evaluators
];

export default routes;

import ConfigWeightingElement from "@/view/config/creation/weighting_group/template/weighting_group.vue";

const routes = [
    {
        path: '/config/creation/weighting-group',
        name: 'config_weighting_group',
        component: ConfigWeightingElement,
        props: {id: null, showMenu: true, otherTitle: 'weighting_group'},
        children: [
            {
                path: '/config/creation/weighting-group/:id',
                name: 'config_weighting_group_id',
                component: ConfigWeightingElement,
                props: {id: null, showMenu: true, otherTitle: 'weighting_group'},
            },
            {
                path: '/config/creation/weighting-group/:id/:view',
                name: 'config_weighting_group_id_view',
                component: ConfigWeightingElement,
                props: {id: null, view: true, showMenu: true, otherTitle: 'weighting_group'},
            }
        ]
    }
];

export default routes;
<template>
    <div class="float_window" :key="haveData" style="padding-bottom: 15px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label !== undefined && label.show"
                :datablock="{
                    id: 'todo_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: TabTodo[label['name'] === 'to_do' ? 'counter_todo' : 'counter_to_validate'],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" :key="'section_' + label.id + '_' + refreshSections"/>
                </template>
            </GhDropSelector>
        </div>

        <component v-bind:is="popupCalendar"/>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import ListToDo from './todo/list_todo.vue'
    import ListToValidate from './to_validate/list_to_validate.vue'
    import popupCalendar from './popups/popup_calendar.vue'

    export default {
        name: "list_todo",
        components: {
            GhDropSelector
        },
        data(){
            return {
                haveData: false,
                showTodo: false,
                showCompanies: false,
                refreshSections: 0,
                popupCalendar: popupCalendar,
                data: [
                    {id: 1, name: 'to_do', perm_key: 'todo', component: ListToDo, result: 'to_do', show: false, second_text: 'results'},//cambiar show a false
                    {id: 2, name: 'to_validate', perm_key: 'to_validate', component: ListToValidate, result: 'to_validate', show: false, second_text: 'results'},//cambiar show a false
                ],
            }
        },
        computed: {
            ...mapState(['TabTodo','Login'])
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getTodoCounter', '', {root: true});
                await this.$store.dispatch('getToValidateCounter', '', {root: true});
                await this.$store.dispatch('getListTodo', '', {root: true});

                var that = this;
                that.Login.tabs[0]['sub-modules'].forEach((subroute) => {
                    if(this.data.find(n => n.perm_key === subroute.name) !== undefined){
                        this.data.find(n => n.perm_key === subroute.name)['show'] = subroute.have_permission;
                    }
                });

                console.log(this.data);

                this.haveData = true;
            }
        },
    }
</script>

<style>
    .section > table{
        min-width: 900px !important;
    }
</style>

<template>
    <div>
        <div class="section_line" style="margin-top: 10px;">
            <div class="T15_b" style="padding-left:13px;">
                {{$t('type') + ': ' + ($root._route.params.view ? Object.values(ConfigTypeExercise.data.type[0])[1] : $parent.$parent.$refs['RefTypeExercise'].datasaved.text)}}
            </div>
        </div>
        <div class="gh-row-content" style="margin-top: 10px">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('n_statements') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_n_statements',
                        name: 'type_exercise[n_statements]',
                        style: 'width: 50px',
                        value: n_statements,
                        type: 'int',
                        limitNumMin: 1
                    }"
                    ref="RefNStatements"
                    @keyup="n_statements = $refs['RefNStatements'].datainput.value"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('n_statements') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.n_statements ? ConfigTypeExercise.data.config_type.n_statements : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('reference'), style: 'width: 130px', id: 'label_type_exercise_reference'}"
                :datainput="{
                    required: false,
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.reference[0] === 1,
                    disabled: $root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_reference',
                    value: 1,
                    name: 'type_exercise[reference][]'
                }"
                ref="RefReference"
                @click="checkReference"
            />
            <template v-if="showReference">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('n_reference') + '*',
                            style: '',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'type_exercise_n_reference',
                            name: 'type_exercise[n_reference]',
                            style: 'width: 50px',
                            value: n_reference,
                            type: 'int',
                            limitNumMin: 1
                        }"
                        ref="RefNReference"
                        @keyup="n_reference = $refs['RefNReference'].datainput.value"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('n_reference') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.n_reference ? ConfigTypeExercise.data.config_type.n_reference : ''}}</label>
                </template>
            </template>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <div style="display: flex; margin-left: 160px;">
                <GhChecks
                    :datalabel="{text: $t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[0] === 1,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_evidence',
                        value: 1,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidence"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                />
                <GhChecks
                    :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[1] === 2,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_required_evidence',
                        value: 2,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidenceRequired"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)"
                />
            </div>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('one_element'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                :datainput="{
                    required: false,
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.one_element === 1,
                    disabled: $root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_one_element',
                    value: 1,
                    name: 'type_exercise[one_element][]'
                }"
                ref="GhCheckConfigOneElement"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('associate_with'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                :datainput="{
                    required: false,
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.associate_with === 1,
                    disabled: $root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_associate_with',
                    value: 1,
                    name: 'type_exercise[associate_with][]'
                }"
                ref="GhCheckConfigOneElement"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapState} from "vuex";

    export default {
        name: "type_multi_open",
        components: {
            GhInputTextField,
            GhChecks
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds'])
        },
        data(){
            return {
                n_statements: '',
                n_reference: '',
                showReference: false
            }
        },
        beforeMount() {
            this.n_statements = this.ConfigTypeExercise.data && this.ConfigTypeExercise.data.config_type !== undefined && this.ConfigTypeExercise.data.config_type.n_statements ? this.ConfigTypeExercise.data.config_type.n_statements : '';
            this.n_reference = this.ConfigTypeExercise.data && this.ConfigTypeExercise.data.config_type !== undefined && this.ConfigTypeExercise.data.config_type.n_reference ? this.ConfigTypeExercise.data.config_type.n_reference : '';
            if(this.ConfigTypeExercise.data && this.ConfigTypeExercise.data.config_type !== undefined && this.ConfigTypeExercise.data.config_type.reference[0] === 1){
                this.showReference = true;
            }
        },
        methods: {
            checkReference(){
                this.showReference = this.$refs['RefReference'].selected;
                this.n_reference = '';
            }
        }
    }
</script>
import ConfigResponsibles from "@/view/config/creation/responsibles/template/responsible.vue";

const routes = [
    {
        path: '/config/creation/responsible',
        name: 'config_responsibles',
        component: ConfigResponsibles,
        props: {id: null, showMenu: true, otherTitle: 'responsible'},
        children: [
            {
                path: '/config/creation/responsible/:id',
                name: 'config_responsibles_id',
                component: ConfigResponsibles,
                props: {id: null, showMenu: true, otherTitle: 'responsible'},
            },
            {
                path: '/config/creation/responsible/:id/:view',
                name: 'config_responsibles_id_view',
                component: ConfigResponsibles,
                props: {id: null, view: true, showMenu: true, otherTitle: 'responsible'},
            }
        ]
    }
];

export default routes;
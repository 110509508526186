<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab v-bind:items="$router.options.GhSubTabsBaseCreation" :dataBlock="{id: 'base_creation_tab_', calldispatch: 'getBaseCreationTabActive'}"/>
        <component :is="viewLoad" :key="refreshView" />
    </div>
</template>
<script>
    import GhSubTab from 'fe-gh-subtab-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_config",
        components:{
            GhSubTab,
        },
        computed:{
            ...mapState(['TabBaseCreation']),
        },
        watch:{
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            },
            'TabBaseCreation.reload'(){
                this.viewLoad = this.TabBaseCreation.subTabActive;
                this.refreshView++;
            }
        },
        data(){
            return{
                haveData: false,
                viewLoad: null,
                refreshView: 0
            }
        },
        mounted(){
            var that = this;
            setTimeout(function(){
                that.haveData = true;
            },200);
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list_users: [],
        flagListUsers: false
    },
    mutations: {
        loadListUsers(state, payload){
            state.flagListUsers = !state.flagListUsers;
            state.list_users = payload;
        },
    },
    getters: {
        getterListUsers(state) {
            const flagListUsers = state.flagListUsers; // eslint-disable-line no-unused-vars
            return state.list_users;
        },
    },
    actions: {
        async getListUsers(state,item){
            let requestSearchCompanies = API_URL + "search/users/evaluators";
            let params = {};
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });

            await axios({
                method: "get",
                url: requestSearchCompanies,
                params: params,
            }).then(
                response => {
                    state.commit('loadListUsers', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListUsersCompany(state,item){
            let requestSearchCompanies = API_URL + "search/users/company";
            let params = {};
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });

            await axios({
                method: "get",
                url: requestSearchCompanies,
                params: params,
            }).then(
                response => {
                    state.commit('loadListUsers', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};

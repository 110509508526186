import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        result_search_data_users: [],
        results: [],
        data: [],
        refreshResults: false,
        refreshData: false
    },
    mutations: {
        loadResultSearchUsers(state, payload){
            state.result_search_data_users = payload;
        },
        loadListUsers(state, payload) {
            state.refreshResults = !state.refreshResults;
            state.results = payload;
        },
        loadPermsUser(state, payload) {
            state.refreshData = !state.refreshData;
            state.data = payload;
        }
    },
    getters: {
        getterListPermissionUsers(state) {
            const refreshResults = state.refreshResults; // eslint-disable-line no-unused-vars
            return state.results;
        },
        getterPermissionUser(state) {
            const refreshData = state.refreshData; // eslint-disable-line no-unused-vars
            return state.data;
        },
    },
    actions: {
        async getListPermissionUsers(state){
            let  requestLogin = API_URL + "perms/users-list";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadListUsers", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPermsUser(state,item){
            if(item.id === undefined){
                state.commit("loadPermsUser", []);
            }else{
                let  requestLogin = API_URL + "user/worker/" + item.id;
                await axios({
                    url: requestLogin,
                }).then(
                    response => {
                        if(response.status === 200) {
                            state.commit("loadPermsUser", response.data.data);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setPermsUser(state,item){
            let request = item.id ? API_URL + "user/worker/"+item.id+"/update" : API_URL + "user/worker/add";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        router.push({name: 'perms'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removePermsUser(state, item){
            let request = API_URL + "perms/users/" + item.id;
            let formData = new FormData();

            await axios({
                method: "delete",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getPermsUsers');
                        await this.dispatch('getListPermissionUsers');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchUsers(state,item){
            let requestSearchCompanies = API_URL + "search/users";
            let params = {};
            
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });
            
            await axios({
                method: "get",
                url: requestSearchCompanies,
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchUsers", response.data.data);
                    response.data.data.forEach((data)=> {
                        if(Array.isArray(item.selected) === true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};

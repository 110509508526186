<template>
    <div v-if="haveData">
        <div style="margin-left: 15px; margin-right: 15px; margin-bottom: 10px;">
            <GhTreeActionBlock
                :dataBlock="{
                    id: 'tree_topics',
                    actionWithoutChildren: true,
                    actionWithChildren: true,
                    extraLabelNumber: false,
                    extraLabelText: '',
                    loadStorageOpen: true
                }"
                :data="ConfigTopics.records_data"
            >
                <template v-slot:Action="Action">
                    <router-link :to="{name: 'config_topics_id', params: {id: Action.Action.Action.Action.id}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_config_topics',
                                text: $t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg')
                            }"
                        />
                    </router-link>
                    <GhAction
                        :dataction="{
                            id: 'delete_config_topics',
                            text: $t('delete'),
                            icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                        }"
                        @click="Global.deleteConfirm('delete_confirm','Topic', Action.Action.Action.Action.id,{'name': Action.Action.Action.Action.name}, $t('delete_topics'), $t('preparing_delete_topics'), 'delete')"
                    />
                </template>
            </GhTreeActionBlock>
        </div>

        <div class="centered" style="margin-bottom: 10px;">
            <router-link :to="{name: 'config_topics'}">
                <GhButton
                    :datainput="{
                        id: 'btn_add_config_topics',
                        text: $t('add')
                    }"
                />
            </router-link>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTreeActionBlock from "fe-gh-tree-action-block-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_topics",
        components: {
            GhTreeActionBlock,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigTopics'])
        },
        data() {
            return {
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getTopics', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
import OngoingPrograms from "@/view/assign/ongoing_programs/list_ongoing_programs.vue";

const routes = [
    {
        path: '/ongoing_programs',
        name: 'assign_ongoing_programs',
        component: OngoingPrograms,
        props: {showMenu: true}
    },
];

export default routes;
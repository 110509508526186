<template>
    <div style="padding-bottom: 12px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'support_material_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: BaseCreationSupportMaterial.counters[label['result']]
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>
<script>
    import list_bibliographies from './bibliography/list_bibliography.vue';
    import list_examples from "@/view/base_creation/support_material/examples/list_examples.vue";
    import list_support_material from "@/view/base_creation/support_material/support_material/list_support_material.vue";
    import list_good_practices from "@/view/base_creation/support_material/good_practices/list_good_practices.vue";
    import list_theory from "@/view/base_creation/support_material/theory/list_theory.vue";

    import GhDropSelector from 'fe-gh-dropselector-lib';

    import {mapState} from "vuex";
    export default {
        name: "list_supportMaterial",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['BaseCreationSupportMaterial','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'bibliographies', component: list_bibliographies, result: 'bibliographies', show: false , second_text: 'results'},
                    {id: 2, name: 'examples', component: list_examples, result: 'examples', show: false , second_text: 'results'},
                    {id: 3, name: 'materials', component: list_support_material, result: 'materials', show: false , second_text: 'results'},
                    {id: 4, name: 'good_practices', component: list_good_practices, result: 'good_practices', show: false , second_text: 'results'},
                    {id: 5, name: 'theory', component: list_theory, result: 'theory', show: false , second_text: 'results'}
                ],
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getBaseCreationSupportMaterialCounters', '', {root: true});

                if (this.Login.tabs.length !== 0) {
                    this.Login.tabs[5]['sub-modules'][0]['sub-sections'].forEach((subroute, subkey) => {
                        if (this.data[subkey] !== undefined) {
                            this.data[subkey].show = subroute.have_permission;
                        }
                    });
                }
            }
        },
    }
</script>

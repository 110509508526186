import Vue from "vue";
// import {LoginUrl} from "@/common/config";
import vuex from "vuex";
// import axios from "axios";
import router from "../../../routes/router"
import {API_URL} from "@/common/config";
import axios from "axios";
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        chart_radio:'',
        table_radio:'',
    },
    mutations:{
        LOAD_CHART_RADIO(state,payload){
            state.chart_radio = payload;
        },
        LOAD_CHART_TABLE(state,payload){
            state.table_radio = payload;
        },
    },
    actions:{
        TO_PAY(state,item){
            router.push({name: 'to_pay',params:{id:item.id}});
        },
        async GET_RADIO_CHART_RESULTS(state){
            let requestLogin = API_URL + "chart_radio_quiz"

            await axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_CHART_RADIO", response.data.data);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async GET_TABLE_CHART_RESULTS(state){
            let requestLogin = API_URL + "chart_table_quiz"

            await axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_CHART_TABLE", response.data.data);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{style:(_vm.hideBtn ? '' : 'padding-left: 12px; padding-right: 12px;')},[(_vm.hideBtn)?_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(label.show)?_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: label.name + '_' + label.id,
                    title: _vm.$t(label.name),
                    second_title: _vm.$t(label.second_text)
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)}):[_c('form',{attrs:{"id":"form_collaboration"}},_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(label.show)?_c('GhNoDropSelector',{attrs:{"datablock":{
                        id: label.name + '_' + label.id,
                        title: _vm.$t(label.name),
                        second_title: _vm.$t(label.second_text)
                    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)}),0)],(!_vm.hideBtn)?_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_send',text: _vm.$t('send_request'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.sendCollaboration();}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('go_out'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.back();}}})],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div style="padding-bottom: 12px;">
        <GhDropSelector :datablock="{id: 'search', title: $t('searcher')}">
            <template v-slot:content>
                <component v-bind:is="view_search"/>
            </template>
        </GhDropSelector>
        <GhNoDropSelector :datablock="{id: 'result' ,title: typeof TabSupervisedEvaluated.resultTitle == 'number' ? TabSupervisedEvaluated.resultTitle + ' ' + $t('results') : $t(TabSupervisedEvaluated.resultTitle)}">
            <template v-slot:content>
                <template v-if="TabSupervisedEvaluated.resultData !== undefined && TabSupervisedEvaluated.resultData != ''">
                    <template v-for="(item, key) in TabSupervisedEvaluated.resultData">
                        <div :key="key" style="margin: 20px;" v-if="item.content !== undefined && item.content.lenght != 0">
                            <component v-bind:is="result_search" :data_supervised_evaluated="item"/>
                        </div>
                    </template>
                </template>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import view_search from './search_content/view_search.vue';
    import result_search from './result_content/result_content.vue';
    import {mapState} from "vuex";
    export default {
        name: "list_supervised_evaluated",
        components: {
            GhDropSelector,
            GhNoDropSelector
        },
        computed: {
            ...mapState(['TabSupervisedEvaluated'])
        },
        data(){
            return {
                view_search: view_search,
                result_search: result_search
            }
        }
    }
</script>
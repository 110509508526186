<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'grouper',title: $t('grouper')}">
            <template v-slot:content>
                <form id="form_grouper" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'grouper_code',
                                        name: 'grouper[code]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigGroupers.data.code,
                                        type: 'text'
                                    }"
                                    maxlength="4"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigGroupers.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'grouper_name',
                                        name: 'grouper[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigGroupers.data.name,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigGroupers.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id: 'grouper_description',
                                        name: 'grouper[description]',
                                        value: ConfigGroupers.data.description
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigGroupers.data.description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'grouper_elements',
                                        class: 'T15_b',
                                        subtitle: $t('select_elements') + ':',
                                        text: $t('elements') + '*',
                                        label: $t('elements'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    }"
                                    :datainput="{
                                        id: 'value_grouper_elements',
                                        name: 'grouper[elements][]'
                                    }"
                                    :feed="getFeeds.feed_elements"
                                    :selected_input="ConfigGroupers.data.elements"
                                />
                            </template>
                            <template v-else>
                                <div style="display: flex">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('elements') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(ConfigGroupers.data.elements).join(', ')}}</label>
                                </div>
                            </template>
                        </div>
                        <div class="gh-row-content striped_white" :style="!$root._route.params.view ? 'margin-top: -10px;' : ''" style="margin-left: 160px; padding-top: 3px; padding-bottom: 5px; padding-left: 5px;">
                            <div style="display: flex; flex-wrap: wrap;">
                                <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                    <GhChecks
                                        :datalabel="{text: $t('responsible'), style: 'width: 130px', id: 'label_grouper_responsible'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_grouper_responsible', value: 1, name: 'grouper[attributes][responsible][]',
                                            selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.responsible && ConfigGroupers.data.attributes.responsible[0] === '1',
                                            disabled: $root._route.params.view
                                        }"
                                        ref="GhCheckAttributeResponsible"
                                        @click="checkGroupChecks('GhCheckAttributeResponsible', 'GhCheckAttributeResponsibleRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_responsible'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_responsible', value: 2, name: 'grouper[attributes][responsible][]',
                                            selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.responsible && ConfigGroupers.data.attributes.responsible[1] === '2',
                                            disabled: $root._route.params.view
                                        }"
                                        ref="GhCheckAttributeResponsibleRequired"
                                        @click="checkGroupChecks('GhCheckAttributeResponsible', 'GhCheckAttributeResponsibleRequired', true)"
                                    />
                                </div>
                                <div style="display: flex; width: 400px; box-sizing: border-box;">
                                    <GhChecks
                                        :datalabel="{text: $t('information'), style: 'width: 130px', id: 'label_grouper_information'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_grouper_information', value: 1, name: 'grouper[attributes][information][]',
                                            selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.information && ConfigGroupers.data.attributes.information[0] === '1',
                                            disabled: $root._route.params.view
                                        }"
                                        ref="GhCheckAttributeInformation"
                                        @click="checkGroupChecks('GhCheckAttributeInformation', 'GhCheckAttributeInformationRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_information'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_information', value: 2, name: 'grouper[attributes][information][]',
                                            selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.information && ConfigGroupers.data.attributes.information[1] === '2',
                                            disabled: $root._route.params.view
                                        }"
                                        ref="GhCheckAttributeInformationRequired"
                                        @click="checkGroupChecks('GhCheckAttributeInformation', 'GhCheckAttributeInformationRequired', true)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content striped" style="margin-left: 160px;  padding-top: 3px; padding-bottom: 5px; padding-left: 5px;">
                            <div>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('examples'), style: 'width: 130px', id: 'label_grouper_examples'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_examples', value: 1, name: 'grouper[attributes][examples][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.examples && ConfigGroupers.data.attributes.examples[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeExamples"
                                            @click="checkGroupChecks('GhCheckAttributeExamples', 'GhCheckAttributeExamplesRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_examples'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_examples', value: 2, name: 'grouper[attributes][examples][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.examples && ConfigGroupers.data.attributes.examples[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeExamplesRequired"
                                            @click="checkGroupChecks('GhCheckAttributeExamples', 'GhCheckAttributeExamplesRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('bibliography'), style: 'width: 130px', id: 'label_grouper_bibliography'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_bibliography', value: 1, name: 'grouper[attributes][bibliography][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.bibliography && ConfigGroupers.data.attributes.bibliography[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeBibliography"
                                            @click="checkGroupChecks('GhCheckAttributeBibliography', 'GhCheckAttributeBibliographyRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_bibliography'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_bibliography', value: 2, name: 'grouper[attributes][bibliography][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.bibliography && ConfigGroupers.data.attributes.bibliography[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeBibliographyRequired"
                                            @click="checkGroupChecks('GhCheckAttributeBibliography', 'GhCheckAttributeBibliographyRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('materials'), style: 'width: 130px', id: 'label_grouper_support_material'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_support_material', value: 1, name: 'grouper[attributes][support_material][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.support_material && ConfigGroupers.data.attributes.support_material[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeSupportMaterial"
                                            @click="checkGroupChecks('GhCheckAttributeSupportMaterial', 'GhCheckAttributeSupportMaterialRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_support_material'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_support_material', value: 2, name: 'grouper[attributes][support_material][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.support_material && ConfigGroupers.data.attributes.support_material[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeSupportMaterialRequired"
                                            @click="checkGroupChecks('GhCheckAttributeSupportMaterial', 'GhCheckAttributeSupportMaterialRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('good_practices'), style: 'width: 130px', id: 'label_grouper_good_practices'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_good_practices', value: 1, name: 'grouper[attributes][good_practices][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.good_practices && ConfigGroupers.data.attributes.good_practices[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeGoodPractices"
                                            @click="checkGroupChecks('GhCheckAttributeGoodPractices', 'GhCheckAttributeGoodPracticesRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_good_practices'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_good_practices', value: 2, name: 'grouper[attributes][good_practices][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.good_practices && ConfigGroupers.data.attributes.good_practices[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeGoodPracticesRequired"
                                            @click="checkGroupChecks('GhCheckAttributeGoodPractices', 'GhCheckAttributeGoodPracticesRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('theory'), style: 'width: 130px', id: 'label_grouper_theory'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_theory', value: 1, name: 'grouper[attributes][theory][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.theory && ConfigGroupers.data.attributes.theory[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeTheory"
                                            @click="checkGroupChecks('GhCheckAttributeTheory', 'GhCheckAttributeTheoryRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_theory'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_theory', value: 2, name: 'grouper[attributes][theory][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.theory && ConfigGroupers.data.attributes.theory[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeTheoryRequired"
                                            @click="checkGroupChecks('GhCheckAttributeTheory', 'GhCheckAttributeTheoryRequired', true)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content striped_white" style="margin-left: 160px; padding-top: 3px; padding-bottom: 5px; padding-left: 5px;">
                            <div>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('stamp'), style: 'width: 130px', id: 'label_grouper_stamp'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_stamp', value: 1, name: 'grouper[attributes][stamp][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.stamp && ConfigGroupers.data.attributes.stamp[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeStamp"
                                            @click="checkGroupChecks('GhCheckAttributeStamp', 'GhCheckAttributeStampRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_stamp'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_stamp', value: 2, name: 'grouper[attributes][stamp][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.stamp && ConfigGroupers.data.attributes.stamp[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeStampRequired"
                                            @click="checkGroupChecks('GhCheckAttributeStamp', 'GhCheckAttributeStampRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; width: 400px;">
                                        <GhChecks
                                            :datalabel="{text: $t('certificate'), style: 'width: 130px', id: 'label_grouper_certificate'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_responsible', value: 1, name: 'grouper[attributes][certificate][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.certificate && ConfigGroupers.data.attributes.certificate[0] === '1',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeCertificate"
                                            @click="checkGroupChecks('GhCheckAttributeCertificate', 'GhCheckAttributeCertificateRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_certificate'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_grouper_required_certificate', value: 2, name: 'grouper[attributes][certificate][]',
                                                selected: ConfigGroupers.data.attributes && ConfigGroupers.data.attributes.certificate && ConfigGroupers.data.attributes.certificate[1] === '2',
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckAttributeCertificateRequired"
                                            @click="checkGroupChecks('GhCheckAttributeCertificate', 'GhCheckAttributeCertificateRequired', true)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitGrouper"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: 'view_grouper',
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhDataSelector,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['ConfigGroupers', 'getFeeds'])
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getGrouperData', {id: this.$root._route.params.id}, {root: true});
                await this.$store.dispatch('getFeedElements', '', {root: true});
                this.haveData = true;
            }
        },
        methods: {
            checkGroupChecks(refType, refRequired, required){
                if (required) {
                    if (this.$refs[refRequired].selected) {
                        if (!this.$refs[refType].selected) {
                            this.$refs[refType].checkUncheck();
                        }
                    }
                } else {
                    if (!this.$refs[refType].selected) {
                        if (this.$refs[refRequired].selected) {
                            this.$refs[refRequired].checkUncheck();
                        }
                    }
                }
            },
            submitGrouper(){
                if(this.Global.checkRequired('form_grouper') === 'OK') {
                    const formData = new FormData(document.getElementById('form_grouper'));
                    this.$store.dispatch('setGrouperData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #grouper_elements_selector_label{
        width: 150px;
        text-align: end;
    }
</style>
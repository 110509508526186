import Vue from "vue";
// import {LoginUrl} from "@/common/config";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        list_companies:[],
        selected_companies:[],
    },
    mutations:{
        LOAD_LIST_COMPANIES(state,payload){
            state.list_companies = payload;
        },
        LOAD_SELECTED_COMPANIES(state,payload){
            state.selected_companies = payload;
        },
    },
    actions:{
        GET_LIST_COMPANIES(state){
            let requestLogin = API_URL + "companies/tabs"

            axios({
                method: "GET",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_LIST_COMPANIES", response.data.data);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async GET_TABS_COMPANY(state,item){
            let requestLogin = API_URL + "company/tabs/"+item;

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_SELECTED_COMPANIES", response.data.data);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async SAVE_COMPANY_TABS(state, item){
            let requestLogin = API_URL + 'company/tabs/' + item.id;

            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    console.log(response);
                    await state.dispatch("GET_LIST_COMPANIES");
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

import AllPrograms from "@/view/itinerary/client/all_programs/list_all_programs.vue";

const route = [
    {
        path: '/programs/all',
        name: 'all_programs',
        component: AllPrograms,
        props: {id: null, showMenu: true, otherTitle: 'all_programs'},
    },
];

const routes = [
    ...route,
]

export default routes;
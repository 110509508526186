<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'evaluation_basis',title: $t('evaluation_basis')}">
            <template v-slot:content>
                <form id="form_evaluation_basis" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('order') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'evaluation_basis_order',
                                        name: 'evaluation_basis[order]',
                                        style: 'width: 200px',
                                        value: content.order,
                                        type: 'text'
                                    }"
                                    ref="RefOrder"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('order') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigEvaluationBasis.data.order}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'evaluation_basis_name',
                                        name: 'evaluation_basis[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: content.name,
                                        type: 'text'
                                    }"
                                    ref="RefName"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigEvaluationBasis.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    }"
                                    :datainput="{
                                        id: 'evaluation_basis_description',
                                        name: 'evaluation_basis[description]',
                                        value: content.description
                                    }"
                                    ref="RefDescription"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigEvaluationBasis.data.description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" style="margin-left: 160px;">
                            <GhChecks
                                :datalabel="{
                                    text: $t('last_item'),
                                    style: '',
                                    id: 'label_evaluation_basis_last_item'
                                }"
                                :datainput="{
                                    required: false,
                                    type: 'checkbox',
                                    id: 'value_evaluation_basis_last_item',
                                    value: 1,
                                    name: 'evaluation_basis[last_item]',
                                    selected: ConfigEvaluationBasis.data.last_item === 1,
                                    disabled: $root._route.params.view
                                }"
                                ref="RefLastItem"
                                @click="checkLastItem"
                            />
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('range') + (lastItem ? '*' : ''),
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: lastItem,
                                        id: 'evaluation_basis_range',
                                        name: 'evaluation_basis[range]',
                                        style: 'width: 80px',
                                        value: content.range,
                                        limitNumMax: 100,
                                        type: 'int'
                                    }"
                                    ref="RefRange"
                                    @keyup="checkRange"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('range') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigEvaluationBasis.data.range}}</label>
                            </template>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitEvaluationBasis"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    export default {
        name: "evaluation_basis_template",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['ConfigEvaluationBasis', 'getFeeds'])
        },
        data(){
            return {
                lastItem: false,
                content: {
                    order: '',
                    name: '',
                    description: '',
                    range: ''
                },
                haveData: false,
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getEvaluationBasisData', {id: this.$root._route.params.id}, {root: true});

                if(this.ConfigEvaluationBasis.data.length !== 0) {
                    this.content = {
                        order: this.ConfigEvaluationBasis.data.order,
                        name: this.ConfigEvaluationBasis.data.name,
                        description: this.ConfigEvaluationBasis.data.description,
                        range: this.ConfigEvaluationBasis.data.range
                    }

                    this.lastItem = this.ConfigEvaluationBasis.data.last_item === 1;
                }

                this.haveData = true;
            }
        },
        methods: {
            checkLastItem(){
                this.content = {
                    order: this.$refs['RefOrder'].datainput.value,
                    name: this.$refs['RefName'].datainput.value,
                    description: this.$refs['RefDescription'].datainput.value,
                    range: this.$refs['RefRange'].datainput.value
                }

                this.lastItem = this.$refs['RefLastItem'].selected;
            },
            checkRange(){
                this.content = {
                    order: this.$refs['RefOrder'].datainput.value,
                    name: this.$refs['RefName'].datainput.value,
                    description: this.$refs['RefDescription'].datainput.value,
                    range: this.$refs['RefRange'].datainput.value
                }
            },
            async submitEvaluationBasis(){
                if(this.Global.checkRequired('form_evaluation_basis') === 'OK') {
                    const formData = new FormData(document.getElementById('form_evaluation_basis'));
                    this.$store.dispatch('setEvaluationBasisData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>
import ListActions from "@/view/actions/list_actions.vue";
import ActionFileCompany from "@/view/actions/template/company_file.vue";

const routes = [
    {
        path: '/actions',
        name: 'actions',
        component: ListActions,
        props: {showMenu: true},
    },
    {
        path: '/actions/company',
        name: 'actions_company',
        component: ActionFileCompany,
        props: {id: null, showMenu: true,  otherTitle: 'view_company'},
        children: [
            {
                path: '/actions/company/:id',
                name: 'actions_company_id',
                component: ActionFileCompany,
                props: {id: null, showMenu: true,  otherTitle: 'view_company'},
            },
            {
                path: '/actions/company/:id/:view/',
                name: 'actions_company_view',
                component: ActionFileCompany,
                props: {id: null, showMenu: true,  otherTitle: 'view_company'},
            },
        ],

    }
];

export default routes;

import ConfigPositionCompany from "@/view/config/company/position_company/template/position_company.vue";

const routes = [
    {
        path: '/config/company/position-company',
        name: 'config_position_company',
        component: ConfigPositionCompany,
        props: {id: null, showMenu: true, otherTitle: 'position_company'},
        children: [
            {
                path: '/config/company/position-company/:id',
                name: 'config_position_company_id',
                component: ConfigPositionCompany,
                props: {id: null, showMenu: true, otherTitle: 'position_company'},
            },
            {
                path: '/config/company/position-company/:id/:view',
                name: 'config_position_company_id_view',
                component: ConfigPositionCompany,
                props: {id: null, view: true, showMenu: true, otherTitle: 'position_company'},
            }
        ]
    }
];

export default routes;
<template>
    <div>
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('format')}}</div>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label" :class="!$root._route.params.view && $root._route.params.type !== 'enabled' ? 'T15_b' : 'T15'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
            <GhChecks v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'"
                :datalabel="{text:$t('full'),style:'',class:'',id:''}"
                :datainput="{
                    required: true,
                    selected: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.item !== undefined && CreationGrouper.grouper_data.item === 'full',
                    type: 'radio',
                    id:'',
                    value: 'full',
                    name: 'grouper[item]'
                }"
                ref="RefItemFull"
                @click="formatItem('full'); checkImpartation()"
            />
            <GhChecks v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'"
                :datalabel="{text: $t('dependent'), style: '', class: '', id: ''}"
                :datainput="{
                    required: true,
                    selected: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.item !== undefined && CreationGrouper.grouper_data.item === 'dependent',
                    type: 'radio',
                    id: '',
                    value: 'dependent',
                    name: 'grouper[item]'
                }"
                @click="formatItem('dependent'); checkImpartation()"
            />
            <label v-else class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t(CreationGrouper.grouper_data.item)}}</label>
        </div>
        <div class="gh-row-content" v-if="showImpartation" :style="!$root._route.params.view && $root._route.params.type !== 'enabled' ? 'height: 18px;' : ''">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhDataSelector
                    :datablock="{
                        id: 'label_grouper_impartation',
                        class: 'T15_b',
                        subtitle: $t('select_impartation') + ':',
                        text: $t('impartation'),
                        label: $t('impartation'),
                        type: 'checkbox',
                        columns: 3,
                        style: '',
                        required: false
                    }"
                    :datainput="{
                        id: 'grouper_impartation',
                        name: 'grouper[impartation][]'
                    }"
                    ref="RefImpartation"
                    :selected_input="CreationGrouper.grouper_data.impartation"
                    :feed="getFeeds.feed_impartation"
                    @accept="checkImpartation"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('impartation') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.impartation !== undefined ? Object.values(CreationGrouper.grouper_data.impartation).join(', ') : ''}}</label>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('impartation_from') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.impartation_from !== undefined ? CreationGrouper.grouper_data.impartation_from : ''}}</label>
                <template v-if="$root._route.params.type === 'historic'">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('until').toLowerCase() + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.impartation_until !== undefined ? CreationGrouper.grouper_data.impartation_until : ''}}</label>
                </template>
            </template>
        </div>
        <div class="gh-row-content" v-if="show.template_report">
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('template_report') + ':'}}</label>
            <GhEasyUpload
                :extrablock="{
                    id: 'grouper_easy_upload_template_report',
                    class: '',
                    style: 'flex-direction: row !important;',
                    name_hidden: 'grouper[attach][template_report]',
                    id_hidden: 'grouper_attach_template_report',
                    icon_attach: require('@/assets/gh_new_dessign/attach.svg'),
                    icon_trash: require('@/assets/gh_new_dessign/trash.svg'),
                }"
                :datalabel="{
                    id: 'label_grouper_easy_upload_final_template_report',
                    style: ''
                }"
                :datainput="{
                    text: $t('attach_template_report'),
                    label_required: $t('template_report'),
                    show_image: false,
                    delete: true,
                    view: true,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.attach !== undefined && CreationGrouper.grouper_data.attach.template_report !== undefined ? CreationGrouper.grouper_data.attach.template_report.file_name : '',
                    file_path: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.attach !== undefined && CreationGrouper.grouper_data.attach.template_report !== undefined ? CreationGrouper.grouper_data.attach.template_report.file_path : '',
                    file_download: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.attach !== undefined && CreationGrouper.grouper_data.attach.template_report !== undefined ? CreationGrouper.grouper_data.attach.template_report.file_download : '',
                    required: true
                }"
            />
        </div>
        <div class="gh-row-content" v-if="show.model_report">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('report') + ':'}}</label>
            <GhEasyUpload
                :extrablock="{
                    id: 'grouper_easy_upload_report',
                    class: '',
                    style: 'flex-direction: row !important;',
                    name_hidden: 'grouper[attach][report]',
                    id_hidden: 'grouper_attach_report',
                    icon_attach: require('@/assets/gh_new_dessign/attach.svg'),
                    icon_trash: require('@/assets/gh_new_dessign/trash.svg'),
                }"
                :datalabel="{
                    id: 'label_grouper_easy_upload_report',
                    style: ''
                }"
                :datainput="{
                    text: $t('attach_model_report'),
                    show_image: false,
                    delete: true,
                    view: true,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.attach !== undefined && CreationGrouper.grouper_data.attach.report !== undefined ? CreationGrouper.grouper_data.attach.report.file_name : '',
                    file_path: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.attach !== undefined && CreationGrouper.grouper_data.attach.report !== undefined ? CreationGrouper.grouper_data.attach.report.file_path : '',
                    file_download: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.attach !== undefined && CreationGrouper.grouper_data.attach.report !== undefined ? CreationGrouper.grouper_data.attach.report.file_download : '',
                    required: true
                }"
            />
        </div>
        <div class="gh-row-content" v-if="show.value">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('price') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.price !== undefined ? CreationGrouper.grouper_data.price : ''}}</label>
        </div>
        <div class="gh-row-content" v-if="show.basic_data">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('activity_sector') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.activity !== undefined ? Object.values(CreationGrouper.grouper_data.activity).join(', ') : ''}}</label>
        </div>
        <div class="gh-row-content" v-if="show.basic_data">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('number_employees') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.num_employee !== undefined ? Object.values(CreationGrouper.grouper_data.num_employee).join(', ') : ''}}</label>
        </div>
        <div class="gh-row-content" v-if="show.basic_data">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('country') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.country !== undefined ? Object.values(CreationGrouper.grouper_data.country).join(', ') : ''}}</label>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    import {mapState} from "vuex";

    export default {
        name: "include_format",
        components: {
            GhChecks,
            GhDataSelector,
            GhEasyUpload
        },
        computed:{
            ...mapState(['CreationGrouper','getFeeds']),
        },
        data(){
            return {
                showImpartation: false,
                haveData: false,
                toUpdate: false,
                show: {
                    template_report: false,
                    model_report: false,
                    value: false,
                    basic_data: false,
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                if (this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.item !== undefined) this.formatItem(this.CreationGrouper.grouper_data.item);

                this.show.template_report = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.show_template_report !== undefined && this.CreationGrouper.grouper_data.show_template_report;
                this.show.model_report = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.show_model_report !== undefined && this.CreationGrouper.grouper_data.show_model_report;
                this.show.value = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.show_value !== undefined && this.CreationGrouper.grouper_data.show_value;
                this.show.basic_data = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.show_basic_data !== undefined && this.CreationGrouper.grouper_data.show_basic_data;

                this.haveData = true;
            }
        },
        methods:{
            formatItem(format){
                this.showImpartation = format === 'full';
            },
            async checkImpartation(){
                if(this.$refs.RefItemFull.selected) {
                    await this.$store.dispatch('getImpartationType', {
                        items: Object.keys(this.$refs.RefImpartation.checkdata)
                    }, {root: true}).then((response) => {
                        if(response.status === 200) {
                            this.show.final_report = response.data.data.final_report;
                            this.show.draft_report = response.data.data.draft_report;
                        }
                    });
                }else{
                    this.show.final_report = false;
                    this.show.draft_report = false;
                }
            }
        }
    }
</script>

<style>
    #label_grouper_impartation_selector_label, #label_grouper_stamp_selector_label, #label_grouper_certificate_selector_label{
        width: 150px;
        text-align: end;
    }
</style>
<template>
    <div v-if="haveData">
        <GhTableCommon
            style="margin-top: 10px;"
            :extratable="{
                id: 'companies_by_itinerary_' + id_itinerary
            }"
            :header="header"
            :data="ItineraryAssign.companies"
        >
            <template v-slot:actions="{itemProp}">
                <template v-if="itemProp.assigned">
                    <GhAction
                        :dataction="{
                            id: 'edit_assign_company_' + itemProp.id,
                            text: $t('modify'),
                            icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                        @click="setAssign(itemProp.id, itemProp.name)"
                    />
                </template>
                <template v-else>
                    <GhAction
                        :dataction="{
                            id: 'edit_assign_company_' + itemProp.id,
                            text: $t('assign'),
                            icon: require('../../../../../assets/gh_new_dessign/tick.svg'),
                        }"
                        @click="setAssign(itemProp.id, itemProp.name)"
                    />
                </template>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px;">
            <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapState} from "vuex";

    export default {
        name: 'list_companies_by_itinerary',
        props: ['id_itinerary'],
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['ItineraryAssign'])
        },
        data(){
            return {
                actualId: '',
                assignId: '',
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 60%;'},
                    {text: 'leader', field: 'leader', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 40%;'},
                    {text: 'start_date', field: 'start_date', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'end_date', field: 'end_date', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                this.actualId = this.$props.id_itinerary;
                await this.$store.dispatch('getCompaniesByItinerary', {
                    id: this.actualId
                }, {root: true});

                this.haveData = true;
            }
        },
        async updated(){
            if(this.actualId !== this.$props.id_itinerary){
                this.actualId = this.$props.id_itinerary;

                this.haveData = false;

                await this.$store.dispatch('getCompaniesByItinerary', {
                    id: this.actualId
                }, {root: true});

                this.haveData = true;
            }
        },
        methods: {
            async setAssign(id, name){
                this.assignId = id;

                this.$parent.$parent.$parent.titlePopup = name + ' - ' + this.$router.options.GhSubTabAssign.find((i) => i.id === this.actualId).value;

                await this.$store.dispatch('getCompanyAssociatedByItinerary', {
                    itinerary_uuid: this.actualId,
                    company_uuid: this.assignId,
                }, {root: true});

                await this.$store.dispatch('getWeightingGroupItineraryCompany', {
                    itinerary_uuid: this.actualId,
                    company_uuid: this.assignId,
                }, {root: true});

                this.Global.openPopUp('popup_assign');
            }
        }
    }
</script>
<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'list_config_generic_holidays',title: $t('generic_holidays')}">
            <template v-slot:content>
                <form id="form_generic_holidays">
                    <div class="gh-content-global" :key="refresh">
                        <div class="gh-row-content" style="width: 100% !important;">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description') + '*',
                                        class: 'T15_b',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    }"
                                    :datainput="{
                                        id: 'generic_holiday_description',
                                        name: 'generic_holiday[description]',
                                        required: true,
                                        value: ConfigGenericHolidays.data.description !== undefined ? ConfigGenericHolidays.data.description : '',
                                        style: 'width: 100%'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ConfigGenericHolidays.data && ConfigGenericHolidays.data.description !== undefined ? ConfigGenericHolidays.data.description : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhCalendar
                                    :datalabel="{
                                        id: 'label_date_generic_holiday',
                                        text: $t('data'),
                                        style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id:'generic_holiday_date',
                                        name:'generic_holiday[date]',
                                        value: date,
                                        required: true,
                                        format: 'DD/MM',
                                        placeholder: 'DD/MM'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('data') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ConfigGenericHolidays.data && ConfigGenericHolidays.data.event_date !== undefined ? ConfigGenericHolidays.data.event_date : ''}}</label>
                            </template>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                type="submit"
                @click="submitGenericHoliday"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>
<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    export default {
        name: "generic_holiday_template",
        components: {
            GhCalendar,
            GhTextarea,
            GhNoDropSelector,
            GhButton,
        },
        data(){
            return {
                haveData:false,
                refresh: 0,
                date:''
            }
        },
        computed: {
            ...mapState(['ConfigGenericHolidays']),
        },
        watch:{
            'ConfigGenericHolidays.data.event_date'(data){
                this.date = data !== undefined ? data + '/' : '';
                if(data !== undefined){
                    this.refresh++;
                }
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                this.ConfigGenericHolidays.data = [];
                this.$root._route.params.id ? await this.$store.dispatch('getGenericHolidaysData', {id: this.$root._route.params.id}, {root: true}) : '';
                this.haveData = true;
            }
        },
        methods: {
            async submitGenericHoliday() {
                if(this.Global.checkRequired('form_generic_holidays') == 'OK') {
                    const formData = new FormData(document.getElementById('form_generic_holidays'));
                    this.$store.dispatch('setGenericHolidayData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        searchData:[],
        resultTitle: 'no_results_found',
        haveResult:false,
    },
    mutations: {
        loadHistoricalSearchData(state,payload){
            state.searchData = payload
        },
        loadHistoricalTitleResult(state, payload){
            state.resultTitle = payload
        },
        loadHistoricalHaveResult(state, payload){
            state.haveResult = payload
        }
    },
    actions: {
        async removeHistorical(state, item){
            let requestLogin = API_URL + "support/tickets/"+item.id+"/reopen";

            axios({
                method: "post",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        router.push({name: 'support'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        destroyedHistoricalResultData(state){
            state.commit("loadHistoricalSearchData", '');
            state.commit('loadHistoricalTitleResult', 'no_results_found');
            state.commit("loadHistoricalHaveResult", false);
        },
        async searchHistoricalData(state,items){
            let requestLogin = API_URL + 'support/tickets';

            let params = {};

            var count_category = 0;
            var count_priority = 0;
            var count_assigned_to = 0;
            items.formData.forEach((item) => {
                if(item[0] == 'historical[category][]'){
                    params['historical[category]['+count_category+']'] = item[1];
                    count_category++;
                }else if(item[0] == 'historical[priority][]'){
                    params['historical[priority]['+count_priority+']'] = item[1];
                    count_priority++;
                }else if(item[0] == 'historical[assigned_to][]'){
                    params['historical[assigned_to]['+count_assigned_to+']'] = item[1];
                    count_assigned_to++;
                }else {
                    params[item[0]] = item[1];
                }
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    state.commit("loadHistoricalSearchData", response.data.data);
                    state.commit("loadHistoricalTitleResult", response.data.data.length);
                    state.commit("loadHistoricalHaveResult", true);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}

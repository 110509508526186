<template>
    <div v-if="haveData" :key="'KEY61' + refreshElement">
        <div class="section_line" style="margin-top: 10px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('referential')}}</div>
        </div>
        <div class="grid-container-2">
            <div class="grid-item min-width-460 references" v-for="(item,index) in selectors" :key="'KEY62' + index" :style="!$root._route.params.view && $root._route.params.type !== 'enabled' && !props.view[item.key] && (item.key === 'groupers' || item.key === 'exercises') ? 'height: 18px;' : !$root._route.params.view && $root._route.params.type !== 'enabled' && item.key !== 'exercises' && item.key !== 'groupers' && props.view.exercises && props.view.groupers ? 'margin-left: 34px;' : ''">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled' && !props.view[item.key]">
                    <GhDataSelector v-if="item.key !== 'topics' && item.type == 'normal'"
                        :datablock="{
                            id: props.name + '_select_' + item.key,
                            class: 'T15_b',
                            subtitle:  $t('select') + ' ' + $t(item.key).toLowerCase() + ':',
                            text: $t(item.key) ,
                            label: $t(item.key),
                            type: 'checkbox',
                            columns: 3,
                            style: '',
                            required: false
                        }"
                        :datainput="{
                            id: 'value_' + props.name + '_' + item.key,
                            name: props.name + '[' + item.key + '][]'
                        }"
                        :selected_input="props.selecteds !== undefined && props.selecteds[item.key] !== undefined ? props.selecteds[item.key] : []"
                        :feed="item.key == 'topics' ? getFeeds.feed_topic : item.key == 'exercises' ? getFeeds.feed_exercises : item.key == 'groupers' ? getFeeds.feed_groupers : []"
                    />
                    <GhTreeSelectorBlock v-else
                        :dataBlock="{
                            type: 'checkbox',
                            id: props.name + '_select_' + item.key,
                            label: $t(item.key),
                            title: $t(item.key),
                            text: $t('select') + ' ' + $t(item.key).toLowerCase() + ':',
                            name: props.name + '[' + item.key + '][]',
                            id_value: 'value',
                            name_label: 'text',
                            child_key: 'children',
                            required: false,
                            checkparentdata: true,
                            style: '',
                            label_style: props.name !== 'search' ? 'width: 149px' : '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        }"
                        :disabled_input="item.key === 'topics' && props.fixTopics !== undefined ? props.fixTopics : []"
                        :selected_input="item.key === 'thesauros' && props.selecteds !== undefined && props.selecteds.thesauros !== undefined ? props.selecteds.thesauros : item.key === 'topics' && props.selecteds !== undefined && props.selecteds.topics !== undefined ? props.selecteds.topics : []"
                        :items="item.key !== 'topics' ? getFeeds.feed_reference_thesauros : getFeeds.feed_topic"
                        :ref="item.key === 'topics' ? 'refTopicsReferentials' : ''"
                    />
                </template>
                <template v-else>
                    <div style="display: flex;">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px!important; min-width: 150px!important; text-align: right;">{{$t(item.key) + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{props.selecteds !== undefined && props.selecteds[item.key] !== undefined ? (item.key == 'thesauros' ? thesaurosView : item.key == 'topics' ?  topicsView : props.selecteds[item.key] !== undefined && props.selecteds[item.key] !== null ? Object.values(props.selecteds[item.key]).join(', ') : '') : ''}}</label>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "reference_thesauros",
        props:['props'],
        components: {
            GhDataSelector,
            GhTreeSelectorBlock
        },
        computed: {
            ...mapState(['getFeeds']),
        },
        data(){
            return {
                haveData: false,
                selectors: [
                    {key:'groupers', type: 'normal'},
                    {key:'exercises', type: 'normal'},
                    {key:'topics', type: 'tree'},
                    {key:'thesauros', type: 'tree'},
                ],
                thesaurosView: '',
                topicsView: '',
                refreshElement: 0
            }
        },
        watch:{
            'props.fixTopics'(){
                if(this.$refs.refTopicsReferentials !== undefined){
                    this.mixTopics();
                }
            },
            'haveData'(){
                var that = this;
                setTimeout(function (){
                    if(that.$refs.refTopicsReferentials !== undefined){
                        that.mixTopics();
                        that.refreshElement++;
                    }
                },1);
            }
        },
        async beforeMount(){
            if(this.props !== undefined && this.props.selecteds !== undefined && this.props.selecteds['thesauros'] !== undefined && this.props.selecteds['thesauros'].length !== 0){
                this.props.selecteds['thesauros'].forEach((thesauros) => {
                    this.thesaurosView += thesauros.text + ', ';
                });

                if(this.thesaurosView !== '') this.thesaurosView = this.thesaurosView.slice(0, -2);
            }

            if(this.props !== undefined && this.props.selecteds !== undefined && this.props.selecteds['topics'] !== undefined && this.props.selecteds['topics'].length !== 0){
                this.props.selecteds['topics'].forEach((topics) => {
                    this.topicsView += topics.text + ', ';
                });

                if(this.topicsView !== '') this.topicsView = this.topicsView.slice(0, -2);
            }

            this.haveData = true;
        },
        methods:{
            mixTopics(){
                var mixed = [...this.$refs.refTopicsReferentials[0].datasaved,...(this.props.fixTopics !== undefined ? this.props.fixTopics : [])]

                const filteredArray = mixed.reduce((acc, obj) => {
                    if (!acc.some(item => item.value === obj.value)) {
                        acc.push(obj);
                    }
                    return acc;
                }, []);

                this.$refs.refTopicsReferentials[0].datasaved = filteredArray;
                this.$refs.refTopicsReferentials[0].afterData();
                this.$refs.refTopicsReferentials[0].save();
            }
        }
    }
</script>

<style>
    #exercise_select_groupers_selector_label,#exercise_select_exercises_selector_label,#exercise_select_thesauros_selector_label,#grouper_select_thesauros_selector_label,#exercise_select_topics_selector_label{
        width: 150px;
        text-align: end;
    }
    #grouper_select_groupers_selector_label,#grouper_select_exercises_selector_label,#grouper_select_topics_selector_label{
        width: 150px;
        text-align: end;
    }

    #search_select_groupers_selector_label, #search_select_topics_selector_label, #search_associate_select_groupers_selector_label{
        margin-left: 35px;
    }

    .references > div > .gh_data_selector{
        margin-left: 0px !important;
    }
</style>
<template>
    <div class="gh-content-global">
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('name') + '*',
                        style:'min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_name',
                        name: 'request_collaboration[personal_data][name]',
                        style: 'width:60%',
                        value: persistance.name,
                        type: 'text'
                    }"
                    ref="refName"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('surnames') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_surname',
                        name: 'request_collaboration[personal_data][surname]',
                        style: 'width:60%',
                        value: persistance.surname,
                        type: 'text'
                    }"
                    ref="refSurname"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('nif_nie') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_nif',
                        name: 'request_collaboration[personal_data][nif]',
                        style: 'width:20%; min-width: 100px;',
                        value: persistance.nif,
                        type: 'text'
                    }"
                    ref="refNif"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('full_address') + '*',
                        style:'min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_full_address',
                        name: 'request_collaboration[personal_data][full_address]',
                        style: 'width:60%',
                        value: persistance.full_address,
                        type: 'text'
                    }"
                    ref="refFullAddress"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('locality') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_locality',
                        name: 'request_collaboration[personal_data][locality]',
                        style: 'width:60%',
                        value: persistance.locality,
                        type: 'text'
                    }"
                    ref="refLocality"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('cp') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_cp',
                        name: 'request_collaboration[personal_data][cp]',
                        style: 'width:20%; min-width: 100px;',
                        value: persistance.cp,
                        type: 'text'
                    }"
                    ref="refCp"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_country',
                        class: 'T15_b',
                        subtitle: $t('select_country') + ':',
                        text: $t('country'),
                        label: $t('country'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_country',
                        name: 'request_collaboration[personal_data][country]'
                    }"
                    :feed="getFeeds.feed_country"
                    :selected_input="getFeeds.feed_ho_is_spain"
                    @accept="searchCommunity"
                    ref="refCountry"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_community',
                        class: 'T15_b',
                        subtitle: $t('select_community') + ':',
                        text: $t('community'),
                        label: $t('community'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_community',
                        name: 'request_collaboration[personal_data][community]'
                    }"
                    :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                    :selected_input="[]"
                    @accept="searchProvince"
                    ref="refCommunity"
                    :key="'refresh_community_' + refreshCommunity"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_province',
                        class: 'T15_b',
                        subtitle: $t('select_province') + ':',
                        text: $t('province'),
                        label: $t('province'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_province',
                        name: 'request_collaboration[personal_data][province]'
                    }"
                    :feed="getterProvinceByCommunity !== undefined ? getterProvinceByCommunity : []"
                    :selected_input="[]"
                    ref="refProvince"
                    :key="'refresh_province_' + refreshProvince"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('email') + '*',
                        style: 'min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'company_email',
                        name: 'request_collaboration[personal_data][email]',
                        style: 'width: 60%',
                        value: persistance.email,
                        type: 'text',
                    }"
                    ref="refEmail"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('telephone') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_personal_data_telephone',
                        name: 'request_collaboration[personal_data][telephone]',
                        style: 'width: 60%',
                        value: persistance.telephone,
                        type: 'int',
                    }"
                    ref="refTelephone"
                />
            </div>
            <div class="gh-row-column"></div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_professional_experience',
                        class: 'T15_b',
                        subtitle: $t('select_professional_experience') + ':',
                        text: $t('professional_experience'),
                        label: $t('professional_experience'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_professional_experience',
                        name: 'request_collaboration[personal_data][professional_experience]'
                    }"
                    :feed="getFeeds.feed_professional_experience"
                    :selected_input="[]"
                    ref="refProfessionalExperience"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_sectors_experience',
                        class: 'T15_b',
                        subtitle: $t('select_sectors_experience') + ':',
                        text: $t('sectors_experience') + '*',
                        label: $t('sectors_experience'),
                        type: 'checkbox',
                        columns: 3,
                        required: true,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_sectors_experience',
                        name: 'request_collaboration[personal_data][sectors_experience][]'
                    }"
                    :feed="getFeeds.feed_sectors_experience"
                    :selected_input="[]"
                    ref="refSectorsExperience"
                />
            </div>
            <div class="gh-row-column no-min-width">

            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhChecks
                    :datalabel="{
                        text: $t('disponibility_to_travel'),
                        style: '',
                        class: '',
                        id: ''
                    }"
                    :datainput="{
                        required: false,
                        selected: false,
                        type: 'checkbox',
                        id: 'request_collaboration_personal_data_disponibility_to_travel',
                        value: 1,
                        style: 'margin-left: 160px;',
                        name: 'request_collaboration[personal_data][disponibility_to_travel]'
                    }"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_communities_where_can_work',
                        class: 'T15_b',
                        subtitle: $t('select_communities_where_can_work') + ':',
                        text: $t('communities_where_can_work') + '*',
                        label: $t('communities_where_can_work'),
                        type: 'checkbox',
                        columns: 3,
                        required: true,
                        inputAll: true,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_communities_where_can_work',
                        name: 'request_collaboration[personal_data][communities_where_can_work][]'
                    }"
                    :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                    :selected_input="[]"
                    ref="refCommunitiesWhereCanWork"
                    :key="'refresh_communities_where_can_work_' + refreshCommunity"
                />
            </div>
            <div class="gh-row-column"></div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_personal_data_language_writing_skills',
                        class: 'T15_b',
                        subtitle: $t('select_languages') + ':',
                        text: $t('language_writing_skills'),
                        label: $t('language_writing_skills'),
                        type: 'checkbox',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_personal_data_language_writing_skills',
                        name: 'request_collaboration[personal_data][languages][]'
                    }"
                    :feed="getFeeds.feed_languages_evaluator"
                    :selected_input="[]"
                    ref="refLanguageWritingSkills"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'personal_data',
        components: {
            GhInputTextField,
            GhDataSelector,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterProvinceByCommunity', 'getterCommunityByCountry']),
        },
        data(){
            return {
                refreshProvince: 0,
                refreshCommunity: 0,
                persistance: {
                    name: '',
                    surname: '',
                    nif: '',
                    full_address: '',
                    locality: '',
                    cp: '',
                    email: '',
                    telephone: '',
                }
            }
        },
        async beforeMount(){
            this.$store.dispatch('getCommunityByCountry', {
                country: Object.keys(this.getFeeds.feed_ho_is_spain)[0],
            }, {root: true});
        },
        watch: {
            'getterCommunityByCountry'(){
                this.refreshCommunity++;
            },
            'getterProvinceByCommunity'(){
                this.refreshProvince++;
            },
        },
        methods: {
            searchProvince(){
                this.persist();
                var community = this.$refs.refCommunity.checkdata;
                community = Object.keys(community);

                if(community.length !== 0){
                    community = community[0];
                    this.$store.dispatch('getProvinceByCommunity', {
                        community: community,
                    }, {root: true});
                }
            },
            searchCommunity(){
                this.persist();
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    this.$store.dispatch('getCommunityByCountry', {
                        country: country,
                    }, {root: true});
                }
            },
            persist(){
                this.persistance.name = this.$refs['refName'].datainput.value;
                this.persistance.surname = this.$refs['refSurname'].datainput.value;
                this.persistance.nif = this.$refs['refNif'].datainput.value;
                this.persistance.full_address = this.$refs['refFullAddress'].datainput.value;
                this.persistance.locality = this.$refs['refLocality'].datainput.value;
                this.persistance.cp = this.$refs['refCp'].datainput.value;
                this.persistance.email = this.$refs['refEmail'].datainput.value;
                this.persistance.telephone = this.$refs['refTelephone'].datainput.value;
            }
        }
    }
</script>

<style>
    .gh-row-line {
        display:flex;
        margin-bottom: 10px;
    }
    .gh-row-column {
        flex: 1; /* Cada columna ocupa un tercio del contenedor */
        display: block;
        justify-content: left;
    }
    .no-min-width > div {
        min-width: unset !important;
    }
    .no-min-width > div > div {
        margin-bottom: unset !important;
    }
    #request_collaboration_personal_data_country_selector_label, #request_collaboration_personal_data_professional_experience_selector_label, #request_collaboration_personal_data_province_selector_label, #request_collaboration_personal_data_community_selector_label, #request_collaboration_personal_data_sectors_experience_selector_label, #request_collaboration_personal_data_communities_where_can_work_selector_label, #request_collaboration_personal_data_language_writing_skills_selector_label{
        width: 150px !important;
        text-align: right;
    }
</style>
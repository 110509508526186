<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab v-bind:items="$router.options.GhSubTabsCreation" :dataBlock="{id: 'creation_tab_', calldispatch: 'getCreationTabActive'}"/>
        <component :is="viewLoad" :key="'KEY2' + refreshView" :id_grouper="TabCreation.idSubTabActive" />
    </div>
</template>

<script>
    import GhSubTab from "fe-gh-subtab-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_creation",
        components:{
          GhSubTab,
        },
        computed:{
          ...mapState(['TabCreation']),
        },
        watch:{
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            },
            'TabCreation.reload'(){
                this.viewLoad = this.TabCreation.subTabActive;
                this.refreshView++;
            }
        },
        data(){
            return{
                haveData: false,
                viewLoad: null,
                refreshView: 0
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getGroupersTabs', '', {root: true});
            }
        },
        mounted(){
            var that = this;
            setTimeout(function(){
                that.haveData = true;
            },200);
        }
    }
</script>
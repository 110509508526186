<template>
    <div>
        <div class="section_line" style="margin-top: 10px;">
            <div class="T15_b" style="padding-left:13px;">{{$t('type') + ': ' + ($root._route.params.view ? Object.values(ConfigTypeExercise.data.type[0])[1] : $parent.$parent.$refs['RefTypeExercise'].datasaved.text)}}</div>
        </div>
        <div class="gh-row-content" style="margin-top: 10px">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('answers') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_n_answers',
                        name: 'type_exercise[n_answers]',
                        style: 'width: 50px',
                        value: getterPossibleNumericQuestions.n_answers,
                        type: 'int',
                        limitNumMin: 1
                    }"
                    ref="RefAnswers"
                    @focusout="checkNumberPossibleAnswers"
                />
                <GhInputTextField
                    :datalabel="{
                        text: $t('interval') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_interval',
                        name: 'type_exercise[interval]',
                        style: 'width: 50px',
                        value: getterPossibleNumericQuestions.interval,
                        type: 'int',
                        limitNumMin: 1
                    }"
                    ref="RefInterval"
                    @focusout="checkInterval"
                />
                <GhInputTextField
                    :datalabel="{
                        text: $t('min_range') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_min_range',
                        name: 'type_exercise[min_range]',
                        style: 'width: 50px',
                        value: getterPossibleNumericQuestions.range.min,
                        type: 'int',
                        limitNumMin: 0
                    }"
                    ref="RefMinRange"
                    @focusout="checkRange"
                />
                <GhInputTextField
                    :datalabel="{
                        text: $t('max').toLowerCase() + '*',
                        style: 'margin-left: 10px;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_max_range',
                        name: 'type_exercise[max_range]',
                        style: 'width: 50px',
                        value: getterPossibleNumericQuestions.range.max,
                        type: 'int',
                        limitNumMin: 1
                    }"
                    ref="RefMaxRange"
                    @focusout="checkRange"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('answers') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPossibleNumericQuestions.n_answers}}</label>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('interval') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPossibleNumericQuestions.interval}}</label>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('min_range') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPossibleNumericQuestions.range.min}}</label>
                <label class="gh_text_field-label T15" style="margin-right: 10px; text-align: right;">{{$t('max').toLowerCase() + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPossibleNumericQuestions.range.max}}</label>
            </template>
        </div>
        <div v-if="getterPossibleNumericQuestions.answers.length !== 0" :style="$root._route.params.view ? '' : 'margin-top: -5px;'" style="padding-bottom: 10px; margin-top: 5px; padding-left: 160px">
            <GhTableCommon
                style="margin-top: 10px;"
                :extratable="{
                    id: 'possible_numeric_question_answers'
                }"
                :header="header"
                :data="getterPossibleNumericQuestions.answers"
                ref="RefTableQuestion"
            >
                <template v-slot:field_component="{itemProp, labelProp}">
                    <div v-if="labelProp.field === 'answers'">
                        <template v-if="!$root._route.params.view">
                            <GhTextarea
                                :datalabel="{
                                    text: '',
                                    class: '',
                                }"
                                :datainput="{
                                    id: 'type_exercise_possible_answer_' + itemProp.id,
                                    name: 'type_exercise[answers]['+itemProp.id+'][answer]',
                                    value: itemProp.answers,
                                    required: false,
                                    edited: false
                                }"
                                :ref="'RefPossibleAnswer' + itemProp.id"
                                @focusout="setPossibleAnswer(itemProp.id)"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" v-html="itemProp.answers"/>
                        </template>
                    </div>
                    <div v-if="labelProp.field === 'range'" style="display: flex; vertical-align: sub; height: 100%;">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{}"
                                :datainput="{
                                    required: false,
                                    id: 'type_exercise_possible_answer_' + itemProp.id,
                                    name: 'type_exercise[answers]['+itemProp.id+'][range_min]',
                                    value: itemProp.range !== undefined && itemProp.range.min !== undefined ? itemProp.range.min : '',
                                    type: 'int',
                                    limitNumMin: getterPossibleNumericQuestions.range.min,
                                    limitNumMax: getterPossibleNumericQuestions.range.max
                                }"
                                :disabled="itemProp.id === 0"
                                :ref="'RefPossibleAnswerRangeMin' + itemProp.id"
                                @focusout="setPossibleAnswer(itemProp.id)"
                            />
                            <template v-if="itemProp.id === 0">
                                <input type="hidden" :name="'type_exercise[answers]['+itemProp.id+'][range_min]'" :value="first">
                            </template>
                            <GhInputTextField
                                :datalabel="{}"
                                :datainput="{
                                    required: false,
                                    id: 'type_exercise_possible_answer_' + itemProp.id,
                                    name: 'type_exercise[answers]['+itemProp.id+'][range_max]',
                                    value: itemProp.range !== undefined && itemProp.range.max !== undefined ? itemProp.range.max : '',
                                    type: 'int',
                                    limitNumMax: getterPossibleNumericQuestions.range.max
                                }"
                                :disabled="itemProp.id === getterPossibleNumericQuestions.answers.length - 1"
                                :ref="'RefPossibleAnswerRangeMax' + itemProp.id"
                                @focusout="setPossibleAnswer(itemProp.id)"
                            />
                            <template v-if="itemProp.id === getterPossibleNumericQuestions.answers.length - 1">
                                <input type="hidden" :name="'type_exercise[answers]['+itemProp.id+'][range_max]'" :value="last">
                            </template>
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15 centered" style="width: 35px">{{itemProp.range !== undefined && itemProp.range.min !== undefined ? itemProp.range.min : ''}}</label>
                            <label class="gh_text_field-label T15" >{{'-'}}</label>
                            <label class="gh_text_field-label T15 centered" style="width: 35px;">{{itemProp.range !== undefined && itemProp.range.max !== undefined ? itemProp.range.max : ''}}</label>
                        </template>
                    </div>
                </template>
                <template v-slot:actions="{itemProp}">
                    <div  style="display: ruby-text; vertical-align: sub; height: 100%;">
                        <GhAction
                            :dataction="{
                                id: 'delete_possible_answer_' + itemProp['id'],
                                text: $t('delete'),
                                icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                                item_prop: itemProp
                            }"
                            @click="deletePossibleAnswer(itemProp)"
                        />
                    </div>
                </template>
            </GhTableCommon>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <div style="display: flex; margin-left: 160px;">
                <GhChecks
                    :datalabel="{text: $t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[0] === 1,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_evidence',
                        value: 1,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidence"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                />
                <GhChecks
                    :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[1] === 2,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_required_evidence',
                        value: 2,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidenceRequired"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)"
                />
            </div>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'}"
                :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.feedback[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('subquestion'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_subquestion'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_subquestion',
                    value: 1,
                    name: 'type_exercise[subquestion][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapState, mapGetters} from "vuex";
    import GhTextarea from "fe-gh-textarea-lib";

    export default {
        name: 'type_questionnaire',
        components: {
            GhTextarea,
            GhInputTextField,
            GhTableCommon,
            GhAction,
            GhChecks
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds']),
            ...mapGetters(['getterPossibleNumericQuestions'])
        },
        data(){
            return{
                header: [
                    {text: 'answers', field: 'answers', field_component: true, required: false, style: 'max-width: 10px; width: 100%; padding-right: 10px;'},
                    {text: 'range', field: 'range', field_component: true, required: false, style: 'min-width: 100px; max-width: 100px; width: 100px; padding-right: 10px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                first: '',
                last: ''
            }
        },
        mounted(){
            if(this.getterPossibleNumericQuestions.answers !== undefined && this.getterPossibleNumericQuestions.answers.length !== 0){
                this.first = this.getterPossibleNumericQuestions.answers[0].range.min;
                this.last = this.getterPossibleNumericQuestions.answers[this.getterPossibleNumericQuestions.answers.length - 1].range.max;
            }

            if(this.$root._route.params.view){
                this.header = [
                    {text: 'answers', field: 'answers', field_component: !this.$root._route.params.view, required: true, style: 'max-width: 10px; width: 100%; padding-right: 10px;'},
                    {text: 'range', field: 'range', field_component: true, required: false, style: 'min-width: 100px; max-width: 100px; width: 100px; padding-right: 10px;'}
                ];
            }
        },
        methods: {
            async checkNumberPossibleAnswers(){
                await this.$store.dispatch('createTablePossibleNumericQuestions', {
                    count: this.$refs['RefAnswers'].datainput.value
                }, {root: true});
            },
            async checkInterval(){
                await this.$store.dispatch('setIntervalPossibleNumericQuestions', {
                    interval: this.$refs['RefInterval'].datainput.value
                }, {root: true});
            },
            async checkRange(){
                await this.$store.dispatch('setRangePossibleNumericQuestions', {
                    min: this.$refs['RefMinRange'].datainput.value,
                    max: this.$refs['RefMaxRange'].datainput.value
                }, {root: true});
            },
            async setPossibleAnswer(item){
                this.first = item === 0 ? this.$refs['RefPossibleAnswerRangeMin' + item].datainput.value : '';
                await this.$store.dispatch('setTablePossibleNumericQuestions', {
                    answer: this.$refs['RefPossibleAnswer' + item].datainput.value,
                    range: {
                        min: this.$refs['RefPossibleAnswerRangeMin' + item].datainput.value,
                        max: this.$refs['RefPossibleAnswerRangeMax' + item].datainput.value
                    },
                    id: item
                }, {root: true});
            },
            async deletePossibleAnswer(item){
                await this.getterPossibleNumericQuestions.answers.forEach((i, k) => {
                    if(i.id === item.id){
                        this.$store.dispatch('deleteTablePossibleNumericQuestions', {
                            position: k
                        }, {root: true});
                    }
                });
            }
        }
    }
</script>

<style>
    #label_type_exercise_type_answer_selector_label {
        width: 150px;
        text-align: end;
    }
</style>
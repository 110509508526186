import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        resultData: [],
        resultTitle: 'no_results_found'
    },
    mutations: {
        loadResultSupervisedEvaluatedData(state,payload){
            state.resultData = payload.data;
        },
        loadTitleSupervisedEvaluatedResult(state, payload){
            state.resultTitle = payload;
        }
    },
    actions:{
        destroyedResultSupervisedEvaluatedData(state){
            state.commit("loadResultSupervisedEvaluatedData", '');
            state.commit('loadTitleSupervisedEvaluatedResult', 'no_results_found');
        },
        async resultSupervisedEvaluatedData(state,items){
            let requestLogin = API_URL +'search/supervised_evaluated';

            let params = {};

            let allEmpty = true;

            items.formData.forEach((item) => {
                params[item[0]] = item[1];

                if(item[1] != '' && item[0] != 'supervised_evaluated[criterio]'){
                    allEmpty = false;
                }
            });

            if(allEmpty){
                state.commit('loadTitleSupervisedEvaluatedResult', 'no_applied_filter');
            }else {
                await axios({
                    method: "get",
                    url: requestLogin,
                    params: params,
                }).then(
                    response => {
                        state.commit("loadResultSupervisedEvaluatedData", response.data);

                        var length = 0;

                        response.data.data.map((item) => {
                            length += item.content.length;
                        });

                        state.commit("loadTitleSupervisedEvaluatedResult", length);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        }
    }
}

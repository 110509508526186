var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'popup_evaluate_' + _vm.refreshAdvice,staticClass:"gh-popup-ellipsis-title"},[_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"RefPopupAdvice",attrs:{"generalConfiguration":{
            id: 'popup_advice',
            title: _vm.$t('hard_points_improvement_opportunities'),
            type: 'info',
            style: 'font-size: 20px; width: 1000px; min-width: 1000px; max-width: 1000px;overflow-y: auto !important;'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_popup_hard_points"}},[_c(_vm.advice,{tag:"component",attrs:{"edit":_vm.edit}})],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.edit)?_c('GhButton',{attrs:{"datainput":{
                    id: 'button_save_popup_advice',
                    text: _vm.$t('accept'),
                    class: 'button-questionnaire-secondary'
                }},on:{"click":function($event){return _vm.submitPopup()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'button_close_popup_advice',
                    text: _vm.$t('close'),
                    class: 'button-questionnaire-secondary'
                }},on:{"click":function($event){_vm.Global.closePopUp('popup_advice');_vm.refreshAdvice++;}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Todo from "@/view/todo/list_todo";
import EjecuteItinerary from "@/view/todo/template/ejecute_itinerary";
import CompanyAssing from "@/view/todo/template/company_assing";

const routes = [
    {
        path: '/todo',
        name: 'todo',
        component: Todo,
        props: {showMenu: true},
    },
    {
        path: '/todo/ejecute_itinerary/:id/:question_id',
        name: 'ejecute_itinerary_id',
        component: EjecuteItinerary,
        props: {id: null,question_id: null, showMenu: true, otherTitle: 'questionary'},
    },
    {
        path: '/todo/company_assing/:id',
        name: 'company_assing_id',
        component: CompanyAssing,
        props: {id: null, showMenu: true, otherTitle: 'assign'},
    },
];

export default routes;
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window",staticStyle:{"padding-bottom":"20px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'section_date_company',
            title: _vm.$t('date_company')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c(_vm.component.view_client,{tag:"component"})]:[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]]},proxy:true}])}),_vm._l((_vm.sections),function(label,labelIndex){return _c('div',{key:labelIndex},[(label.show)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'section_' + label.id,
                title: _vm.$t(label['name']),
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import ListCreation from "@/view/creation/list_creation";
import list_grouper from "@/view/creation/templates/list_groupers.vue";
import view_grouper from "@/view/creation/templates/views/view_grouper.vue";
import search_associate from "@/view/creation/templates/search/search_associate.vue";
import search_format from "@/view/creation/templates/search/search_format.vue";
import conditions from './conditions/route';

const route = [
    {
        path: '/list_creation',
        name: 'create',
        props: {showMenu: true},
        component: ListCreation
    },
    {
        path: '/list_grouper',
        name: 'list_grouper',
        props: {showMenu: true},
        component: list_grouper
    },
    {
        path: '/grouper/:grouper/:type',
        name: 'view_grouper',
        props: {id:null, showMenu: true},
        component: view_grouper,
        children: [
            {
                path: '/grouper/:grouper/:type/:id',
                name: 'view_grouper_id',
                component: view_grouper,
                props: {id: null, showMenu: true, otherTitle: 'grouper'},
            },
            {
                path: '/grouper/:grouper/:type/:id/:view',
                name: 'view_grouper_id_view',
                component: view_grouper,
                props: {id: null, view: true, showMenu: true, otherTitle: 'grouper'},
            }
        ]
    },
    {
        path: '/search/:grouper/:id/:view',
        name: 'view_grouper_id_view_search',
        component: view_grouper,
        props: {id: null, grouper: null, view: true, showMenu: false, background:true, otherTitle: 'grouper'},
    },
    {
        path: '/config/grouper/:from/:grouper/:id/:view/:type',
        name: 'config_grouper_view',
        component: view_grouper,
        props: {id: null, from: null, grouper: null, view: true, type: true, showMenu: false, background:true, otherTitle: 'exercise'},
    },
    {
        path: '/grouper/:parent_grouper_id/:parent_id/:grouper/:id/:version/:type',
        name: 'config_grouper_id_version_float',
        component: view_grouper,
        props: {
            parent_grouper_id: null,
            parent_id: null,
            grouper: null,
            id: null,
            version: false,
            type: true,
            showMenu: false,
            background:true,
            otherTitle: 'grouper'
        }
    },
    {
        path: '/associate-search',
        name: 'associate_search',
        component: search_associate,
        props: {showMenu: true}
    },
    {
        path: '/format-search',
        name: 'search_format',
        component: search_format,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...conditions,
]

export default routes;
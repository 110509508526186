import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list: [],
        listPrograms: [],
        listEvaluations: [],
        refreshListClients: false,
        refreshListProgramsClients: false,
        refreshListEvaluationsClients: false
    },
    mutations: {
        loadClients(state, payload) {
            state.refreshListClients = !state.refreshListClients;
            state.list = payload;
        },
        loadProgramsClients(state, payload) {
            state.refreshListProgramsClients = !state.refreshListProgramsClients;
            state.listPrograms = payload;
        },
        loadEvaluationsClients(state, payload) {
            state.refreshListEvaluationsClients = !state.refreshListEvaluationsClients;
            state.listEvaluations = payload;
        }
    },
    getters: {
        getterListClients(state){
            const refreshListClients = state.refreshListClients; // eslint-disable-line no-unused-vars
            return state.list;
        },
        getterListProgramsClients(state){
            const refreshListProgramsClients = state.refreshListProgramsClients; // eslint-disable-line no-unused-vars
            return state.listPrograms;
        },
        getterListEvaluationsClients(state){
            const refreshListProgramsClients = state.refreshListEvaluationsClients; // eslint-disable-line no-unused-vars
            return state.listEvaluations;
        }
    },
    actions: {
        async getClients(state){
            await axios.get(
                API_URL + "clients"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadClients', response.data.data);
                    }
                }
            )
        },
        async getProgramsFinishByCleint(state,items){
            let requestLogin = API_URL + "user/company/" + items.id + "/programs/finish";

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadProgramsClients', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getEvaluationsAndStampFinishByClient(state,items){
            let requestLogin = API_URL + "user/company/" + items.id + "/evaluations/finish";

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadEvaluationsClients', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPartnerClub(state, item){
            let requestLogin = API_URL + "partner-club/" + item.id;
            let formData = new FormData();

            formData.append('partner_club', item.check);

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        //
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};

<template>
    <div v-if="haveData">
        <GhTableCommon
            style="margin-top:10px;"
            :extratable="{
                id: 'assigned_users'
            }"
            :header="header"
            :data="getterAssignedUsers"
        />
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapGetters} from "vuex";

    export default {
        name: "list_assigned_users",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterAssignedUsers'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'rol', field: 'rol', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'program', field: 'program', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'part_program', field: 'part_program', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 50%;'}
                ]
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getAssignedUsers', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
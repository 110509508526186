<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_warning_save',
            title: $t('info'),
            type: 'info',
            style: 'width: 400px; min-width: 400px; max-width: 400px;'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <div>
                        <div class="T15_b" style="margin-left: 35px;">
                            {{$t('changes_have_been_saved_correctly')}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_close_popup_warning_save',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_warning_save');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "popup_warning_save",
        components: {
            GhButton,
            GhPopUp
        }
    }
</script>
import ListEvaluators from "@/view/perms/evaluators/list_evaluators.vue";

const route = [
    {
        path: '/perms_evaluators',
        name: 'perms_evaluators',
        component: ListEvaluators,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
]

export default routes;
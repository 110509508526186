import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        users: [],
        refreshUsers: false
    },
    mutations: {
        loadResultSearchUsers(state, payload){
            state.refreshUsers = !state.refreshUsers;
            state.users = payload;
        }
    },
    getters: {
        getterUsersAssociated(state) {
            const dummy = state.refreshUsers; // eslint-disable-line no-unused-vars
            return state.users;
        }
    },
    actions: {
        async checkAssociatedUsers(state, item){
            let request =  API_URL + 'search/permissions';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            let params = new URLSearchParams(formData);

            await axios({
                method: "get",
                url: request,
                params: params,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        console.log(response.data.data);
                        state.commit("loadResultSearchUsers", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        cleanAssociatedUsers(state){
            state.commit("loadResultSearchUsers", []);
        }
    }
};

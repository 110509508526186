<template>
    <div v-if="haveData">
        <div class="gh-row-content">
            <GhInputTextField
                :datalabel="{
                    text: $t('code'),
                    style: 'margin-left: 35px; padding-right: 10px;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'search_format_code',
                    name: 'search_format[code]',
                    style: 'width: 25%',
                    value: '',
                    type: 'text'
                }"
            />
            <GhInputTextField
                :datalabel="{
                    text: $t('name'),
                    style: 'margin-left: 35px; padding-right: 10px;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'search_format_name',
                    name: 'search_format[name]',
                    style: 'width: 25%',
                    value: '',
                    type: 'text'
                }"
            />
        </div>
        <div class="gh-row-content">
            <GhTreeSelectorBlock
                :dataBlock="{
                    type: 'checkbox',
                    id: 'search_format_topic',
                    label: $t('topic'),
                    title: $t('topic'),
                    text: $t('select_topic') + ':',
                    name: 'search_format[topic][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: true,
                    checkparentdata: true,
                    style: '',
                    label_style: 'margin-left: 2px;',
                    popupStyle: 'min-width: 900px; width: 55%;'
                }"
                :selected_input="[]"
                :items="getFeeds.feed_topic"
            />
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "associate_search",
        components:{
            GhTreeSelectorBlock,
            GhInputTextField
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                await this.$store.dispatch('getFeedTypeExerciseGrouper', {id: this.$root._route.params.idSearch}, {root: true});
                this.haveData = true;
            }
        }
    }
</script>

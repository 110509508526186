var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?[(_vm.getterProgramsAssociateCompany[_vm.itinerary] !== undefined)?[_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'programs_associate_companies_' + _vm.itinerary
                },"header":_vm.header,"data":_vm.getterProgramsAssociateCompany[_vm.itinerary].data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterProgramsAssociateCompany[_vm.itinerary].actions !== undefined)?[(_vm.getterProgramsAssociateCompany[_vm.itinerary].actions.associate)?[_c('router-link',{attrs:{"to":{name: 'assignation_base', params: {id: itemProp.id, itinerary: _vm.itinerary}}}},[_c('GhAction',{attrs:{"dataction":{
                                        id: 'view_programs_associate_companies_' + itemProp.id,
                                        text: _vm.$t('associate'),
                                        icon: require('../../../../assets/gh_new_dessign/tick.svg')
                                    }}})],1)]:_vm._e()]:_vm._e()]}}],null,false,2691173853)})]:_vm._e()]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
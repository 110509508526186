import conditions from "@/view/creation/templates/views/conditions.vue";

const route = [
    {
        path: '/items-conditions/:id/:view',
        name: 'create_dependences',
        component: conditions,
        props: {id: null, view: null, showMenu: false, background:true, floatWindow:true, otherTitle: 'define_conditions'},
    },
];

const routes = [
    ...route,
]

export default routes;
import ConfigLanguages from "@/view/config/evaluators/languages/template/languages.vue";

const routes = [
    {
        path: '/config/evaluator/languages',
        name: 'config_languages',
        component: ConfigLanguages,
        props: {id: null, showMenu: true, otherTitle: 'languages'},
        children: [
            {
                path: '/config/evaluator/languages/:id',
                name: 'config_languages_id',
                component: ConfigLanguages,
                props: {id: null, showMenu: true, otherTitle: 'languages'},
            },
            {
                path: '/config/evaluator/languages/:id/:view',
                name: 'config_languages_id_view',
                component: ConfigLanguages,
                props: {id: null, view: true, showMenu: true, otherTitle: 'languages'},
            }
        ]
    }
];

export default routes;
<template>
    <div>
        <template v-if="haveData">
            <div v-if="getterEvaluatorEvaluations[itinerary] !== undefined">
                <GhTableCommon
                    :extratable="{ id: 'table_evaluations_' + itinerary}"
                    :data="getterEvaluatorEvaluations[itinerary]"
                    :header="header"
                >
                </GhTableCommon>
            </div>
        </template>
        <template v-else>
            <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </template>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'content_section_evaluations',
        props: ['itinerary'],
        components: {
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterEvaluatorEvaluations'])
        },
        data(){
            return {
                header: [
                    {text: 'company', field: 'company', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'program', field: 'program', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'n_employees', field: 'n_employees', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'sector', field: 'sector', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'range_ev', field: 'range_ev', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'stamp', field: 'stamp', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'rol', field: 'rol', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'team_ev', field: 'team_ev', sorting: true, typeSort: 'string', style: 'min-width: 75px; max-width: 75px; width: 12.5%;'},
                    {text: 'date_start', field: 'date_start', sorting: true, typeSort: 'date', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'date_end', field: 'date_end', sorting: true, typeSort: 'date', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getEvaluatorEvaluation', {
                id: this.$root._route.params.id,
                itinerary: this.itinerary
            }, {root: true});

            this.haveData = true;
        }
    }
</script>
<template>
    <div>
        <template v-if="haveData">
            <div v-for="(label, labelIndex) in getterOngoingPrograms" :key="'SECTION_ONGOING_PROGRAM' + labelIndex">
                <GhDropSelector
                    :datablock="{
                        id: 'grouper_' + label.id,
                        title: $t(label['name']),
                        second_title: '',
                        num_results: '',
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="ongoing_programs_companies" :itinerary="label.id" />
                    </template>
                </GhDropSelector>
            </div>
        </template>
        <template v-else-if="!haveData">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapGetters} from "vuex";

    import ongoing_programs_companies from "@/view/assign/ongoing_programs/template/ongoing_programs_companies.vue";

    export default {
        name: 'list_ongoing_programs',
        components: {
            GhDropSelector,
        },
        computed: {
            ...mapGetters(['getterOngoingPrograms'])
        },
        data(){
            return {
                haveData: false,
                ongoing_programs_companies: ongoing_programs_companies
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getOngoingPrograms', '', {root: true});

            this.haveData = true;
        }
    }
</script>
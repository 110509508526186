// config.js ----------------------------------------------------------

//export const API_URL = "http://localhost:8090/api";

// export const API_URL = "http://dev74.globalholdings.es/users/sgutierrez/new-fe/be-axios-test/Test01Be.php?action=";
// export const LoginUrl = "http://dev81.globalholdings.es/users/imartinez/exenius-eve-be-repo/branches/full_merge/";
// //
//
export const API_URL = window.API_URL;
export const LoginUrl = window.API_URL;



//http://dev81.globalholdings.es/users/enunezp/exenius-eve-be-repo/branches/configuration/

//http://localhost/dev/newfe-sample/be-axios-test/Test01Be.php?action=

//http://192.168.6.86/users/sgutierrez/new-fe/be-axios-test/Test01Be.php?action=


//http://192.168.6.86/users/sgutierrez/new-fe/be-axios-test/files/
// login|person|places|epis|email - count=(default=10)

export default {LoginUrl,API_URL};

export const IS_CONSOLE_DEBUG = true;

export const APP_SHORT_NAME = "App_short_name";
export const APP_LONG_NAME = "El nombre largo de la APP";


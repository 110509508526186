import ListAround from "@/view/config/around/list_around";

const routes = [
    {
        path: '/config_around',
        name: 'config_around',
        component: ListAround,
        props: {showMenu: true}
    },
];

export default routes;
<template>
    <div>
        <template v-if="haveData">
            <template v-if="getterProgramsAssociateCompany[itinerary] !== undefined">
                <GhTableCommon
                    :extratable="{
                        id: 'programs_associate_companies_' + itinerary
                    }"
                    :header="header"
                    :data="getterProgramsAssociateCompany[itinerary].data"
                >
                    <template v-slot:actions="{itemProp}">
                        <template v-if="getterProgramsAssociateCompany[itinerary].actions !== undefined">
                            <template v-if="getterProgramsAssociateCompany[itinerary].actions.associate">
                                <router-link :to="{name: 'assignation_base', params: {id: itemProp.id, itinerary: itinerary}}">
                                    <GhAction
                                        :dataction="{
                                            id: 'view_programs_associate_companies_' + itemProp.id,
                                            text: $t('associate'),
                                            icon: require('../../../../assets/gh_new_dessign/tick.svg')
                                        }"
                                    />
                                </router-link>
                            </template>
                        </template>
                    </template>
                </GhTableCommon>
            </template>
        </template>
        <template v-else-if="!haveData">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'programs_associate_companies',
        props: ['itinerary'],
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterProgramsAssociateCompany'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getProgramsAssociateCompany', {
                    itinerary: this.$props.itinerary
                }, {root: true});

                if(this.getterProgramsAssociateCompany[this.$props.itinerary] !== undefined && !this.getterProgramsAssociateCompany[this.$props.itinerary].actions.associate){
                    delete this.header[1];
                    this.header = this.header.filter(n => n);
                }

                this.haveData = true;
            }
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data: ''
    },
    mutations: {
        loadClubExperience(state, payload) {
            state.records_data = payload;
        },
        loadClubExperienceData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getClubExperience(state){
            await axios.get(
                API_URL + "club-experience"
            ).then(
                response => {
                    state.commit('loadClubExperience', response.data.data);
                }
            )
        },
        async getClubExperienceData(state, items){
            if(items.id === undefined){
                state.commit("loadClubExperienceData", '');
            }else {
                let requestLogin = API_URL + "club-experience/" + items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadClubExperienceData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setClubExperienceData(state, item){
            let requestLogin = API_URL + "club-experience/"+item.id;
            let formData = new FormData();

            formData.append('club_experience[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        router.push({name: 'configuration'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};

import ConfigFlow from "@/view/config/support/flow/template/flow.vue";

const routes = [
    {
        path: '/config/support/flow',
        name: 'flow',
        component: ConfigFlow,
        props: {id: null, showMenu: true,background: true, otherTitle: 'flow'},
        children: [
            {
                path: '/config/support/flow/:id',
                name: 'flow_id',
                component: ConfigFlow,
                props: {id: null, showMenu: true, background:true, otherTitle: 'flow'},
            },
            {
                path: '/config/support/flow/:id/:view/',
                name: 'flow_view',
                component: ConfigFlow,
                props: {id: null, showMenu: true, background:true, otherTitle: 'flow'},
            },
        ]
    }
];
export default routes;

<template>
    <div v-if="haveData">
        <GhTreeContentBlock
            style="margin-bottom: 15px; margin-left: 15px; margin-right: 15px;"
            :dataBlock="{
                id: 'enabled',
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
                extraTableClass: 'class-table-exercise'
            }"
            :data="BaseCreationExerciseTable.records_data_enabled.length === 0 ? [] : BaseCreationExerciseTable.records_data_enabled"
            :header="header"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true, type: 'enabled'}}"><a class="table-cell_link T13"> <b>{{itemProp[labelProp]}}</b> </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <GhAction
                    :dataction="{
                        id: 'edit_config_exercises',
                        text: $t('versioning'),
                        icon: require('../../../../assets/gh_new_dessign/return-blue.svg')
                    }"
                    @click="Global.deleteConfirm('delete_confirm','ExerciseVersion', itemProp.id,{'code': itemProp.code, 'statement': itemProp.statement, 'topic': itemProp.topic}, $t('versioning_exercise'), $t('versioning_delete_exercise'), 'delete')"
                />
                <GhAction
                    :dataction="{
                        id: 'delete_config_exercises',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg')
                    }"
                    @click="Global.deleteConfirm('delete_confirm','ExerciseToHistoric', itemProp.id,{'code': itemProp.code, 'statement': itemProp.statement, 'topic': itemProp.topic}, $t('delete_exercise'), $t('preparing_delete_exercise'), 'delete')"
                />
            </template>
        </GhTreeContentBlock>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTreeContentBlock from "@/components/GhTreeContentBlock.vue";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_enabled",
        components: {
            GhTreeContentBlock,
            GhAction
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 150px;'},
                    {text: 'statement', field: 'statement', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getExercisesEnabled', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
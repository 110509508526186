import ConfigActivityReason from "@/view/config/company/activity_sector/template/activity_sector.vue";

const routes = [
    {
        path: '/config/company/activity-sector',
        name: 'config_activity_sector',
        component: ConfigActivityReason,
        props: {id: null, showMenu: true, otherTitle: 'activity_sector'},
        children: [
            {
                path: '/config/company/activity-sector/:id',
                name: 'config_activity_sector_id',
                component: ConfigActivityReason,
                props: {id: null, showMenu: true, otherTitle: 'activity_sector'},
            },
            {
                path: '/config/company/activity-sector/:id/:view',
                name: 'config_activity_sector_id_view',
                component: ConfigActivityReason,
                props: {id: null, view: true, showMenu: true, otherTitle: 'activity_sector'},
            }
        ]
    }
];

export default routes;
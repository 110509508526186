<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'general'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigCompany.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>
<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';

    import {mapState} from "vuex";

    import list_social_reason from "@/view/config/company/social_reason/list_social_reason.vue";
    import list_activity_sector from "@/view/config/company/activity_sector/list_activity_sector.vue";
    import list_number_employees from "@/view/config/company/number_employees/list_number_employees.vue";
    import list_position_company from "@/view/config/company/position_company/list_position_company.vue";
    import list_country from "@/view/config/company/countries/list_countries.vue";

    export default {
        name: "list_general",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['ConfigCompany','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'social_reason', component: list_social_reason, result: 'social_reason', show: false , second_text: 'results'},
                    {id: 2, name: 'activity_sector', component: list_activity_sector, result: 'activity_sector', show: false , second_text: 'results'},
                    {id: 3, name: 'number_employees', component: list_number_employees, result: 'number_employees', show: false , second_text: 'results'},
                    {id: 4, name: 'position_company', component: list_position_company, result: 'position_company', show: false , second_text: 'results'},
                    {id: 5, name: 'country', component: list_country, result: 'country', show: false , second_text: 'results'}
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getConfigCompanyCounters', '', {root: true});
            }

            this.haveData = true;

            if(this.Login.tabs.length !== 0){
                this.Login.tabs[4]['sub-modules'][0]['sub-sections'].forEach((subroute,subkey) => {
                    if(this.data[subkey] !== undefined) {
                        this.data[subkey].show = subroute.have_permission;
                    }
                });
            }
        },
    }
</script>

import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import router from "@/routes/router";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        user_data: '',
    },
    mutations: {
        loadUserData(state, payload){
            state.user_data = payload;
        }
    },
    actions: {
        async getUser(state, items){
            if(items.id === undefined){
                state.commit("loadUserData", '');
            }else {
                // let requestLogin = API_URL + "users/"+items.id;
                let requestLogin = API_URL + "user/worker/"+items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                axios({
                    method: "GET",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadUserData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setUser(state, item){
            let requestLogin = item.id ? API_URL + "user/worker/"+item.id+"/update" : API_URL + "user/worker/add";
            let formData = new FormData();

            formData.append('user[id]', item.id);

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadUserData", response.data);
                    router.push({name: 'company'});
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};

<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'certificate',title: $t('certificate')}">
            <template v-slot:content>
                <form id="form_certificate" v-if="haveData">
                    <div style="display: flex">
                        <div class="gh-content-global" style="width: 170px">
                            <div style="width: 168px; height: 168px;">
                                <img :src="image" alt="" width="168" style="border-radius: 6px; max-height: 168px;">
                            </div>
                        </div>
                        <div class="gh-content-global" style="width: 85%">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'certificate_code',
                                            name: 'certificate[code]',
                                            style: 'width: 200px',
                                            value: certificate.code,
                                            type: 'text'
                                        }"
                                        ref="RefCode"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{certificate.code}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'certificate_name',
                                            name: 'certificate[name]',
                                            style: 'min-width: 200px; width: 20%',
                                            value: certificate.name,
                                            type: 'text'
                                        }"
                                        ref="RefName"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{certificate.name}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                        }"
                                        :datainput="{
                                            id: 'certificate_description',
                                            name: 'certificate[description]',
                                            value: certificate.description
                                        }"
                                        ref="RefDescription"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{certificate.description}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTreeSelectorBlock
                                        :dataBlock="{
                                            type: 'radio',
                                            id: 'label_topic',
                                            label: $t('topic'),
                                            title: $t('topic'),
                                            text: $t('select_topic') + ':',
                                            name: 'certificate[topic]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: true,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        }"
                                        :selected_input="ConfigCertificates.data && ConfigCertificates.data.topic ? ConfigCertificates.data.topic : []"
                                        :items="getFeeds.feed_topic"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('topic')}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 40%;">{{ConfigCertificates.data && ConfigCertificates.data.topic ? ConfigCertificates.data.topic[0].text : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" :style="!$root._route.params.view ? 'margin-top: -10px;' : ''">
                                <label class="gh_text_field-label T15_b" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('document') + ':'}}</label>
                                <div>
                                    <GhEasyUpload
                                        :style="$root._route.params.view ? 'margin-left: -10px;' : ''"
                                        :extrablock="{
                                            id: 'certificate',
                                            class: '',
                                            name_hidden: 'certificate[document]',
                                            id_hidden: 'certificate',
                                            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                        }"
                                        :datalabel="{
                                            id: 'label_import_block',
                                            style: 'margin-right: -15px'
                                        }"
                                        :datainput="{
                                            view: $root._route.params.view,
                                            accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .odt, .docx, .pdf, .png, .jpg, .jpeg',
                                            text: $t('attach'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            downloadHref: 'easyDownload',
                                            file_name: ConfigCertificates.data.document !== undefined ? ConfigCertificates.data.document.file_name : '',
                                            file_path: ConfigCertificates.data.document !== undefined ? ConfigCertificates.data.document.file_path : '',
                                            file_download: ConfigCertificates.data.document !== undefined ? ConfigCertificates.data.document.file_download : '',
                                            required: false
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitCertificate"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhButton from "fe-gh-button-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    import {mapState} from "vuex";

    export default {
        name: "certificate_template",
        components: {
            GhTreeSelectorBlock,
            GhEasyUpload,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhButton
        },
        computed: {
            ...mapState(['ConfigCertificates', 'UploadFiles', 'getFeeds'])
        },
        data(){
            return {
                haveData: false,
                haveImage: false,
                image: '',
                certificate: {
                    code: '',
                    name: '',
                    description: '',
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getCertificatesData', {id: this.$root._route.params.id}, {root: true});
                await this.$store.dispatch('getFeedTopic', '', {root: true});

                if (this.$root._route.params.id !== undefined) {
                    this.certificate.code = this.ConfigCertificates.data.code;
                    this.certificate.name = this.ConfigCertificates.data.name;
                    this.certificate.description = this.ConfigCertificates.data.description;
                }
                this.image = this.ConfigCertificates.data.document !== undefined && this.ConfigCertificates.data.document.image !== null && this.ConfigCertificates.data.document.image !== undefined ? this.ConfigCertificates.data.document.image : require('../../../../../assets/gh_new_dessign/no-image.svg');
                this.haveImage = this.ConfigCertificates.data.image !== null && this.ConfigCertificates.data.image !== undefined;

                this.haveData = true;
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.saveTemporalValues();
                this.image = data.image;
                this.haveImage = true;
            }
        },
        methods: {
            saveTemporalValues(){
                this.certificate.code = this.$refs['RefCode'].datainput.value;
                this.certificate.name = this.$refs['RefName'].datainput.value;
                this.certificate.description = this.$refs['RefDescription'].datainput.value;
            },
            async submitCertificate(){
                if(this.Global.checkRequired('form_certificate') === 'OK') {
                    const formData = new FormData(document.getElementById('form_certificate'));
                    this.$store.dispatch('setCertificateData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #certificate_image_easy_download_block{
        display: none !important;
    }

    #btn_download_certificate_attach_file{
        margin-left: 10px;
    }
</style>
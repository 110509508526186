var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.$root._route.params.windowed ? 'margin-top: -3px' : '')},[(_vm.$root._route.params.windowed)?_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$root._route.params.assigned ? _vm.$t('assignation') : _vm.$t('evaluator'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}):_vm._e(),_c('div',{staticClass:"content_page"},[(_vm.$root._route.name === 'assign_evaluator')?[_c('form',{attrs:{"id":"form_evaluator"}},[_c(_vm.listRequestCollaboration,{tag:"component",attrs:{"hideBtn":true}})],1)]:[_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: 'evaluator',
                    title: (!_vm.$root._route.params.view ? ((_vm.$root._route.params.id === undefined ? _vm.$t('add') : _vm.$t('modify')) + ' ' + _vm.$t('evaluator').toLowerCase()) : _vm.$t('evaluator'))
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('form',{attrs:{"id":"form_evaluator"}},[_c(_vm.evaluator,{tag:"component"})],1)]:(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])})],(_vm.$root._route.params.view && !_vm.$root._route.params.assigned)?_vm._l((_vm.getterEvaluatorEvaluationsSection),function(label,labelIndex){return _c('div',{key:'SECTION_EVALUATIONS' + labelIndex},[_c('GhDropSelector',{attrs:{"datablock":{
                        id: 'evaluations_' + label.id,
                        title: _vm.$t(label['name']),
                        second_title: '',
                        num_results: '',
                    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.sectionContent,{tag:"component",attrs:{"itinerary":label.id}})]},proxy:true}],null,true)})],1)}):_vm._e(),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px","padding-bottom":"20px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_finish',
                    text: _vm.$t('finish')
                }},on:{"click":_vm.submitEvaluator}}):_vm._e(),(_vm.$root._route.params.assigned)?[(!_vm.$root._route.params.practice || _vm.$root._route.params.practice === 'false' || _vm.$root._route.params.practice === false)?[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_assign_leader',
                            text: _vm.$t('assign_leader')
                        }},on:{"click":function($event){return _vm.submitAssign('leader')}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_assign_evaluator',
                        text: _vm.$t('assign_evaluator')
                    }},on:{"click":function($event){return _vm.submitAssign('evaluator')}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$root._route.params.windowed ? _vm.$t('close') : _vm.$t('back'),
                }},on:{"click":function($event){_vm.$root._route.params.windowed ? _vm.close() : _vm.router.go(-1)}}})],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div v-if="haveData">
        <GhNoDropSelector :datablock="{id: 'new_edit_profiles',title: $t('profiles')}">
            <template v-slot:content>
                <form id="profiles_perms">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'name',
                                        name: 'profile[name]',
                                        style: 'width: 40%',
                                        value: getterProfiles.name,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('name')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{getterProfiles && getterProfiles.name ? getterProfiles.name : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        class: 'T15_b',
                                        styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                                    }"
                                    :datainput="{
                                        id: 'description',
                                        name: 'profile[description]',
                                        required: false,
                                        value: getterProfiles.description,
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('description')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{getterProfiles && getterProfiles.description ? getterProfiles.description : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'label_groups',
                                        class: 'T15_b',
                                        subtitle: $t('select_group') + ':',
                                        text: $t('groups') + '*',
                                        label: $t('groups'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :datainput="{
                                        id: 'groups',
                                        name: 'profile[groups][]'
                                    }"
                                    :feed="getFeeds.feed_groups.feed"
                                    :selected_input="getterProfiles && getterProfiles.groups ? getterProfiles.groups : []"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('groups')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{getterProfiles && getterProfiles.groups ? Object.values(getterProfiles.groups).join() : ''}}</label>
                            </template>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div  class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="submitPermsProfiles"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="$router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhButton from "fe-gh-button-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import {mapGetters, mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";

    export default {
        name: 'view_profiles',
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterProfiles'])
        },
        components: {
            GhDataSelector,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhButton
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getGroups', '', {root: true});
                await this.$store.dispatch('getPermsProfiles', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods:{
            async submitPermsProfiles(){
                if(this.Global.checkRequired('profiles_perms') === 'OK') {
                    const formData = new FormData(document.getElementById('profiles_perms'));
                    await this.$store.dispatch('setPermsProfile', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #label_groups_selector_label{
        width: 150px;
        text-align: end;
    }
</style>
import UserFileList from "@/view/user_file/list_user_file";

const routes = [
    {
        path: '/user_file',
        name: 'user_file',
        component: UserFileList,
        props: {showMenu: true},
    }
];

export default routes;
import Group from "@/view/perms/groups/groups/template/groups.vue";

const route = [
    {
        path: '/perms/group',
        name: 'permission_groups',
        component: Group,
        props: {id: null, showMenu: true, otherTitle: 'group'},
        children: [
            {
                path: '/perms/group/:id',
                name: 'permission_groups_id',
                component: Group,
                props: {id: null, showMenu: true, otherTitle: 'group'},
            },
            {
                path: '/perms/group/:id/:view',
                name: 'permission_groups_id_view',
                component: Group,
                props: {id: null, view: true, showMenu: true, otherTitle: 'group'},
            },
        ]
    },
];

const routes = [
    ...route,
]

export default routes;
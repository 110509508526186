<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: 'annexes',
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="gh-content-global-questionnaire">
            <div class="question-body">
                <template v-if="getterAnnexes.have_files">
                    <div style="float: right; margin: 6px 0px 5px;">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_generate_zip',
                                text: zipGenerate ? $t('download_zip') : $t('generating'),
                                icon: zipGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            }"
                            :disabled="!zipGenerate"
                            @click="generateZIP()"
                        />
                    </div>
                </template>
                <div class="gh-statement-content T15">
                    <div class="T-subtitle" style="margin-left: 10px; margin-top: 2px;">{{$t('annexes') + ' ' + $t('of') + ' ' + getterAnnexes.name}}</div>
                </div>
                <div class="separator-line" style="margin-top: unset;"></div>
                <div class="overflow-index">
                    <div class="gh-content-global">
                        <div v-for="(itemIndex, keyIndex) in index" :key="'INDEX' + keyIndex + refreshIndex" v-show="itemIndex.show || itemIndex.level === 0">
                            <div :style="itemIndex.level !== 0 ? 'display: flow; padding-left: ' + (itemIndex.level * 25) + 'px;' : 'display: flex; padding-left: 5px;'">
                                <div style="display: flex; cursor: pointer; width: 100%;">
                                    <template v-if="itemIndex.parent_id === null && itemIndex.have_childrens">
                                        <div class="gh_margin-arrow" @click="showOrHideIndex(itemIndex, keyIndex)">
                                            <img height="18" width="18" style="margin-top: 5px;" :src="itemIndex.active ? require('@/assets/questionnaire/arrow-up.svg') : require('@/assets/questionnaire/arrow-down.svg')" alt="">
                                        </div>
                                    </template>
                                    <div style="display: flex; width: 100%;">
                                        <template v-if="itemIndex.parent_id !== null">
                                            <div class="gh-pointer" :style="'color:' + colors[itemIndex.state]" :title="$t(title[itemIndex.state])">
                                                ⬤
                                            </div>
                                        </template>
                                        <span :class="itemIndex.parent_id !== null ? itemIndex.active ? 'T-subtitle-second' : 'T-text' : 'T-subtitle'" class="ellipsis" style="display: table-cell; margin-top: 2px; white-space: normal; padding-bottom: 8px;" :title="itemIndex.name.replace(/<[^>]*>/g, '')" v-html="itemIndex.name" :style="itemIndex.parent_id !== null ? itemIndex.current ? '' : '' : 'color: #1B82C5; width: 100% !important;'"/>
                                        <template v-if="itemIndex.parent_id !== null && itemIndex.have_childrens || itemIndex.files !== null && itemIndex.files !== undefined && itemIndex.files.length !== 0">
                                            <div class="gh_margin-arrow" @click="showOrHideIndex(itemIndex, keyIndex)">
                                                <img height="18" width="18" style="margin-top: 3px;" :src="itemIndex.active ? require('@/assets/questionnaire/arrow-up.svg') : require('@/assets/questionnaire/arrow-down.svg')" alt="">
                                            </div>
                                        </template>
                                        <template v-if="itemIndex.files !== null && itemIndex.files !== undefined && itemIndex.files.length !== 0">
                                            <span class="T-small-text gh_margin-spaces" style="display: flex; white-space: nowrap; margin-top: 3px; margin-left: 10px;">{{itemIndex.files.length + ' ' + $t('files').toLowerCase()}}</span>
                                        </template>
                                    </div>
                                </div>
                                <template v-if="itemIndex.files !== null && itemIndex.files !== undefined && itemIndex.files.length !== 0 && itemIndex.active">
                                    <div style="display: flex; padding-bottom: 10px; padding-left: 27px; padding-right: 25px">
                                        <div class="file-container">
                                            <div v-for="(file, keyFile) in itemIndex.files" :key="'FILES_' + keyFile">
                                                <a style="display: inline-flex; cursor: pointer" :href="file.file_download">
                                                    <img width="17" :style="images.some(v => file.file_name.split('.')[file.file_name.split('.').length - 1].includes(v)) || folder.some(v => file.file_name.split('.')[file.file_name.split('.').length - 1].includes(v)) ? 'padding-top: 2px;' : 'padding-top: 3px;'" :src="images.some(v => file.file_name.split('.')[file.file_name.split('.').length - 1].includes(v)) ? require('@/assets/gh_new_dessign/image.svg') : folder.some(v => file.file_name.split('.')[file.file_name.split('.').length - 1].includes(v)) ? require('@/assets/gh_new_dessign/folder.svg') : require('@/assets/gh_new_dessign/doc.svg')" alt="">
                                                    <GhAction
                                                        style="margin-left: 8px;"
                                                        class="color-light-blue T-subtitle-second underline-light-blue"
                                                        :dataction="{
                                                            id: 'download_file_' + keyIndex + keyFile,
                                                            text: file.file_name,
                                                        }"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhButton from 'fe-gh-button-lib';
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'view_annexes',
        components: {
            GhLocation,
            GhButton,
            GhAction
        },
        computed: {
            ...mapGetters(['getterAnnexes'])
        },
        data(){
            return {
                images: ['png', 'jpeg', 'jpg', 'bmp', 'gif', 'tiff', 'heif', 'raw', 'psd', 'svg', 'eps', 'indd', 'ai'],
                folder: ['zip', 'rar'],
                colors: [
                    '#C7C7C7',
                    '#F58449',
                    '#005392',
                    '#3DA106'
                ],
                title: [
                    'for_start',
                    'in_progress',
                    'finish'
                ],
                index: [],
                zipGenerate: true,
                refreshIndex: 0,
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getAnnexes', {
                agrupator_id: this.$root._route.params.id,
                company_id: this.$root._route.params.extra_id
            }, {root: true});

            this.index = this.getterAnnexes.index;

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods: {
            showOrHideIndex(currentItem, currentIndex) {
                const currentLevel = currentItem.level;
                const isActive = !this.index[currentIndex].active;

                this.$set(this.index[currentIndex], 'active', isActive);

                if (currentLevel === 0) {
                    this.index.forEach((item, index) => {
                        if (item.level === 1 && item.parent_id === currentItem.id) {
                            this.$set(this.index[index], 'show', isActive);
                        }
                    });

                    if (!isActive) {
                        this.hideAllDescendants(currentItem.id);
                    }
                } else if (currentLevel !== 0) {
                    this.index.forEach((item, index) => {
                        if (item.parent_id === currentItem.id) {
                            this.$set(this.index[index], 'show', isActive);
                            if (!isActive) {
                                this.hideAllDescendants(item.id);
                            }
                        }
                    });
                }
            },
            hideAllDescendants(parentId) {
                const stack = [parentId];
                while (stack.length > 0) {
                    const currentId = stack.pop();
                    this.index.forEach((item, index) => {
                        if (item.parent_id === currentId) {
                            this.$set(this.index[index], 'show', false);
                            this.$set(this.index[index], 'active', false);
                            stack.push(item.id);
                        }
                    });
                }
            },
            async generateZIP(){
                this.zipGenerate = false;

                await this.$store.dispatch('getAnnexesZIP', {
                    agrupator_id: this.$root._route.params.id,
                    company_id: this.$root._route.params.extra_id
                }, {root: true}).then((response) => {
                    if(response.status === 200){
                        if(response.data !== undefined && response.data !== null && response.data !== '') {
                            const link = document.createElement('a');
                            link.id = 'zip_annexes';

                            const blob = new Blob([response.data], {type: 'application/zip'});
                            link.href = URL.createObjectURL(blob);
                            link.download = 'annexes.zip'

                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            URL.revokeObjectURL(link.href);
                        }

                        this.zipGenerate = true;
                    }
                });
            },
        }
    }
</script>

<style>
    .file-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);;
        gap: 10px;
        width: 100% !important;
    }
</style>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: [],
        data: [],
        refreshBilling: 0
    },
    mutations: {
        loadBilling(state, payload) {
            state.records_data = payload;
        },
        loadBillingData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getBilling(state){
            await axios.get(
                API_URL + "list_billing"
            ).then(
                response => {
                    state.commit('loadBilling', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getBillingData(state, item){
            let requestLogin = API_URL + "billing/" + item.id;
            
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadBillingData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setMadePayment(state, item){
            let requestLogin = item.id ? API_URL + "billing/"+item.id : API_URL + "billing";
            let formData = new FormData();

            formData.append('billing[id]', item.id);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200){
                        state.state.records_data.forEach((i, k) => {
                            if(i.id === item.id){
                                state.state.records_data[k].payed = true;
                            }
                        });

                        state.state.refreshBilling++;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeBilling(state, item){
            let requestLogin = API_URL + "billing/"+item.id+"/billing";
            let formData = new FormData();

            formData.append('billing[id]', item.id);
            formData.append('billing[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getBilling');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
import PlatformPay from "@/view/shared/platform_pay/platform_pay.vue";

const routes = [
    {
        path: '/pay/:type',
        name: 'platform_pay',
        component: PlatformPay,
        props: {type: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'exercise'},
    }
];

export default routes;
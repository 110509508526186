<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'groups',
            }"
            :header="header"
            :data="getterListGroups.length === 0 ? [] : getterListGroups"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'permission_groups_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="itemProp.show">
                    <router-link :to="{name: 'permission_groups_id', params: {id: itemProp['id']}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_group_' + itemProp['id'],
                                text: $t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }"
                        />
                    </router-link>
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'permission_groups'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_group',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapGetters} from "vuex";



    export default {
        name: 'list_groups',
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterListGroups'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'name',urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 20%;'},
                    {text: 'users', field: 'users', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 60%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                haveData: false,
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getListGroups', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        groups: [],
        data: [],
        refreshGroups: false,
        refreshData: false
    },
    mutations: {
        loadListGroups(state, payload){
            state.refreshGroups = !state.refreshGroups;
            state.groups = payload;
        },
        loadPermsGroup(state, payload){
            state.refreshData = !state.refreshData;
            state.data = payload;
        }
    },
    getters: {
        getterListGroups(state) {
            const dummy = state.refreshGroups; // eslint-disable-line no-unused-vars
            return state.groups;
        },
        getterGroups(state) {
            const dummy = state.refreshData; // eslint-disable-line no-unused-vars
            return state.data;
        }
    },
    actions: {
        async getListGroups(state){
            let  requestLogin = API_URL + "groups";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadListGroups", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPermsGroup(state,item){
            if(item.id === undefined){
                state.commit("loadPermsGroup", []);
            }else{
                let  requestLogin = API_URL + "groups/" + item.id;
                await axios({
                    url: requestLogin,
                }).then(
                    response => {
                        if(response.status === 200) {
                            state.commit("loadPermsGroup", response.data.data);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setPermsGroup(state,item){
            let requestCompaniesGroup =  item.id ? API_URL + "groups/" + item.id : API_URL + "groups";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadPermsGroup", response.data.data);
                        router.push({name: 'perms'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?[(_vm.getterPermissionEvaluator)?[_c('router-link',{attrs:{"to":{name: 'assign_evaluator'}}},[_c('GhButton',{staticStyle:{"margin-top":"15px"},attrs:{"datainput":{
                        id: 'btn_add_new_evaluator',
                        class: 'T19 container-md_button_content',
                        text: _vm.$t('add_new_evaluator')
                    }}})],1)]:_vm._e(),_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:'SECTION_EVALUATORS' + labelIndex},[_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: 'evaluators_' + label.id,
                    title: _vm.$t(label['name']),
                    second_title: ''
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)})],1)}),(_vm.showSectionResults)?[_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: 'result_evaluators',
                    title: _vm.loaderResults ? _vm.$t('searching') + '...' : _vm.getterEvaluatorsResults.length === 0 ? _vm.$t('no_results_found') : _vm.getterEvaluatorsResults.length + ' ' + _vm.$t('results'),
                    second_title: ''
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.loaderResults)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:[_c(_vm.results,{tag:"component"})]]},proxy:true}],null,false,3460439114)})]:_vm._e()]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
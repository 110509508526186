import Profiles from "@/view/perms/groups/profiles/template/profiles.vue";

const route = [
    {
        path: '/perms/profile',
        name: 'permission_profiles',
        component: Profiles,
        props: {id: null, showMenu: true, otherTitle: 'profiles'},
        children: [
            {
                path: '/perms/profile/:id',
                name: 'permission_profiles_id',
                component: Profiles,
                props: {id: null, showMenu: true, otherTitle: 'profiles'},
            },
            {
                path: '/perms/profile/:id/:view',
                name: 'permission_profiles_id_view',
                component: Profiles,
                props: {id: null, view: true, showMenu: true, otherTitle: 'profiles'},
            },
        ]
    }
];

const routes = [
    ...route,
]

export default routes;
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data: '',
        possible_answers: [],
        possible_questions: [],
        answers: [],
        actual_possible_answer: '',
        actual_possible_questions: '',
        n_answers: '',
        interval: '',
        range: {
            min: '',
            max: '',
        },
        flagForGetter: false,
    },
    mutations: {
        loadTypesExercises(state, payload) {
            state.records_data = payload;
        },
        loadTypeExerciseData(state, payload) {
            state.data = payload;
        },
        loadActualPossibleAnswer(state, payload) {
            state.actual_possible_answer = payload === 0 ? '' : payload;
        },
        loadActualPossibleQuestion(state, payload) {
            state.actual_possible_questions = payload === 0 ? '' : payload;
        },
        loadActualPossibleNumericQuestions(state, payload) {
            state.n_answers = payload === 0 ? '' : payload;
        },
        loadInterval(state, payload) {
            state.interval = payload === 0 ? '' : payload;
        },
        loadRange(state, payload) {
            state.range.min = payload.min;
            state.range.max = payload.max;

            state.answers.forEach((i, k) => {
                if(k === 0) {
                    state.answers[k].range.min = payload.min;
                }
                if(k === state.answers.length - 1) {
                    state.answers[k].range.max = payload.max;
                }
            })
        },
        loadPossibleAnswers(state, payload){
            state.flagForGetter = !state.flagForGetter;
            state.possible_answers = payload;
        },
        loadPossibleQuestions(state, payload){
            state.flagForGetter = !state.flagForGetter;
            state.possible_questions = payload;
        },
        loadPossibleNumericQuestions(state, payload){
            state.flagForGetter = !state.flagForGetter;
            state.answers = payload;
        }
    },
    getters: {
        getterPossibleAnswers(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return {
                'possible_answer': state.possible_answers,
                'actual_possible_answer': state.actual_possible_answer
            };
        },
        getterPossibleQuestions(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return {
                'possible_questions': state.possible_questions,
                'actual_possible_questions': state.actual_possible_questions
            };
        },
        getterPossibleNumericQuestions(state){
            const dummy = state.flagForGetter; // eslint-disable-line no-unused-vars
            return {
                'answers': state.answers,
                'n_answers': state.n_answers,
                'interval': state.interval,
                'range': state.range
            };
        }
    },
    actions: {
        async cleanTablePossibleAnswers(state){
            state.state.actual_possible_answer = '';
            state.commit('loadPossibleAnswers', []);
        },
        async cleanTablePossibleQuestions(state){
            state.state.actual_possible_questions = '';
            state.commit('loadPossibleQuestions', []);
        },
        async cleanTablePossibleNumericQuestions(state){
            state.state.n_answers = '';
            state.state.interval = '';
            state.state.range.min = '';
            state.state.range.max = '';
            state.commit('loadPossibleNumericQuestions', []);
        },
        async createTablePossibleAnswers(state, item){
            var params = [];

            for(var i = 0; i < item.count; i++){
                params.push({
                    'id': i,
                    'answer': state.state.possible_answers[i] !== undefined ? state.state.possible_answers[i].answer : ''
                });
            }

            state.commit('loadActualPossibleAnswer', item.count);
            state.commit('loadPossibleAnswers', params);
        },
        async createTablePossibleQuestions(state, item){
            var params = [];

            for(var i = 0; i < item.count; i++){
                params.push({
                    'id': i,
                    'question': state.state.possible_questions[i] !== undefined ? state.state.possible_questions[i].question : ''
                });
            }

            state.commit('loadActualPossibleQuestion', item.count);
            state.commit('loadPossibleQuestions', params);
        },
        async createTablePossibleNumericQuestions(state, item){
            var params = [];

            for(var i = 0; i < item.count; i++){
                params.push({
                    'id': i,
                    'answers': state.state.answers[i] !== undefined && state.state.answers[i].answer !== undefined ? state.state.answers[i].answer : '',
                    'range': state.state.answers[i] !== undefined && state.state.answers[i].range !== undefined ? state.state.answers[i].range : {
                        min: i === 0 ? state.state.range.min : '',
                        max: i === parseInt(item.count) - 1 ? state.state.range.max : ''
                    }
                });
            }

            state.commit('loadActualPossibleNumericQuestions', item.count);
            state.commit('loadPossibleNumericQuestions', params);
        },
        async setIntervalPossibleNumericQuestions(state, item){
            state.commit('loadInterval', item.interval);
        },
        async setRangePossibleNumericQuestions(state, item){
            state.commit('loadRange', {min: item.min, max: item.max});
        },
        async deleteTablePossibleAnswers(state, item){
            var params = [];
            var count = 0;

            for(var i = 0; i < state.state.possible_answers.length; i++) {
                if (item.position !== i) {
                    params.push({
                        'id':  count++,
                        'answer': state.state.possible_answers[i].answer
                    });
                }
            }

            state.commit('loadActualPossibleAnswer', state.state.actual_possible_answer - 1);
            state.commit('loadPossibleAnswers', params);
        },
        async deleteTablePossibleQuestions(state, item){
            var params = [];
            var count = 0;

            for(var i = 0; i < state.state.possible_questions.length; i++) {
                if (item.position !== i) {
                    params.push({
                        'id':  count++,
                        'question': state.state.possible_questions[i].question
                    });
                }
            }

            state.commit('loadActualPossibleQuestion', state.state.actual_possible_questions - 1);
            state.commit('loadPossibleQuestions', params);
        },
        async deleteTablePossibleNumericQuestions(state, item){
            var params = [];
            var count = 0;

            for(var i = 0; i < state.state.answers.length; i++) {
                if (item.position !== i) {
                    params.push({
                        'id':  count++,
                        'answers': state.state.answers[i].answers,
                        'range': state.state.answers[i].range
                    });
                }
            }

            state.commit('loadActualPossibleNumericQuestions', state.state.n_answers - 1);
            state.commit('loadPossibleNumericQuestions', params);
        },
        async setTablePossibleAnswers(state, item){
            state.state.possible_answers[item.id].answer = item.answer;
            state.state.flagForGetter = !state.state.flagForGetter;
        },
        async setTablePossibleQuestions(state, item){
            state.state.possible_questions[item.id].question = item.question;
            state.state.flagForGetter = !state.state.flagForGetter;
        },
        async setTablePossibleNumericQuestions(state, item){
            state.state.answers[item.id].answers = item.answer;
            state.state.answers[item.id].range = item.range;

            state.state.flagForGetter = !state.state.flagForGetter;
        },
        async getTypesExercises(state){
            await axios.get(
                API_URL + "type-exercises"
            ).then(
                response => {
                    state.commit('loadTypesExercises', response.data.data);
                }
            )
        },
        async getTypeExerciseData(state, items){
            if(items.id === undefined){
                state.commit("loadTypeExerciseData", '');
            }else {
                let requestLogin = API_URL + "type-exercise/"+items.id;

                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        if(response.data.data.config_type !== undefined && response.data.data.config_type.possible_answers !== undefined) {
                            state.commit('loadActualPossibleAnswer', response.data.data.config_type.possible_answers);

                            if(response.data.data.config_type.answers !== undefined){
                                state.commit('loadPossibleAnswers', response.data.data.config_type.answers);
                            }
                        }

                        if(response.data.data.config_type !== undefined && response.data.data.config_type.possible_questions !== undefined) {
                            state.commit('loadActualPossibleQuestion', response.data.data.config_type.possible_questions);

                            if(response.data.data.config_type.questions !== undefined){
                                state.commit('loadPossibleQuestions', response.data.data.config_type.questions);
                            }
                        }

                        if(response.data.data.type !== undefined && response.data.data.type[0] !== undefined  && response.data.data.type[0].value === 8){
                            state.commit('loadActualPossibleNumericQuestions', response.data.data.config_type.n_answers);
                            state.commit('loadPossibleNumericQuestions', response.data.data.config_type.answers);
                            state.commit('loadInterval', response.data.data.config_type.interval);
                            state.commit('loadRange', {min: response.data.data.config_type.range.min, max: response.data.data.config_type.range.max});
                        }

                        state.commit("loadTypeExerciseData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setTypeExerciseData(state, item){
            let requestLogin = item.id ? API_URL + "type-exercise/"+item.id : API_URL + "type-exercise";
            let formData = new FormData();

            formData.append('type_exercise[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadTypeExerciseData", response.data);
                    router.push({name: 'configuration'});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeTypeExercise(state, item){
            let requestLogin = API_URL + "delete-type-exercise/"+item.id;
            let formData = new FormData();

            formData.append('type_exercise[id]', item.id);
            formData.append('type_exercise[type]', item.type);

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getTypesExercises');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};

import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        records_data: '',
        defaultSelecteds: []
    },
    mutations: {
        loadCountries(state, payload) {
            state.defaultSelecteds = [];
            state.records_data = payload;
            payload.forEach((i) => {
                if(i.selected){
                    state.defaultSelecteds.push(i.id);
                }
            });

        }
    },
    actions: {
        async getCountriesList(state){
            await axios.get(
                API_URL + "countries"
            ).then(
                response => {
                    state.commit('loadCountries', response.data.data);
                }
            )
        },
        async setCountriesData(state, item){
            let requestLogin = API_URL + "countries";
            let formData = new FormData();

            item.ids.forEach((i) => {
                formData.append('country[]', i);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        await this.dispatch('getCountriesList');
                        await this.dispatch('getConfigCompanyCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};

<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_401',
            title: $t('session_timeout') + '!',
            type: 'info',
            style: 'width: 400px; min-width: 400px; max-width: 400px;'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <div>
                        <div class="T15_b" style="margin-left: 35px;">
                            {{$t('you_have_been_logged_out_due_to_inactivity')}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_session_timeout',
                    text: $t('login'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="acceptLogin()"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "popup_401",
        components: {
            GhButton,
            GhPopUp
        },
        methods: {
            async acceptLogin(){
                this.Global.closePopUp('popup_401');
                await this.$store.dispatch('acceptSet401', '', {root: true});
            }
        }
    }
</script>
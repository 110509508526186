import AssignedUsers from "@/view/itinerary/client/assigned_users/list_assigned_users.vue";

const route = [
    {
        path: '/programs/assigned-users',
        name: 'assigned_users',
        component: AssignedUsers,
        props: {id: null, showMenu: true, otherTitle: 'assigned_users'},
    },
];

const routes = [
    ...route,
]

export default routes;
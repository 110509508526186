import router from "../../routes/router";
import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        subTabActive: '',
        nameSubTabActive: '',
        idSubTabActive: '',
        reload: 0,
        searchData: '',
        resultTitle: 'no_results_found'
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
        loadNameSubTabIdActive(state,payload){
            state.idSubTabActive = payload
        },
        loadSearchData(state,payload){
            state.searchData = payload
        },
        loadTitleResult(state, payload){
            state.resultTitle = payload
        },
        loadReloadTab(state){
            state.reload++;
        }
    },
    actions:{
        destroyedResultData(state){
            state.commit("loadSearchData", '');
            state.commit('loadTitleResult', 'no_results_found');
        },
        exportTotal(state,items){
            let requestLogin = API_URL + 'exports/excel';
            let params = {};

            params['export[type]'] = items.data;
            params['export[type_export]'] = 'all';

            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        exportResult(state,items){
            let requestLogin =API_URL +'search/' + items.data;
            let params = {};

            // formData.append('export[ho]', items.data);
            params['export[type_export]'] = 'results';
            params['excel'] = 'true';

            items.formData.forEach((item) => {
                params[item[0].replace('search', 'export')] = item[1];
            });

            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    let filename = 'downloaded-file.xlsx'; // Default filename

                    const contentDisposition = response.headers['content-disposition'];
                    console.log(response.headers);
                    console.log(contentDisposition);
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        async searchData(state,items){
            let requestLogin = API_URL +'search/' + items.type;

            let params = {};
            var countGroupers = 0;
            var countExercises = 0;
            var countTopics = 0;
            var countThesauros = 0;
            var countImpartation = 0;
            items.formData.forEach((item) => {
                let _key = item[0];
                if(_key === 'search[groupers][]'){
                    params['search[groupers][' + countGroupers + ']'] = item[1];
                    countGroupers++;
                }else if(_key === 'search[exercises][]'){
                    params['search[exercises][' + countExercises + ']'] = item[1];
                    countExercises++;
                }else if(_key === 'search[topics][]'){
                    params['search[topics][' + countTopics + ']'] = item[1];
                    countTopics++;
                }else if(_key === 'search[thesauros][]'){
                    params['search[thesauros][' + countThesauros + ']'] = item[1];
                    countThesauros++;
                }else if(_key === 'search[impartation][]'){
                    params['search[impartation][' + countImpartation + ']'] = item[1];
                    countImpartation++;
                }else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    state.commit("loadSearchData", response.data.data);
                    state.commit("loadTitleResult", response.data.data.length);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async GhSubTabItem(state, item) {
            var valRouter = await router.options.routes.find((i) => (item.call == i.name && item.call)  || i.name == item.value);
            state.commit("loadSubActiveItem", valRouter.component != '' ? valRouter.component : '');
            state.commit("loadNameSubActiveItem", valRouter.name != '' ? valRouter.name : '');

            state.rootState.TabCreation.actual_grouper = item.value;
            await state.commit("loadNameSubTabIdActive", item.id);

            state.commit("loadReloadTab");
        },
    }
}

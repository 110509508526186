<template>
    <div class="gh-content-global">
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('company_name') + '*',
                        style:'min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_professional_relationship_company_name',
                        name: 'request_collaboration[professional_relationship][company_name]',
                        style: 'width:60%',
                        value: persistance.company_name,
                        type: 'text'
                    }"
                    ref="refCompanyName"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_professional_relationship_social_reason',
                        class: 'T15_b',
                        subtitle: $t('select_social_reason') + ':',
                        text: $t('social_reason') + '*',
                        label: $t('social_reason'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_professional_relationship_social_reason',
                        name: 'request_collaboration[professional_relationship][social_reason]'
                    }"
                    :feed="getFeeds.feed_social_reason"
                    :selected_input="[]"
                    ref="refSocialReason"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('cif') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_professional_relationship_cif',
                        name: 'request_collaboration[professional_relationship][cif]',
                        style: 'width:20%; min-width: 100px;',
                        value: persistance.cif,
                        type: 'text'
                    }"
                    ref="refCif"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('full_address') + '*',
                        style:'min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_professional_relationship_full_address',
                        name: 'request_collaboration[professional_relationship][full_address]',
                        style: 'width:60%',
                        value: persistance.full_address,
                        type: 'text'
                    }"
                    ref="refFullAdress"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('locality') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_professional_relationship_locality',
                        name: 'request_collaboration[professional_relationship][locality]',
                        style: 'width:60%',
                        value: persistance.locality,
                        type: 'text'
                    }"
                    ref="refLocality"
                />
            </div>
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('cp') + '*',
                        style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_professional_relationship_cp',
                        name: 'request_collaboration[professional_relationship][cp]',
                        style: 'width:20%; min-width: 100px;',
                        value: persistance.cp,
                        type: 'text'
                    }"
                    ref="refCp"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_professional_relationship_country',
                        class: 'T15_b',
                        subtitle: $t('select_country') + ':',
                        text: $t('country'),
                        label: $t('country'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_professional_relationship_country',
                        name: 'request_collaboration[professional_relationship][country]'
                    }"
                    :feed="getFeeds.feed_country"
                    :selected_input="getFeeds.feed_ho_is_spain"
                    @accept="searchCommunity"
                    ref="refCountry"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_professional_relationship_community',
                        class: 'T15_b',
                        subtitle: $t('select_community') + ':',
                        text: $t('community'),
                        label: $t('community'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_professional_relationship_community',
                        name: 'request_collaboration[professional_relationship][community]'
                    }"
                    :feed="getterCommunityByCountryProfessional !== undefined ? getterCommunityByCountryProfessional : []"
                    :selected_input="[]"
                    @accept="searchProvince"
                    ref="refCommunity"
                    :key="'refresh_community_professional_' + refreshCommunity"
                />
            </div>
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_professional_relationship_province',
                        class: 'T15_b',
                        subtitle: $t('select_province') + ':',
                        text: $t('province'),
                        label: $t('province'),
                        type: 'radio',
                        columns: 3,
                        required: false,
                        style: 'margin-left:20px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_professional_relationship_province',
                        name: 'request_collaboration[professional_relationship][province]'
                    }"
                    :feed="getterProvinceByCommunityProfessional !== undefined ? getterProvinceByCommunityProfessional : []"
                    :selected_input="[]"
                    ref="refProvince"
                    :key="'refresh_province_professional_' + refreshProvince"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column">
                <GhInputTextField
                    :datalabel="{
                        text: $t('position') + '*',
                        style:'min-width: 150px !important; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'request_collaboration_professional_relationship_position',
                        name: 'request_collaboration[professional_relationship][position]',
                        style: 'width:60%',
                        value: persistance.position,
                        type: 'text'
                    }"
                    ref="refPosition"
                />
            </div>
            <div class="gh-row-column"></div>
            <div class="gh-row-column"></div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_professional_relationship_professional_activity_sector',
                        class: 'T15_b',
                        subtitle: $t('select_activity_sector') + ':',
                        text: $t('activity_sector') + '*',
                        label: $t('activity_sector'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_professional_relationship_professional_activity_sector',
                        name: 'request_collaboration[professional_relationship][professional_activity_sector]'
                    }"
                    :feed="getFeeds.feed_professional_activity_sector"
                    :selected_input="[]"
                    ref="refActivitySector"
                />
            </div>
        </div>
        <div class="gh-row-line">
            <div class="gh-row-column no-min-width">
                <GhDataSelector
                    :datablock="{
                        id: 'request_collaboration_professional_relationship_number_employees',
                        class: 'T15_b',
                        subtitle: $t('select_number_employees') + ':',
                        text: $t('number_employees') + '*',
                        label: $t('number_employees'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: '',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :datainput="{
                        id: 'value_request_collaboration_professional_relationship_number_employees',
                        name: 'request_collaboration[professional_relationship][number_employees]'
                    }"
                    :feed="getFeeds.feed_num_employers"
                    :selected_input="[]"
                    ref="refNumberEmployees"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'professional_relationship',
        components: {
            GhInputTextField,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterCommunityByCountryProfessional', 'getterProvinceByCommunityProfessional']),
        },
        data(){
            return {
                refreshProvince: 0,
                refreshCommunity: 0,
                persistance: {
                    company_name: '',
                    cif: '',
                    full_address: '',
                    locality: '',
                    cp: '',
                    position: '',
                }
            }
        },
        async beforeMount(){
            this.$store.dispatch('getCommunityByCountry', {
                country: Object.keys(this.getFeeds.feed_ho_is_spain)[0],
                from: 'professional'
            }, {root: true});
        },
        watch: {
            'getterProvinceByCommunityProfessional'(){
                this.refreshProvince++;
            },
            'getterCommunityByCountryProfessional'(){
                this.refreshCommunity++;
            },
        },
        methods: {
            searchProvince(){
                this.persist();
                var community = this.$refs.refCommunity.checkdata;
                community = Object.keys(community);

                if(community.length !== 0){
                    community = community[0];
                    this.$store.dispatch('getProvinceByCommunity', {
                        community: community,
                        from: 'professional'
                    }, {root: true});
                }
            },
            searchCommunity(){
                this.persist();
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    this.$store.dispatch('getCommunityByCountry', {
                        country: country,
                        from: 'professional'
                    }, {root: true});
                }
            },
            persist(){
                this.persistance.company_name = this.$refs['refCompanyName'].datainput.value;
                this.persistance.cif = this.$refs['refCif'].datainput.value;
                this.persistance.full_address = this.$refs['refFullAdress'].datainput.value;
                this.persistance.locality = this.$refs['refLocality'].datainput.value;
                this.persistance.cp = this.$refs['refCp'].datainput.value;
                this.persistance.position = this.$refs['refPosition'].datainput.value;
            }
        }
    }
</script>

<style>
    .gh-row-line {
        display:flex;
        margin-bottom: 10px;
    }
    .gh-row-column {
        flex: 1; /* Cada columna ocupa un tercio del contenedor */
        display: block;
        justify-content: left;
    }
    .no-min-width > div {
        min-width: unset !important;
    }
    .no-min-width > div > div {
        margin-bottom: unset !important;
    }
    #request_collaboration_professional_relationship_social_reason_selector_label, #request_collaboration_professional_relationship_country_selector_label, #request_collaboration_professional_relationship_province_selector_label, #request_collaboration_professional_relationship_community_selector_label, #request_collaboration_professional_relationship_professional_activity_sector_selector_label, #request_collaboration_professional_relationship_number_employees_selector_label{
        width: 150px !important;
        text-align: right;
    }
</style>
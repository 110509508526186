import ConfigEvaluatorModality from "@/view/config/evaluators/evaluator_modality/template/evaluator_modality.vue";

const routes = [
    {
        path: '/config/evaluator/evaluator-modality',
        name: 'config_evaluator_modality',
        component: ConfigEvaluatorModality,
        props: {id: null, showMenu: true, otherTitle: 'evaluator_modality'},
        children: [
            {
                path: '/config/evaluator/evaluator-modality/:id',
                name: 'config_evaluator_modality_id',
                component: ConfigEvaluatorModality,
                props: {id: null, showMenu: true, otherTitle: 'evaluator_modality'},
            },
            {
                path: '/config/evaluator/evaluator-modality/:id/:view',
                name: 'config_evaluator_modality_id_view',
                component: ConfigEvaluatorModality,
                props: {id: null, view: true, showMenu: true, otherTitle: 'evaluator_modality'},
            }
        ]
    }
];

export default routes;
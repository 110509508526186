<template>
    <div :style="hideBtn ? '' : 'padding-left: 12px; padding-right: 12px;'" v-if="haveData">
        <template v-if="hideBtn">
            <div v-for="(label, labelIndex) in data" :key="labelIndex">
                <GhNoDropSelector v-if="label.show"
                    :datablock="{
                        id: label.name + '_' + label.id,
                        title: $t(label.name),
                        second_title: $t(label.second_text)
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component"/>
                    </template>
                </GhNoDropSelector>
            </div>
        </template>
        <template v-else>
            <form id="form_collaboration">
                <div v-for="(label, labelIndex) in data" :key="labelIndex">
                    <GhNoDropSelector v-if="label.show"
                        :datablock="{
                            id: label.name + '_' + label.id,
                            title: $t(label.name),
                            second_title: $t(label.second_text)
                        }"
                    >
                        <template v-slot:content>
                            <component v-bind:is="label.component"/>
                        </template>
                    </GhNoDropSelector>
                </div>
            </form>
        </template>

        <div class="centered" style="margin-top: 10px;" v-if="!hideBtn">
            <GhButton
                :datainput="{id: 'btn_send',text: $t('send_request'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="sendCollaboration();"
            />
            <GhButton
                :datainput="{id: 'btn_back',text: $t('go_out'), style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="back();"
            />
        </div>
    </div>
</template>
<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import personal_data from "./template/personal_data.vue";
    import professional_relationship from "./template/professional_relationship.vue";
    import data_treatment from "./template/data_treatment.vue";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "list_general",
        props: ['hideBtn'],
        components: {
            GhButton,
            GhNoDropSelector,
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'personal_data', component: personal_data, result: '', show: true , second_text: ''},
                    {id: 2, name: 'professional_relationship', component: professional_relationship, result: '', show: true , second_text: ''},
                    {id: 3, name: 'data_treatment', component: data_treatment, result: '', show: true , second_text: ''},
                ],
                haveData: false
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getHoIsSpain', '', {root: true});
            await this.$store.dispatch('getCountry', '', {root:true});
            await this.$store.dispatch('getProfessionalExperienceData', '', {root:true});
            await this.$store.dispatch('getSectorsExperienceData', '', {root:true});
            await this.$store.dispatch('getLanguagesEvaluatorData', '', {root:true});
            await this.$store.dispatch('getSocialReason', '', {root:true});
            await this.$store.dispatch('getProfessionalActivitySectorFeed', '', {root:true});
            await this.$store.dispatch('getNumEmployers', '', {root:true});
            await this.$store.dispatch('getTreatmentText', '', {root:true});
            await this.$store.dispatch('resetProvinceByCommunity', '', {root:true});
            await this.$store.dispatch('resetProvinceByCommunityProfessional', '', {root:true});
            await this.$store.dispatch('resetCommunityByCountry', '', {root:true});
            await this.$store.dispatch('resetCommunityByCountryProfessional', '', {root:true});

            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);

            if(this.hideBtn){
                this.data = [
                    {id: 1, name: 'personal_data', component: personal_data, result: '', show: true , second_text: ''},
                    {id: 2, name: 'professional_relationship', component: professional_relationship, result: '', show: true , second_text: ''},
                ];
            }

            this.haveData = true;
        },
        methods: {
            back(){
                this.$store.dispatch('TO_LOGIN','',{root:true});
            },
            sendCollaboration(){
                if(this.Global.checkRequired('form_collaboration') === 'OK') {
                    const formData = new FormData(document.getElementById('form_collaboration'));
                    this.$store.dispatch('SET_COLLABORATION', {
                        formData: [...formData],
                    }, {root: true});
                }
            },
        }
    }
</script>

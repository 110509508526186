import SupportList from "@/view/support/list_support";
import SupportCrmIncidence from "@/view/support/templates/incidences";

const routes = [
    {
        path: '/support',
        name: 'support',
        component: SupportList,
        props: {showMenu: true}
    },
    {
        path: '/support/incidence',
        name: 'incidence',
        component: SupportCrmIncidence,
        props: {id: null, showMenu: true,  otherTitle: 'see_incidence'},
        children: [
            {
                path: '/support/incidence/:id',
                name: 'incidence_id',
                component: SupportCrmIncidence,
                props: {id: null, showMenu: true,  otherTitle: 'see_incidence'},
            },
            {
                path: '/support/incidence/:id/:view',
                name: 'incidence_view',
                component: SupportCrmIncidence,
                props: {id: null, showMenu: true, view: true, otherTitle: 'see_incidence'},
            }
        ],
    },
    {
        path: '/support/incidence_new/:add/',
        name: 'incidence_new',
        component: SupportCrmIncidence,
        props: {showMenu: false,background:true,floatWindow:true},
    },
    {
        path: '/support/incidence_new/:add/:origin_id',
        name: 'incidence_new_by_origin',
        component: SupportCrmIncidence,
        props: {showMenu: false,background:true,floatWindow:true},
    },
];

export default routes;

import ListBaseCreation from "@/view/base_creation/list_base_creation.vue";

import SupportMaterial from './support_material/route';
import Exercises from './exercises/route';

const route = [
    {
        path: '/base-creation',
        name: 'base_creation',
        component: ListBaseCreation,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...SupportMaterial,
    ...Exercises
]

export default routes;

import ConfigTopics from "@/view/config/creation/topics/template/topic.vue";

const routes = [
    {
        path: '/config/creation/topics',
        name: 'config_topics',
        component: ConfigTopics,
        props: {id: null, showMenu: true, otherTitle: 'topic'},
        children: [
            {
                path: '/config/creation/topics/:id',
                name: 'config_topics_id',
                component: ConfigTopics,
                props: {id: null, showMenu: true, otherTitle: 'topic'},
            },
            {
                path: '/config/creation/topics/:id/:view',
                name: 'config_topics_id_view',
                component: ConfigTopics,
                props: {id: null, view: true, showMenu: true, otherTitle: 'topic'},
            }
        ]
    }
];

export default routes;
<template>
    <div style="width: 100%">
        <div class="input-box-container">
            <label
                v-for="value in answers"
                :key="value"
                class="input-box-item"
                :class="{ selected: isSelected(value) }"
            >
                <input
                    :type="block.type"
                    :name="block.name"
                    :value="value"
                    v-model="selected"
                    v-bind="$attrs"
                    @change="handleChange(value)"
                />
                <label class="T-subtitle">{{ value }}</label>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'gh-ce-check-selector-box',
        props: ['block', 'answers', 'defaultSelected'],
        data() {
            return {
                selected: this.block.type === 'radio' ? this.defaultSelected : [...this.defaultSelected]
            };
        },
        methods: {
            handleChange(value) {
                if (this.block.type === 'checkbox') {
                    const index = this.selected.indexOf(value);
                    if (index > -1) {
                        this.selected.splice(index, 1);
                    } else {
                        this.selected.push(value);
                    }
                } else {
                    this.selected = value;
                }

                this.$emit('input', this.selected);
            },
            isSelected(value) {
                if (this.block.type === 'checkbox') {
                    return this.selected.includes(value);
                } else {
                    return this.selected === value;
                }
            }
        }
    }
</script>

<style>
    .input-box-container {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
    }

    .input-box-item {
        position: relative;
        background-color: #F5F5F5;
        flex: 1 0 0;
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        border-radius: 2px;
        box-sizing: border-box;
        border: 2px solid transparent;
        transition: border-color 0.3s ease, background-color 0.3s ease;
    }

    .input-box-item.selected {
        border-color: #1B82C5;
    }

    .input-box-item.selected > .T15_b{
        color: #1B82C5 !important;
    }

    .input-box-item input[type="radio"],
    .input-box-item input[type="checkbox"] {
        display: none;
    }
    .input-box-item label {
        pointer-events: none;
    }
</style>
import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";

Vue.use(vuex);

export default {
    state: {
        sections: [],
        data: [],
        refreshOngoingPrograms: false,
        refreshOngoingProgramsCompany: false
    },
    mutations: {
        loadOngoingPrograms(state, payload){
            state.refreshOngoingPrograms = !state.refreshOngoingPrograms;
            state.sections = payload;
        },
        loadOngoingProgramsCompany(state, payload){
            state.refreshOngoingProgramsCompany = !state.refreshOngoingProgramsCompany;

            if(state.data[payload.itinerary] === undefined){
                state.data[payload.itinerary] = [];
            }

            state.data[payload.itinerary] = payload.data;
        }
    },
    getters: {
        getterOngoingPrograms(state){
            const dummy = state.refreshOngoingPrograms; // eslint-disable-line no-unused-vars
            return state.sections;
        },
        getterOngoingProgramsCompany(state){
            const dummy = state.refreshOngoingProgramsCompany; // eslint-disable-line no-unused-vars
            return state.data;
        }
    },
    actions: {
        async getOngoingPrograms(state){
            await axios.get(
                API_URL + "assign/ongoing_programs"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadOngoingPrograms', response.data.data);
                    }
                }
            )
        },
        async getOngoingProgramsCompany(state, item){
            await axios.get(
                API_URL + "assign/ongoing_programs/" + item.itinerary
            ).then(
                response => {
                    if(response.status === 200) {
                        let params = {
                            'itinerary': item.itinerary,
                            'data': response.data.data
                        }

                        state.commit('loadOngoingProgramsCompany', params);
                    }
                }
            )
        }
    }
}

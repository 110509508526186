<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'table_users',
            }"
            :header="header"
            :data="PermsCompaniesCompanies.list_group_users"
        >
        </GhTableCommon>
    </div>
    <div v-else class="centered" style="padding-bottom: 10px; padding-top: 10px">
        <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapState} from "vuex";

    export default {
        name: "template_persons",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapState(['PermsCompaniesCompanies'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'first_name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'surnames', field: 'last_name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'email', field: 'email', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getGroupUsers', {
                    id: this.$root._route.params.id
                }, {root: true});

                this.haveData = true;
            }
        }
    }
</script>
<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'table_programs',
            }"
            :header="header"
            :data="getterListProgramsClients"
        >
            <template v-slot:actions="{itemProp}">
                <template v-if="itemProp.show_action">
                    <GhAction
                        :dataction="{
                            id: 'download_report',
                            text: $t('report'),
                            icon: require('@/assets/gh_new_dessign/doc.svg')
                        }"
                        @click="$store.dispatch('easyDownload', {url: itemProp.file_download}, {root: true})"
                    />
                </template>
            </template>
        </GhTableCommon>
    </div>
    <div v-else class="centered" style="padding-bottom: 10px; padding-top: 10px">
        <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapGetters} from "vuex";

    export default {
        name: "template_persons",
        components: {
            GhAction,
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterListProgramsClients'])
        },
        data(){
            return {
                header: [
                    {text: 'programs', field: 'program', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 80%;'},
                    {text: 'start_date', field: 'start_date', sorting: true, typeSort: 'date', style: 'min-width: 120px; max-width: 120px; width: 10%;'},
                    {text: 'end_date', field: 'end_date', sorting: true, typeSort: 'date', style: 'min-width: 120px; max-width: 120px; width: 10%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getProgramsFinishByCleint', {
                    id: this.$root._route.params.id
                }, {root: true});

                this.haveData = true;
            }
        }
    }
</script>
import Vue from "vue";
import vuex from "vuex";

import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        pre_selected_bibliography: [],
        pre_selected_topic: [],
        pre_selected_videography: [],
        pre_selected_example: [],
        pre_selected_support_material: [],
    },
    mutations: {
        loadPreDefindedBibliography(state,payload){
            state.pre_selected_bibliography = payload
        },
        loadPreDefindedTopic(state,payload){
            state.pre_selected_topic = payload
        },
        loadPreDefindedVideography(state,payload){
            state.pre_selected_videography = payload
        },
        loadPreDefindedExample(state,payload){
            state.pre_selected_example = payload
        },
        loadPreDefindedSupportMaterial(state,payload){
            state.pre_selected_support_material = payload
        },
    },
    actions: {
        async getPreDefindedBibliography(state,item){
            var name = '';
            var to_for = new Array();
            var requestLogin = '';
            for (const [key] of Object.entries(item)) {
                switch(key){
                    case 'ejercicios':
                        name = 'exercise';
                        to_for = Object.keys(item.ejercicios);
                        requestLogin = API_URL + 'configuration/exercise/pre_selected_bibliography';
                    break;
                    case 'actividades':
                        name = 'activity';
                        to_for = Object.keys(item.actividades);
                        requestLogin = API_URL + 'creation/activities/pre_selected_bibliography';
                    break;
                    case 'ambits_certificates':
                        name = 'ambit_certificate';
                        to_for = Object.keys(item.ambits_certificates);
                        requestLogin = API_URL + 'configuration/itineraries/pre_selected_bibliography';
                    break;
                }
            }

            let formData = new FormData();
            for(var i=0;i<to_for.length;i++){
                if(to_for[i] !== undefined){
                    formData.append(name+'[]', to_for[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadPreDefindedBibliography", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPreDefindedTopic(state,item){
            var name = '';
            var to_for = new Array();
            var requestLogin = '';
            for (const [key] of Object.entries(item)) {
                switch(key){
                    case 'ejercicios':
                        name = 'exercise';
                        to_for = Object.keys(item.ejercicios);
                        requestLogin = API_URL + 'configuration/exercise/pre_selected_topic';
                    break;
                    case 'actividades':
                        name = 'activity';
                        to_for = Object.keys(item.actividades);
                        requestLogin = API_URL + 'creation/activities/pre_selected_topic';
                    break;
                    case 'ambits_certificates':
                        name = 'ambit_certificate';
                        to_for = Object.keys(item.ambits_certificates);
                        requestLogin = API_URL + 'pre_selected_topic/'+name;
                    break;
                }
            }

            let formData = new FormData();

            for(var i=0;i<to_for.length;i++){
                if(to_for[i] !== undefined){
                    formData.append(name+'[]', to_for[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.commit("loadPreDefindedTopic", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPreDefindedVideography(state,item){
            var name = '';
            var to_for = new Array();
            var requestLogin = '';
            for (const [key] of Object.entries(item)) {
                switch(key){
                    case 'ejercicios':
                        name = 'exercise';
                        to_for = Object.keys(item.ejercicios);
                        requestLogin = API_URL + 'configuration/exercise/pre_selected_videography';
                    break;
                    case 'actividades':
                        name = 'activity';
                        to_for = Object.keys(item.actividades);
                        requestLogin = API_URL + 'creation/activities/pre_selected_videography';
                    break;
                    case 'ambits_certificates':
                        name = 'ambit_certificate';
                        to_for = Object.keys(item.ambits_certificates);
                        requestLogin = API_URL + 'configuration/itineraries/pre_selected_videography';
                    break;
                }
            }

            let formData = new FormData();
            for(var i=0;i<to_for.length;i++){
                if(to_for[i] !== undefined){
                    formData.append(name+'[]', to_for[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadPreDefindedVideography", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPreDefindedExample(state,item){
            var name = '';
            var to_for = new Array();
            var requestLogin = '';
            for (const [key] of Object.entries(item)) {
                switch(key){
                    case 'ejercicios':
                        name = 'exercise';
                        to_for = Object.keys(item.ejercicios);
                        requestLogin = API_URL + 'configuration/exercise/pre_selected_example';
                    break;
                    case 'actividades':
                        name = 'activity';
                        to_for = Object.keys(item.actividades);
                        requestLogin = API_URL + 'creation/activities/pre_selected_example';
                    break;
                    case 'ambits_certificates':
                        name = 'ambit_certificate';
                        to_for = Object.keys(item.ambits_certificates);
                        requestLogin = API_URL + 'configuration/itineraries/pre_selected_example';
                    break;
                }
            }

            let formData = new FormData();
            for(var i=0;i<to_for.length;i++){
                if(to_for[i] !== undefined){
                    formData.append(name+'[]', to_for[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadPreDefindedExample", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPreDefindedSupportMaterial(state,item){
            var name = '';
            var to_for = new Array();
            var requestLogin = '';
            for (const [key] of Object.entries(item)) {
                switch(key){
                    case 'ejercicios':
                        name = 'exercise';
                        to_for = Object.keys(item.ejercicios);
                        requestLogin = API_URL + 'configuration/exercise/pre_selected_support_material';
                    break;
                    case 'actividades':
                        name = 'activity';
                        to_for = Object.keys(item.actividades);
                        requestLogin = API_URL + 'creation/activities/pre_selected_support_material';
                    break;
                    case 'ambits_certificates':
                        name = 'ambit_certificate';
                        to_for = Object.keys(item.ambits_certificates);
                        requestLogin = API_URL + 'configuration/itineraries/pre_selected_support_material';
                    break;
                }
            }

            let formData = new FormData();
            for(var i=0;i<to_for.length;i++){
                if(to_for[i] !== undefined){
                    formData.append(name+'[]', to_for[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadPreDefindedSupportMaterial", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
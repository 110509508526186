import ConfigBenchmark from "@/view/config/benchmark/benchmark/template/benchmark.vue";

const routes = [
    {
        path: '/config/creation/benchmark/:id',
        name: 'config_benchmark_id',
        component: ConfigBenchmark,
        props: {id: null, showMenu: true, otherTitle: 'benchmark'},
    }
];

export default routes;
<template>
    <div class="gh-content-global-questionnaire">
        <div v-if="haveData" style="width: 100%;">
            <div style="margin-left: 10px; margin-right: 10px; float: right; margin-top: 10px; margin-bottom: 5px;">
                <GhButton
                    class="button-questionnaire-secondary"
                    :datainput="{
                        id: 'btn_generate_pdf',
                        text: pdfGenerate ? $t('generate_pdf') : $t('generating'),
                        icon: pdfGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                    }"
                    :disabled="!pdfGenerate"
                    @click="generatePDF()"
                />
            </div>
        </div>
        <div class="question-body">
            <div class="gh-content-global">
                <form id="form_joint_map" v-if="haveData">
                    <GhChartTable
                        :extratable="{
                            id: 'joint_map',
                            more: require('@/assets/questionnaire/arrow-down.svg'),
                            less: require('@/assets/questionnaire/arrow-up.svg'),
                            pointer: {
                                colors: [
                                    '#C7C7C7',
                                    '#F58449',
                                    '#005392'
                                ],
                                title: [
                                    'for_start',
                                    'in_progress',
                                    'finish'
                                ]
                            }
                        }"
                        :header="headerChart"
                        :data="getterQuestionnaireJointMap.data"
                        :footer="[]"
                        :type="getterQuestionnaireJointMap.type"
                        :evaluator="getterQuestionnaireJointMap.is_evaluator"
                        :leader="getterQuestionnaireJointMap.is_leader"
                        :evaluation_finished="getterQuestionnaireJointMap.evaluation_finished"
                        :states="statesChart"
                        @advice="''"
                        @columnAdvice="columnAdvice"
                        ref="RefJointMap"
                    >
                        <template v-slot:actions="{itemProp}">
                            <template v-if="itemProp.actions !== undefined && itemProp.actions.opportunities">
                                <GhAction
                                    style="margin-left: 10px;"
                                    class="color-light-blue T-subtitle-second underline-light-blue"
                                    :dataction="{
                                        id: 'modify_action_' + itemProp.id,
                                        text: $t('selecting'),
                                        class: 'T-subtitle-colored',
                                        icon: require('@/assets/gh_new_dessign/tick.svg')
                                    }"
                                    @click="changeOportunitiesOrHardPoints(itemProp)"
                                />
                            </template>
                        </template>
                        <template v-slot:component="{itemProp}">
                            <template v-if="itemProp.actions !== undefined && itemProp.actions.valoration">
                                <GhInputTextField
                                    :datalabel="{
                                        id: 'label_joint_evaluations_valoration_' + itemProp.id,
                                        text: '',
                                        style: '',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        id: 'input_joint_evaluations_valoration_' + itemProp.id,
                                        name: 'joint_evaluations[' + itemProp.id + '][valoration]',
                                        style: 'width: 100%',
                                        type: 'text',
                                        value: valorations['uuid_' + itemProp.id]['valoration'],
                                        required: true,
                                        label_required: itemProp.title
                                    }"
                                    :ref="'RefValoration' + itemProp.id"
                                    @keyup="persistValoration(itemProp.id)"
                                />
                            </template>
                        </template>
                    </GhChartTable>
                </form>

                <div class="centered" style="margin-top: 15px;">
                    <GhButton
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_back_joint_map',
                            text: $t('back')
                        }"
                        :title="$t('back')"
                        @click="router.go(-1)"
                    />
                    <template v-if="getterQuestionnaireJointMap.is_leader">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_save_joint_map',
                                text: $t('save')
                            }"
                            :title="$t('save')"
                            :disabled="disabledSave"
                            @click="setLiderMap('save')"
                        />
                        <GhButton
                            :datainput="{
                                id: 'btn_finish_joint_map',
                                text: $t('finish'),
                                class: 'T19 container-md_button_content button-questionnaire-primary'
                            }"
                            :title="disabledFinish ? $t('to_finish_need_all_required') : $t('finish')"
                            :disabled="disabledFinish"
                            @click="setLiderMap('finish')"
                        />
                    </template>
                </div>

                <div v-if="haveData" :key="'ADVICE' + refreshPopup">
                    <component :is="popups.advice" :edit="getterQuestionnaireJointMap.is_leader" :id="currentIdentifier"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhChartTable from "fe-gh-chart-table-lib";
    import popup_advice from "@/view/shared/popup/advice.vue";
    import GhLocation from "fe-gh-location-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'joint_map',
        components: {
            GhInputTextField,
            GhAction,
            GhButton,
            GhLocation,
            GhChartTable
        },
        computed: {
            ...mapGetters(['getterQuestionnaireJointMap'])
        },
        data(){
            return{
                haveData: false,
                pdfGenerate: true,
                valorations: [],
                selectedItems: [],
                disabledSave: true,
                disabledFinish: true,
                refreshPopup: 0,
                currentIdentifier: null,
                finishEval: false,
                headerChart: [
                    {text: 'title', field: 'title', style: 'max-width: 50px; width: 100%;', show_drag: true}
                ],
                statesChart: {
                    0: 'to_do',
                    1: 'unfinished',
                    2: 'completed',
                },
                popups: {
                    advice: popup_advice
                }
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getQuestionnaireJointMap', {
                id: this.$root._route.params.id,
                type: this.$root._route.params.type,
                extra_id: this.$root._route.params.extra_id
            }, {root: true});

            await this.$store.dispatch('getUserColumns', {
                id: this.$root._route.params.id,
                type: this.$root._route.params.type,
                extra_id: this.$root._route.params.extra_id
            }, {root: true}).then(response => {
                response.columns.forEach(item => {
                    var row = {};

                    row = {
                        text: item.key_field.toUpperCase(),
                        field: item.key_field,
                        column_id: item.id,
                        full_name: item.full_name,
                        advice: true,
                        show_punctuation: response.show_punctuation,
                        style: 'min-width: 130px; width: 130px; padding-left: 10px; padding-right: 10px; text-align:center;'
                    }

                    this.finishEval = response.show_punctuation;

                    this.headerChart.push(row);
                });
            });

            if(this.getterQuestionnaireJointMap.is_leader) {
                this.headerChart.push({
                    text: '',
                    field: 'punctuation',
                    style_be: true,
                    style: 'min-width: 50px; width: 1%;'
                });

                this.headerChart.push({
                    text: '',
                    field: 'hard_points',
                    hard_points: true,
                    style: 'min-width: 30px; width: 30px; text-align:center;'
                });

                this.headerChart.push({
                    text: '',
                    field: 'improvement_opportunities',
                    improvement_opportunities: true,
                    style: 'min-width: 30px; width: 30px; text-align:center;'
                });

                this.headerChart.push({
                    text: '',
                    field: 'good_practices',
                    good_practices: true,
                    style: 'min-width: 30px; width: 30px; text-align:center;'
                });

                this.headerChart.push({
                    text: '',
                    field: 'component',
                    component: true,
                    style: 'min-width: 50px; width: 1%;'
                });

                this.headerChart.push({
                    text: '',
                    field: 'actions',
                    slot: true,
                    style: 'min-width: 50px; width: 1%;'
                });

                let pendingValorations = false;
                this.getterQuestionnaireJointMap.data.forEach((item) => {
                    if (item.actions !== undefined && item.actions.valoration) {
                        if (this.valorations['uuid_' + item.id] === undefined) {
                            this.valorations['uuid_' + item.id] = [];
                            this.valorations['uuid_' + item.id]['valoration'] = '';
                        }

                        this.valorations['uuid_' + item.id]['valoration'] = item.valoration;

                        if (item.valoration === '' || item.valoration === null) {
                            pendingValorations = true;
                        }
                    }

                    if (item.actions !== undefined && item.actions.opportunities) {
                        if (this.valorations['uuid_' + item.id]['selecteds'] === undefined) {
                            this.valorations['uuid_' + item.id]['selecteds'] = item.selecteds;
                        }
                    }
                });

                this.disabledFinish = pendingValorations;
            }

            this.headerChart = this.headerChart.filter(n => n);

            this.haveData = true;
        },
        methods: {
            async generatePDF(){
                this.pdfGenerate = false;

                await this.$store.dispatch('getPDFGlobalMap', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type
                }, {root: true}).then((response) => {
                    if(response.status === 200){
                        const link = document.createElement('a');
                        link.id = 'download_map_pdf';
                        link.href = response.data.data;
                        link.download = response.data.data.split('/').pop();
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        this.pdfGenerate = true;
                    }
                });
            },
            async columnAdvice(item, label){
                this.Global.windowOpen(this.Global.openSecondWindow('evaluation_questionnaire_map',{
                    type: this.$root._route.params.type,
                    user_id: label.column_id,
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    complete: this.finishEval
                }),'evaluation_questionnaire_' + this.$root._route.params.id + '_' + item.id + '_' + label.column_id);
            },
            async changeOportunitiesOrHardPoints(item){
                this.selectedItems = this.valorations['uuid_' + item.id]['selecteds'];
                this.currentIdentifier = item.id;
                this.refreshPopup++;

                await this.$store.dispatch('getAdvice', {
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    type: this.$root._route.params.type,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});

                this.Global.openPopUp('popup_advice');
            },
            persistValoration(id){
                if(this.valorations['uuid_' + id] === undefined) {
                    this.valorations['uuid_' + id] = [];
                    this.valorations['uuid_' + id]['valoration'] = ''
                }

                this.valorations['uuid_' + id]['valoration'] = this.$refs['RefValoration' + id].$el.children[0].value;

                let pendingValorations = false;
                Object.entries(this.valorations).forEach((item) => {
                    if(item[1].valoration === '' || item[1].valoration === null){
                        pendingValorations = true;
                    }
                });

                this.disabledSave = false;
                this.disabledFinish = pendingValorations;
            },
            async setLiderMap(type){
                if(this.Global.checkRequired('form_joint_map') == 'OK') {
                    const formData = new FormData(document.getElementById('form_joint_map'));

                    await this.$store.dispatch('setLiderMap', {
                        id: this.$root._route.params.id,
                        type: this.$root._route.params.type,
                        type_set: type,
                        formData: [...formData],
                        extra_id: this.$root._route.params.extra_id
                    }, {root: true}).then((response) => {
                        if (response.status === 200) {
                            this.disabledSave = true;
                            if (type === 'finish') {
                                this.disabledFinish = true;
                                this.router.go(-1);
                            }
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    .gh_position-joint {
        height: auto;
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 4px 4px 8px #1b82c570, -4px 4px 8px #1b82c570;
    }
</style>
<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'type_evaluation', title: $t('type_evaluation')}">
            <template v-slot:content>
                <form id="form_type_evaluation" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="(!$root._route.params.view && $root._route.params.id === undefined) || (!$root._route.params.view && ConfigTypeEvaluation.data !== undefined && ConfigTypeEvaluation.data.is_admin)">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'type_evaluation_code',
                                        name: 'type_evaluation[code]',
                                        style: 'width: 200px',
                                        value: ConfigTypeEvaluation.data.code,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeEvaluation.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="(!$root._route.params.view && $root._route.params.id === undefined) || (!$root._route.params.view && ConfigTypeEvaluation.data !== undefined && ConfigTypeEvaluation.data.is_admin)">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'type_evaluation_name',
                                        name: 'type_evaluation[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigTypeEvaluation.data.name,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeEvaluation.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="(!$root._route.params.view && $root._route.params.id === undefined) || (!$root._route.params.view && ConfigTypeEvaluation.data !== undefined && ConfigTypeEvaluation.data.is_admin)">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 164px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id: 'type_evaluation_description',
                                        name: 'type_evaluation[description]',
                                        value: ConfigTypeEvaluation.data.description
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeEvaluation.data.description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" v-if="$root._route.params.id !== undefined">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('punctuation'),
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'type_evaluation_score',
                                        name: 'type_evaluation[score]',
                                        style: 'min-width: 50px; width: 50px',
                                        value: ConfigTypeEvaluation.data.score,
                                        type: 'int'
                                    }"
                                    maxlength="4"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeEvaluation.data.score}}</label>
                            </template>
                        </div>
                        <template v-if="$root._route.params.id !== undefined && ConfigTypeEvaluation.data !== undefined && ConfigTypeEvaluation.data.modality !== undefined">
                            <div class="gh-row-content" v-for="(item, keyItem) in ConfigTypeEvaluation.data.modality" :key="'KEY_MODALITY' + keyItem">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('evaluator') + ' ' + item.name,
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'type_evaluation_evaluator',
                                        name: 'type_evaluation[evaluator][' + item.id + ']',
                                        style: 'min-width: 50px; width: 50px',
                                        value: item.evaluator,
                                        type: 'int'
                                    }"
                                    maxlength="4"
                                />
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('days') + ' ' + item.name,
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'type_evaluation_days',
                                        name: 'type_evaluation[days][' + item.id + ']',
                                        style: 'min-width: 50px; width: 50px',
                                        value: item.days,
                                        type: 'float',
                                        limitNumMax: '9999'
                                    }"
                                />
                            </div>
                        </template>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('@/assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitTypeEvaluation"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";

    export default {
        name: "type_evaluation_template",
        components: {
            GhTextarea,
            GhInputTextField,
            GhNoDropSelector,
            GhButton
        },
        computed: {
            ...mapState(['ConfigTypeEvaluation'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getTypeEvaluationData', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async submitTypeEvaluation(){
                if(this.Global.checkRequired('form_type_evaluation') === 'OK') {
                    const formData = new FormData(document.getElementById('form_type_evaluation'));
                    this.$store.dispatch('setTypeEvaluationData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>
import ConfigProfessionalActivitySector from "@/view/config/evaluators/professional_activity_sector/template/professional_activity_sector.vue";

const routes = [
    {
        path: '/config/evaluator/professional-activity-sector',
        name: 'config_professional_activity_sector',
        component: ConfigProfessionalActivitySector,
        props: {id: null, showMenu: true, otherTitle: 'professional_activity_sector'},
        children: [
            {
                path: '/config/evaluator/professional-activity-sector/:id',
                name: 'config_professional_activity_sector_id',
                component: ConfigProfessionalActivitySector,
                props: {id: null, showMenu: true, otherTitle: 'professional_activity_sector'},
            },
            {
                path: '/config/evaluator/professional-activity-sector/:id/:view',
                name: 'config_professional_activity_sector_id_view',
                component: ConfigProfessionalActivitySector,
                props: {id: null, view: true, showMenu: true, otherTitle: 'professional_activity_sector'},
            }
        ]
    }
];

export default routes;
<template>
    <div style="width: 100%; display: flex; justify-content: center;">
        <template v-if="haveData">
            <div class="card-container" style="padding: 20px; max-width: 1500px;">
                <template v-for="(itemCard, keyCard) in getterContractedPrograms">
                    <div :key="'KEY'+ keyCard">
                        <template v-if="!itemCard.finished">
                            <router-link :to="{name: 'initial_itinerary_window', params: {
                                type: 'initial',
                                id: itemCard.id,
                                extra_id: undefined
                            }}">
                                <component :is="components.card" :keyCard="keyCard" :itemCard="itemCard" :showButton="true"/>
                            </router-link>
                        </template>
                        <template v-else>
                            <div @click="downloadPDF(itemCard)">
                                <component :is="components.card" :keyCard="keyCard" :itemCard="itemCard" :showButton="true"/>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="centered" style="padding-bottom: 10px; margin-top: 15px;">
                <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    import card from '../../template/card.vue';

    export default {
        name: 'contracted_programs',
        computed: {
            ...mapGetters(['getterContractedPrograms'])
        },
        data(){
            return{
                haveData: false,
                components: {
                    card: card
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getContractedPrograms', '', {root: true});

                this.haveData = true;
            }
        },
        methods: {
            downloadPDF(item){
                this.$store.dispatch('easyDownload', {
                    url: item.download
                }, {root: true});
            }
        }
    }
</script>
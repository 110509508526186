<template>
    <div v-if="haveData">
        <div class="gh-row-content" v-if="getterTypeExercise.responsible.view" :style="!$root._route.params.view && $root._route.params.type !== 'enabled' ? 'margin-top: -10px;margin-bottom: 10px;' : ''">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: 'label_exercise_simple_question_responsible',
                        label: $t('responsible') + (getterTypeExercise.responsible.required ? '*' : ''),
                        title: $t('responsible'),
                        text: $t('select_responsible') + ':',
                        name: 'exercise[simple_question][responsible]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: getterTypeExercise.responsible.required,
                        checkparentdata: false,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    }"
                    :selected_input="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.responsible ? BaseCreationExerciseTable.exercise_data.simple_question.responsible : []"
                    :items="getFeeds.feed_responsible"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.responsible && BaseCreationExerciseTable.exercise_data.simple_question.responsible[0] !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.responsible[0].text : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhInputTextField
                        style="margin-top: -5px;"
                        :datalabel="{
                            text: $t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'exercise_simple_question_statement',
                            name: 'exercise[simple_question][statement]',
                            style: 'width: 600px',
                            value: BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.statement : '',
                        }"
                        maxlength="100"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('description'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_simple_question_description',
                            name: 'exercise[simple_question][description]',
                            value: BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.description : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.description : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('statement'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_simple_question_real_statement',
                            name: 'exercise[simple_question][real_statement]',
                            value: BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.real_statement : '',
                            required: false,
                            edited: false
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('real_statement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.real_statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style=" width: 100%;"
                        :datalabel="{
                            text: $t('substatement'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_simple_question_substatement',
                            name: 'exercise[simple_question][substatement]',
                            value: BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.substatement : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('substatement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.substatement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.information.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('information') + (getterTypeExercise.information.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_simple_question_information',
                        name: 'exercise[simple_question][information]',
                        value: BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.information : '',
                        required: getterTypeExercise.information.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.information : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.score_legend.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('score_legend') + (getterTypeExercise.score_legend.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_simple_question_score_legend',
                        name: 'exercise[simple_question][score_legend]',
                        value: BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.score_legend : '',
                        required: getterTypeExercise.score_legend.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('score_legend') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.simple_question !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.score_legend : ''"></label>
            </template>
        </div>
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b" style="padding-left:13px;">{{''}}</div>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>

            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhInputTextField
                    :datalabel="{
                        text: $t('evidence') + (getterTypeExercise.autoevaluation.checked ? '*' : ''),
                        style: '',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: getterTypeExercise.autoevaluation.checked,
                        id: 'exercise_simple_question_punctutation_evidence',
                        name: 'exercise[simple_question][punctuation][evidence]',
                        style: 'width: 50px',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.punctuation.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.punctuation.evidence : '',
                        type: 'int'
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('evidence') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.punctuation.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.punctuation.evidence : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_before_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_simple_question_text_evidence_before_download',
                        name: 'exercise[simple_question][text_evidence][before_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.before_download !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.before_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg')
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_before_download') + ''}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.before_download !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.before_download : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attach_template') + (getterTypeExercise.evidence.required ? '*' :'')}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'easy_upload_template',
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'exercise[simple_question][text_evidence][template]',
                        id_hidden: 'example',
                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_easy_upload_template',
                        style: ''
                    }"
                    :datainput="{
                        text: $t('attach'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== null && BaseCreationExerciseTable.exercise_data.simple_question.template.file_name !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.template.file_name : '',
                        file_path: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== null && BaseCreationExerciseTable.exercise_data.simple_question.template.file_path !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.template.file_path : '',
                        file_download: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== null && BaseCreationExerciseTable.exercise_data.simple_question.template.file_download !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.template.file_download : '',
                        required: getterTypeExercise.evidence.required,
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attach_template') + ''}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'easy_upload_template',
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'exercise[simple_question][text_evidence][template]',
                        id_hidden: 'example',
                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_easy_upload_template',
                        style: ''
                    }"
                    :datainput="{
                        view: true,
                        text: $t('attach'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== null && BaseCreationExerciseTable.exercise_data.simple_question.template.file_name !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.template.file_name : '',
                        file_path: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== null && BaseCreationExerciseTable.exercise_data.simple_question.template.file_path !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.template.file_path : '',
                        file_download: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.template !== null && BaseCreationExerciseTable.exercise_data.simple_question.template.file_download !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.template.file_download : '',
                        required: getterTypeExercise.evidence.required,
                    }"
                />
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_after_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_simple_question_text_evidence_after_download',
                        name: 'exercise[simple_question][text_evidence][after_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.after_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg')
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_after_download') + ''}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.text_evidence.after_download : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.feedback.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('feedback_answer'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_simple_question_feedback_answer',
                        name: 'exercise[simple_question][feedback][answer][answer]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined  && BaseCreationExerciseTable.exercise_data.simple_question.feedback.answer !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.feedback.answer : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_answer') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined  && BaseCreationExerciseTable.exercise_data.simple_question.feedback && BaseCreationExerciseTable.exercise_data.simple_question.feedback.answer !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.feedback.answer : ''"></label>
            </template>
        </div>
        <template v-if="(getterTypeExercise.evidence.view && getterTypeExercise.feedback.view) || (getterTypeExercise.conditions !== undefined && getterTypeExercise.conditions.length !== 0)">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_evidence'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_simple_question_feedback_evidence',
                            name: 'exercise[simple_question][feedback][evidence][answer]',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.feedback.evidence : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_evidence') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.feedback.evidence : ''"></label>
                </template>
            </div>
        </template>
        <template v-if="getterTypeExercise.not_apply.checked && getterTypeExercise.feedback.view">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_not_apply'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_simple_question_feedback_evidence',
                            name: 'exercise[simple_question][feedback][not_apply][answer]',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback.not_apply !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.feedback.not_apply : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_not_apply') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.simple_question !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback !== undefined && BaseCreationExerciseTable.exercise_data.simple_question.feedback.not_apply !== undefined ? BaseCreationExerciseTable.exercise_data.simple_question.feedback.not_apply : ''"></label>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    import {mapGetters, mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "type_simple_question",
        components: {
            GhTreeSelectorBlock,
            GhTextarea,
            GhInputTextField,
            GhEasyUpload
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable', 'getFeeds']),
            ...mapGetters(['getterTypeExercise'])
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                if (this.getterTypeExercise.responsible.view) {
                    await this.$store.dispatch('getFeedResponsibles', '', {root: true});
                }

                this.haveData = true;
            }
        }
    }
</script>

<style>
    #label_exercise_simple_question_responsible_selector_label, #label_exercise_subquestion_simple_question_responsible_selector_label{
        width: 150px;
        text-align: end;
    }
</style>
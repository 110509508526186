<template>
    <div v-if="getterTypeExercise.conditions !== undefined && getterTypeExercise.conditions.length !== 0 && haveData">
        <div class="section_line" style="margin-bottom: 15px;">
            <div class="T15_b">{{$t('conditions')}}</div>
        </div>
        <template v-for="(countIndex, countKey) in countConditions">
            <div class="gh-row-content" :key="'R50' + countKey">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('condition') + ' ' + (parseInt(countKey) + 1)}}</label>
                <GhAction v-if="countConditions.length > 1"
                    :dataction="{
                        id: 'delete_condition',
                        text: $t('delete_condition'),
                        icon: require('../../../../../../assets/svg/add_less.svg')
                    }"
                    @click="deleteBlockCondition(countKey)"
                />
            </div>
            <template v-for="(conditionItem, conditionKey) in getterTypeExercise.conditions">
                <div class="gh-row-content" :key="'A50' + conditionKey + countKey">
                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                        <GhInputTextField
                            :datalabel="{
                                text: $t(conditionItem === 1 ? 'value_same_as' : (conditionItem === 2 ? 'value_greather_than' : (conditionItem === 3 ? 'value_less_than' : 'value_between'))) + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'exercise_condition_' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '_' + countKey + '_value' + (conditionItem === 4 ? '_1' : ''),
                                name: 'exercise[condition]['+ countKey +'][' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '][value]'  + (conditionItem === 4 ? '[1]' : ''),
                                style: 'width: 50px',
                                value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value !== undefined ? (conditionItem !== 4 ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value : BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[1] !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[1] : '') : '',
                                type: 'int',
                                label_required: conditionItem === 4 ? $t('value_between') + '*' : ''
                            }"
                        />

                        <template v-if="conditionItem === 4">
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18px; min-width: 18px; text-align: right;">{{$t('and')}}</label>

                            <GhInputTextField
                                :datalabel="{
                                    text: '',
                                    style: 'width: 18px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'exercise_condition_between_' + countKey + '_value_2',
                                    name: 'exercise[condition][' + countKey + '][between][value][2]',
                                    style: 'width: 50px',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[2] !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[2] : '',
                                    type: 'int',
                                    label_required: $t('value_between') + '*'
                                }"
                            />
                        </template>

                        <GhInputTextField
                            :datalabel="{
                                text: $t('punctuation') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'exercise_condition_' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '_' + countKey + '_punctuation',
                                name: 'exercise[condition][' + countKey + '][' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '][punctuation]',
                                style: 'width: 50px; margin-right: 20px;',
                                value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].punctuation !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].punctuation : '',
                                type: 'int'
                            }"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(conditionItem === 1 ? 'value_same_as' : (conditionItem === 2 ? 'value_greather_than' : (conditionItem === 3 ? 'value_less_than' : 'value_between'))) + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">
                            {{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value !== undefined ? (conditionItem !== 4 ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value : BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[1] !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[1] : '') : ''}}
                            <template v-if="conditionItem === 4">
                                {{' ' + $t('and') + ' '}}
                                {{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[2] !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].value[2] : ''}}
                            </template>
                        </label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">
                            {{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].punctuation !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].punctuation : ''}}
                        </label>

                    </template>

                    <template>
                        <GhChecks
                            :datalabel="{
                                text:$t('evidence'),
                                style:'',
                                class:'',
                                id:''
                            }"
                            :datainput="{
                                required: false,
                                disabled: $root._route.params.view || $root._route.params.type === 'enabled',
                                selected: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence.check !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence.check ? true : false,
                                type:'checkbox',
                                id: '',
                                value:  1,
                                name: 'exercise[condition][' + countKey + '][' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '][evidence][check]'
                            }"
                            :countKey="countKey"
                            :conditionItem="conditionItem"
                            :ref="'ref_' + countKey + '_' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))"
                            @click="conditionEvidencePunctuation(countKey,(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))))"
                        />

                        <div style="display: none">{{getterStatusConditionEvidencePunctuation}}</div> <!-- MIRAR POR QUE NO FUNCIONA SIN ESTO -->
                        <div v-if="showPunctationEvidence !== undefined && showPunctationEvidence[countKey] !== undefined && showPunctationEvidence[countKey][(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && showPunctationEvidence[countKey][(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))]">
                            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('punctuation'),
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'exercise_condition_' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '_' + countKey + 'evidence_punctuation',
                                        name: 'exercise[condition][' + countKey + '][' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '][evidence][punctuation]',
                                        style: 'width: 50px; margin-right: 20px;',
                                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence.punctuation !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence.punctuation : '',
                                        type: 'int'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence.punctuation !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].evidence.punctuation : ''}}</label>
                            </template>
                        </div>
                    </template>
                </div>
                <div v-if="getterTypeExercise.feedback.view" class="gh-row-content" :key="'B50' + conditionKey + countKey">
                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                        <GhTextarea
                            :datalabel="{
                                text: $t('feedback'),
                                class: 'T15_b',
                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                            }"
                            :datainput="{
                                id: 'exercise_condition_' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '_' + countKey + '_feedback',
                                name: 'exercise[condition][' + countKey + '][' + (conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between'))) + '][feedback]',
                                value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].feedback !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].feedback : '',
                                required: false,
                                edited: true,
                                image: {
                                    subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                    superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                    bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                    italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                    underline: require('../../../../../../assets/img/text/text-underline.svg')
                                }
                            }"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.condition !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey] !== undefined && BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].feedback !== undefined ? BaseCreationExerciseTable.exercise_data.condition[(conditionItem === 1 ? 'equal' : (conditionItem === 2 ? 'higher_than' : (conditionItem === 3 ? 'minor_than' : 'between')))][countKey].feedback : ''}}</label>
                    </template>
                </div>
            </template>
        </template>
        <GhAction v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'"
            style="margin-left: 160px;"
            :dataction="{
                id: 'new_condition',
                text: $t('add_another_condition'),
                icon: require('../../../../../../assets/svg/add_plus.svg')
            }"
            @click="newBlockCondition"
        />
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters, mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'conditions_section',
        components: {
            GhChecks,
            GhInputTextField,
            GhTextarea,
            GhAction
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable']),
            ...mapGetters(['getterTypeExercise','getterStatusConditionEvidencePunctuation'])
        },
        data(){
            return {
                haveData: false,
                countConditions: [0],
                showPunctationEvidence: []
            }
        },
        beforeMount() {
            this.haveData = true;
        },
        mounted() {
            Object.values(this.$refs).forEach((refs) => {
                refs.forEach((item) => {
                    var conditionType = (item.$attrs.conditionItem === 1 ? 'equal' : (item.$attrs.conditionItem === 2 ? 'higher_than' : (item.$attrs.conditionItem === 3 ? 'minor_than' : 'between')));
                    this.conditionEvidencePunctuation(item.$attrs.countKey,conditionType);
                });
            });
        },
        watch: {
            'getterStatusConditionEvidencePunctuation'(data) {
                this.showPunctationEvidence = data;
            },
        },
        methods: {
            newBlockCondition(){
                var block = this.countConditions[this.countConditions.length - 1];
                this.countConditions.push(block++);
            },
            deleteBlockCondition(key){
                delete this.countConditions[key];
                this.countConditions = this.countConditions.filter((n) => n !== '');
            },
            conditionEvidencePunctuation(key,type){
                if(this.showPunctationEvidence[key] === undefined) this.showPunctationEvidence[key] = [];
                if(this.showPunctationEvidence[key][type] === undefined) this.showPunctationEvidence[key][type] = [];
                this.showPunctationEvidence[key][type] = this.$refs['ref_' + key + '_' + type][0].selected;

                this.$store.dispatch('setTemporalStatusConditionEvidencePunctuation', {
                    key: key,
                    type: type,
                    selected: this.$refs['ref_' + key + '_' + type][0].selected
                }, {root: true});
            }
        }
    }
</script>
<template>
    <div v-if="haveData">
        <div class="gh-row-content" v-if="getterTypeExercise.responsible.view" :style="!$root._route.params.view && $root._route.params.type !== 'enabled' ? 'margin-top: -10px;margin-bottom: 10px;' : ''">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: 'label_exercise_close_n_correct_responsible',
                        label: $t('responsible') + (getterTypeExercise.responsible.required ? '*' : ''),
                        title: $t('responsible'),
                        text: $t('select_responsible') + ':',
                        name: 'exercise[close_n_correct][responsible]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: getterTypeExercise.responsible.required,
                        checkparentdata: false,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    }"
                    :selected_input="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.responsible ? BaseCreationExerciseTable.exercise_data.close_n_correct.responsible : []"
                    :items="getFeeds.feed_responsible"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.responsible && BaseCreationExerciseTable.exercise_data.close_n_correct.responsible[0] !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.responsible[0].text : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhInputTextField
                        style="margin-top: -5px;"
                        :datalabel="{
                            text: $t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'exercise_close_n_correct_statement',
                            name: 'exercise[close_n_correct][statement]',
                            style: 'width: 600px',
                            value: BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.statement : '',
                        }"
                        maxlength="100"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('description'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_close_n_correct_description',
                            name: 'exercise[close_n_correct][description]',
                            value: BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.description : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.description : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style="width: 100%;"
                        :datalabel="{
                            text: $t('statement'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_close_n_correct_real_statement',
                            name: 'exercise[close_n_correct][real_statement]',
                            value: BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.real_statement : '',
                            required: false,
                            edited: false
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('statement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.real_statement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <div style="width: 100%">
                    <GhTextarea
                        style=" width: 100%;"
                        :datalabel="{
                            text: $t('substatement'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_close_n_correct_substatement',
                            name: 'exercise[close_n_correct][substatement]',
                            value: BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.substatement : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('substatement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.substatement : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.information.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('information') + (getterTypeExercise.information.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_close_n_correct_information',
                        name: 'exercise[close_n_correct][information]',
                        value: BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.information : '',
                        required: getterTypeExercise.information.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.information : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.score_legend.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('score_legend') + (getterTypeExercise.score_legend.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_close_n_correct_score_legend',
                        name: 'exercise[close_n_correct][score_legend]',
                        value: BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.score_legend : '',
                        required: getterTypeExercise.score_legend.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('score_legend') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.score_legend : ''"></label>
            </template>
        </div>
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b" style="padding-left:13px;">{{''}}</div>
        </div>
        <template v-if="getterTypeExercise.possible_answers.length !== 0">
            <GhTableCommon
                style="margin-bottom: 10px;"
                :extratable="{
                    id: 'answers'
                }"
                :header="header"
                :data="getterTypeExercise.possible_answers"
            >
                <template v-slot:field_component="{itemProp, labelProp}">
                    <div v-if="labelProp.field == 'answer'">
                        <template v-if="itemProp.view">
                            {{itemProp.answer}}
                        </template>
                        <template v-else>
                            <GhInputTextField
                                :datalabel="{}"
                                :datainput="{
                                    required: labelProp.required,
                                    id: 'exercise_close_n_correct_answer_answer_'+itemProp.id,
                                    name: 'exercise[close_n_correct][answer][answer]['+itemProp.id+']',
                                    style: 'margin-right: 10px;',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.answer !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.answer[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.answer.answer[itemProp.id] : '',
                                }"
                            />
                        </template>
                    </div>
                    <div v-else-if="labelProp.field == 'punctuation'">
                        <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                            <GhInputTextField
                                :datalabel="{}"
                                :datainput="{
                                    type: 'int',
                                    required: labelProp.required,
                                    id: 'exercise_close_n_correct_answer_punctuation_'+itemProp.id,
                                    name: 'exercise[close_n_correct][answer][punctuation]['+itemProp.id+']',
                                    style: 'margin-right: 10px;',
                                    value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.punctuation[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.answer.punctuation[itemProp.id] : '',
                                }"
                            />
                        </template>
                        <template v-else>
                            {{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.punctuation[itemProp.id] !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.answer.punctuation[itemProp.id] : ''}}
                        </template>
                    </div>
                </template>
            </GhTableCommon>
        </template>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhInputTextField
                    :datalabel="{
                        text: $t('evidence') + (getterTypeExercise.autoevaluation.checked ? '*' : ''),text: $t('evidence') + '*',
                        style: '',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: getterTypeExercise.autoevaluation.checked,
                        id: 'exercise_close_n_correct_punctutation_evidence',
                        name: 'exercise[close_n_correct][punctuation][evidence]',
                        style: 'width: 50px',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.punctuation.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.punctuation.evidence : '',
                        type: 'int'
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('evidence') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.punctuation !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.punctuation.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.punctuation.evidence : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_before_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_close_n_correct_text_evidence_before_download',
                        name: 'exercise[close_n_correct][text_evidence][before_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.before_download !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.before_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_before_download') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.before_download !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.before_download : ''"></label>
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attach_template') + (getterTypeExercise.evidence.required ? '*' :'')}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'easy_upload_template',
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'exercise[close_n_correct][text_evidence][template]',
                        id_hidden: 'example',
                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_easy_upload_template',
                        style: ''
                    }"
                    :datainput="{
                        text: $t('attach'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== null && BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_name !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_name : '',
                        file_path: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== null && BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_path !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_path : '',
                        file_download: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== null && BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_download !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_download : '',
                        required: getterTypeExercise.evidence.required,
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attach_template') + ''}}</label>
                <GhEasyUpload
                    :extrablock="{
                        id: 'easy_upload_template',
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'exercise[close_n_correct][text_evidence][template]',
                        id_hidden: 'example',
                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_easy_upload_template',
                        style: ''
                    }"
                    :datainput="{
                        view: true,
                        text: $t('attach'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== null && BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_name !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_name : '',
                        file_path: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== null && BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_path !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_path : '',
                        file_download: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.template !== null && BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_download !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.template.file_download : '',
                        required: getterTypeExercise.evidence.required,
                    }"
                />
            </template>
        </div>
        <div class="gh-row-content" v-if="getterTypeExercise.evidence.view">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    :datalabel="{
                        text: $t('text_after_download') + (getterTypeExercise.evidence.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_close_n_correct_text_evidence_after_download',
                        name: 'exercise[close_n_correct][text_evidence][after_download]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.after_download : '',
                        required: getterTypeExercise.evidence.required,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                    maxlength="128"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('text_after_download') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.after_download !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.text_evidence.after_download : ''"></label>
            </template>
        </div>
        <template v-if="getterTypeExercise.feedback.view">
            <div class="gh-row-content" v-for="(item,index) in getterTypeExercise.possible_answers" :key="'KEY46' + index">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_answer') + ' ' + item.id,
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_close_n_correct_answer_feedback_' + item.id,
                            name: 'exercise[close_n_correct][answer][feedback]['+item.id+']',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.feedback[item.id] !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.answer.feedback[item.id] : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_answer') + ' ' + item.id + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.feedback !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.answer.feedback[item.id] !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.answer.feedback[item.id] : ''"></label>
                </template>
            </div>
        </template>
        <template v-if="(getterTypeExercise.evidence.view && getterTypeExercise.feedback.view) || (getterTypeExercise.conditions !== undefined && getterTypeExercise.conditions.length !== 0)">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_evidence'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_close_n_correct_feedback_evidence',
                            name: 'exercise[close_n_correct][feedback][evidence][answer]',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.evidence : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_evidence') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.evidence !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.evidence : ''"></label>
                </template>
            </div>
        </template>
        <template v-if="getterTypeExercise.not_apply.checked && getterTypeExercise.feedback.view">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback_not_apply'),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_close_n_correct_feedback_evidence',
                            name: 'exercise[close_n_correct][feedback][not_apply][answer]',
                            value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.not_apply !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.not_apply : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback_not_apply') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback !== undefined && BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.not_apply !== undefined ? BaseCreationExerciseTable.exercise_data.close_n_correct.feedback.not_apply : ''"></label>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import GhTextarea from "fe-gh-textarea-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters, mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    export default {
        name: "type_close_n_correct",
        components: {
            GhEasyUpload,
            GhTreeSelectorBlock,
            GhTextarea,
            GhTableCommon,
            GhInputTextField,
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable', 'getFeeds']),
            ...mapGetters(['getterTypeExercise'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: '', field: 'id', title: 'Test', sorting: false, typeSort: 'int', style: 'min-width: 20px; max-width: 20px; width: 20px;'},
                    {text: 'answers', field: 'answer', field_component: this.$root._route.params.view || this.$root._route.params.type === 'enabled' ? false : true, required: true, style: 'margin-left: -5px; max-width: 10px; width: 85%; padding-right: 10px;'},
                    {text: 'punctuation', field: 'punctuation', field_component: this.$root._route.params.view || this.$root._route.params.type === 'enabled' ? false : true, required: true, style: 'min-width: 60px; width: 10%; padding-right: 10px;'},
                ],
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                if (this.getterTypeExercise.responsible.view) {
                    await this.$store.dispatch('getFeedResponsibles', '', {root: true});
                }

                this.haveData = true;
            }
        },
        methods: {
            disabledChecks(name){
                if(document.querySelectorAll('input[name="'+name+'"]:checked').length === this.getterTypeExercise.correct){
                    document.querySelectorAll('input[name="'+name+'"]').forEach(item => {
                        if(!item.checked){
                            item.parentElement.style.opacity = 0.5;
                            item.parentElement.style.pointerEvents = 'none';
                        }
                    });
                }else{
                    document.querySelectorAll('input[name="'+name+'"]').forEach(item => {
                        item.parentElement.style.opacity = '';
                        item.parentElement.style.pointerEvents = '';
                    });
                }
            },
        }
    }
</script>

<style>
    #label_exercise_close_n_correct_responsible_selector_label, #label_exercise_subquestion_close_n_correct_responsible_selector_label{
        width: 150px;
        text-align: end;
    }

    #subquestion_exist_data_select_SUMMARY{
        display: none !important;
    }
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-3px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: _vm.haveData ? 'OK' : '',
            text: _vm.getterQuestionnaireEvaluation.name,
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}),_c('div',{staticClass:"gh-content-global-questionnaire"},[_c('div',{staticClass:"question-body"},[_c('div',{staticClass:"gh-statement-content T15"},[_c('div',{staticClass:"T-subtitle",staticStyle:{"margin-left":"10px","margin-top":"2px"}},[_vm._v(_vm._s(_vm.$t('oportunity_hard_points')))])]),_c('div',{staticClass:"separator-line",staticStyle:{"margin-top":"unset"}}),_c('form',{attrs:{"id":"form_evaluate_questionnaire"}},[_c(_vm.component.oportunity_hard_points,{tag:"component"})],1)]),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"-10px"}},[_c('GhButton',{staticClass:"button-questionnaire-secondary",staticStyle:{"margin-bottom":"20px","margin-top":"-10px"},attrs:{"datainput":{
                    id: 'btn_close_rate_questionnaire',
                    text: _vm.$t('close')
                },"title":_vm.$t('close')},on:{"click":function($event){return _vm.Global.windowClose()}}}),(_vm.$root._route.params.complete === undefined || _vm.$root._route.params.complete === null)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",staticStyle:{"margin-bottom":"20px","margin-top":"-10px"},attrs:{"datainput":{
                        id: 'btn_save_evaluation_questionnaire',
                        text: _vm.$t('save')
                    },"title":_vm.$t('save')},on:{"click":function($event){return _vm.saveEvaluation()}}}),_c('GhButton',{staticClass:"button-questionnaire-primary",staticStyle:{"margin-bottom":"20px","margin-top":"-10px"},attrs:{"datainput":{
                        id: 'btn_finish_rate_questionnaire',
                        text: _vm.$t('finish')
                    },"disabled":!(_vm.getterQuestionnaireEvaluation.valorations.hard_points.length !== 0 && _vm.getterQuestionnaireEvaluation.valorations.hard_points[0] !== undefined && _vm.getterQuestionnaireEvaluation.valorations.hard_points[0].description !== ''),"title":_vm.$t('finish')},on:{"click":function($event){return _vm.finishEvaluation()}}})]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
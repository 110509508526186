import ConfigSocialReason from "@/view/config/company/social_reason/template/social_reason.vue";

const routes = [
    {
        path: '/config/company/social-reason',
        name: 'config_social_reason',
        component: ConfigSocialReason,
        props: {id: null, showMenu: true, otherTitle: 'social_reason'},
        children: [
            {
                path: '/config/company/social-reason/:id',
                name: 'config_social_reason_id',
                component: ConfigSocialReason,
                props: {id: null, showMenu: true, otherTitle: 'social_reason'},
            },
            {
                path: '/config/company/social-reason/:id/:view',
                name: 'config_social_reason_id_view',
                component: ConfigSocialReason,
                props: {id: null, view: true, showMenu: true, otherTitle: 'social_reason'},
            }
        ]
    }
];

export default routes;
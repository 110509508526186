<template>
    <div :style="this.$store.state.ActionHistorical.searchData && this.$store.state.ActionHistorical.searchData != [] ? 'margin-top: 12px;' : ''">
        <GhTableCommon
            :extratable="{ id:'result_action_historical_data'}"
            :data="this.$store.state.ActionHistorical.searchData"
            :header="header"
        >
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'actions_company_view', params: {id:itemProp.id,view:true}}">
                    <GhAction
                        :dataction="{
                            id: 'eject_support_pending_requests_groups',
                            text: $t('view'),
                            icon: require('@/assets/gh_new_dessign/view.svg'),
                        }"
                    />
                </router-link>
            </template>
        </GhTableCommon>
    </div>
</template>
<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from 'fe-gh-action-lib'
    import {mapState} from "vuex";
    export default {
        name: "result_action_historical",
        computed:{
            ...mapState(['ActionHistorical'])
        },
        components:{
            GhAction,
            GhTableCommon
        },
        data(){
            return{
                header: [
                    {text: 'company_name', field: 'company_name', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 19.25%;'},
                    {text: 'cif', field: 'cif',sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 100px; width: 7%;'},
                    {text: 'sector', field: 'sector', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 19.25%;'},
                    {text: 'n_employees', field: 'n_employees', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 100px; width: 7%;'},
                    {text: 'state', field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 19.25%;'},
                    {text: 'last_login', field: 'last_login', sorting: true, typeSort: 'date', style: 'min-width: 80px; max-width: 85px; width: 7%;'},
                    {text: 'n_actions', field: 'n_actions', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 7%;'},
                    {text: 'last_action', field: 'last_action', sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 14.25%;'},
                    {text: 'date', field: 'date', sorting: true, typeSort: 'date', style: 'min-width: 80px; max-width: 85px; width: 85px;'},
                    {text: '', field: 'actions', style: ''},
                ],
            }
        },
    }
</script>

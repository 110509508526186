<template>
    <div style="padding-bottom: 12px;">
        <template v-if="haveData">
            <div v-for="(item,itemIndex) in TabAction.sections" :key="itemIndex">
                <GhDropSelector
                    :datablock="{
                        id: item.id,
                        title: $t(item.name),
                        num_results: item.count + ' ' + $t('results'),
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="list_companies" :id="item.id" />
                    </template>
                </GhDropSelector>
            </div>

            <GhDropSelector v-if="show.indicators"
                :datablock="{
                    id: 'section_indicators',
                    title: $t('indicators'),
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="list_action_indicators" />
                </template>
            </GhDropSelector>

            <GhNoDropSelector
                v-if="ActionIndicators && ActionIndicators.haveResult"
                :datablock="{
                    id: 'result_search_indicators',
                    title:typeof ActionIndicators.resultTitle == 'number' ? ActionIndicators.resultTitle + ' ' + $t('results') : $t(ActionIndicators.resultTitle),
                }"
            >
                <template v-slot:actions="">
                    <GhAction
                        :dataction="{id: 'download_data_export_historical', class: 'T15_action', text: $t('export_results')}"
                        @click="exportData('indicators')"
                    />
                </template>
                <template v-slot:content>
                    <component v-bind:is="result_action_indicators" />
                </template>
            </GhNoDropSelector>

            <GhDropSelector v-if="show.historic"
                :datablock="{
                    id: 'actions_historical_search',
                    title: $t('historical'),
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="list_action_historical" />
                </template>
            </GhDropSelector>

            <GhNoDropSelector
                v-if="ActionHistorical && ActionHistorical.haveResult"
                :datablock="{
                    id: 'result_search_historical',
                    title:typeof ActionHistorical.resultTitle == 'number' ? ActionHistorical.resultTitle + ' ' + $t('results') : $t(ActionHistorical.resultTitle),
                }"
            >
                <template v-slot:actions="">
                    <GhAction
                        :dataction="{id: 'download_data_export_historical', class: 'T15_action', text: $t('export_results')}"
                        @click="exportData('historic')"
                    />
                </template>
                <template v-slot:content>
                    <component v-bind:is="result_search" />
                </template>
            </GhNoDropSelector>
        </template>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import result_search from "@/view/actions/historical/result_content/result_action_historical.vue";
    import list_companies from "@/view/actions/template/list_companies.vue";
    import list_action_historical from "@/view/actions/historical/list_actions_historical.vue";
    import list_action_indicators from "@/view/actions/indicators/list_indicators.vue";
    import result_action_indicators from "@/view/actions/indicators/result_content/result_action_indicators.vue";
    export default {
        name: "list_actions",
        components: {
            GhAction,
            GhNoDropSelector,
            GhDropSelector,
        },
        computed:{
            ...mapState(['TabSupport','ActionHistorical','ActionIndicators','TabAction','Login'])
        },
        data(){
            return {
                haveData:false,
                list_companies:list_companies,
                list_action_indicators:list_action_indicators,
                list_action_historical:list_action_historical,
                result_search:result_search,
                result_action_indicators: result_action_indicators,

                actions: [
                    {
                        id:'export_monthly_support_indicator_data',
                        name: this.$t('export_monthly'),
                    },
                    {
                        id:'export_annual_support_indicator_data',
                        name: this.$t('export_annual')
                    }
                ],
                show: {
                    indicators: false,
                    historic: false,
                }
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getSectionsActions', '', {root: true});

                this.Login.tabs[8]['sub-modules'].forEach((subroute) => {
                    this.show[subroute.name] = subroute.have_permission;
                });

                this.haveData = true;
            }
        },
        methods:{
            exportTypeExcel(type){
              this.$store.dispatch('exportTypeExcel', {where: 'actions', type: type}, {root: true});
            },
            exportData(type){
                const formData = new FormData(document.getElementById('form_search_actions_historical'));
                this.$store.dispatch('exportResultActionHistorical', {formData: [...formData],type: type}, {root: true});
            }
        },
        destroyed() {
            this.$store.dispatch('destroyedActionHistoricalResultData',{},{root:true});
            this.$store.dispatch('destroyedActionIndicatorsResultData',{},{root:true});
        }
    }
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'popup_evaluate_' + _vm.refreshEvaluate,staticClass:"gh-popup-ellipsis-title"},[_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"RefPopupEvaluate",attrs:{"generalConfiguration":{
            id: 'popup_evaluate',
            title: _vm.getterQuestionnaireEvaluation.name,
            type: '',
            style: 'font-size: 20px; width: 1000px; min-width: 1000px; max-width: 1000px;overflow-y: auto !important;'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c(_vm.evaluate,{tag:"component",attrs:{"read":true}})]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button_close_popup_evaluate',
                    text: _vm.$t('close'),
                    class: 'button-questionnaire-secondary'
                }},on:{"click":function($event){return _vm.Global.closePopUp('popup_evaluate');}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <GhTableCommon style="margin-top: 10px;"
        :extratable="{
            id:'result_billing_data'
        }"
        :header="header"
        :data="HistoricBilling.searchData"
    >
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import {mapState} from "vuex";
    export default {
        name: "result_billing",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapState(['HistoricBilling']),
        },
        data(){
            return {
                header: [
                    {text: 'reference', field: 'code', sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 10%;'},
                    {text: 'company', field: 'business', sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 79%;'},
                    {text: 'amount', field: 'price', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 5%;'},
                    {text: 'pay_day', field: 'date', sorting: true, typeSort: 'string', style: 'min-width: 80px; width: 6%;'},
                ]
            }
        }
    }
</script>
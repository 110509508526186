import ListConfig from "@/view/config/list_config";

import Company from './company/route';
import Creation from './creation/route';
import Entorno from './around/route';
import Benchmark from './benchmark/route';
import Soporte from './support/route'
import Referenciales from './referential/route';
import Evaluators from './evaluators/route';

const route = [
    {
        path: '/config',
        name: 'configuration',
        component: ListConfig,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Company,
    ...Creation,
    ...Entorno,
    ...Benchmark,
    ...Soporte,
    ...Referenciales,
    ...Evaluators
]

export default routes;

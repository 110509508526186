<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in TabTutor.sections" :key="labelIndex">
            <GhDropSelector
                :datablock="{
                    id: 'tutor_' + label.id,
                    title: $t(label.name),
                    second_title: $t(label.second_text),
                    num_results: label.result,
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="table_tutor" :id="label.id"></component>
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import table_tutor from "./template/table_tutor.vue";
    import {mapState} from "vuex";

    export default {
        name: "list_tutor",
        components: {
            GhDropSelector
        },
        computed: {
            ...mapState(['TabTutor'])
        },
        data(){
            return {
                haveData: false,
                table_tutor: table_tutor
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getTutorSections', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
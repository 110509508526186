import ListCreation from "@/view/config/creation/list_creation";

import Topics from './topics/route';
import TipoDeEjercicio from './types_exercise/route';
import Groupers from './groupers/route';
import Responsables from './responsibles/route';
import Seals from './seals/route';
import Certificate from './certificates/route';
import WeightingGroup from './weighting_group/route';
import WeightingElement from './weighting_element/route';
import EvaluationBasis from './evaluation_basis/route';

const route = [
    {
        path: '/config_creation',
        name: 'config_creation',
        component: ListCreation,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Topics,
    ...TipoDeEjercicio,
    ...Groupers,
    ...Responsables,
    ...Seals,
    ...Certificate,
    ...WeightingGroup,
    ...WeightingElement,
    ...EvaluationBasis
]

export default routes;
<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_learning',
            title: $t(title),
            type: 'info',
            style: 'font-size: 20px; width: 1000px; min-width: 1000px; max-width: 1000px;'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh-content-global" style="overflow: auto; max-height: 360px;">
                <div class="gh-row-content" v-if="getterQuestionnaireKnowMoreOrLearning.know_more !== '' && getterQuestionnaireKnowMoreOrLearning.know_more !== null && getterQuestionnaireKnowMoreOrLearning.know_more !== undefined">
                    <div>
                        <div class="T-text" style="margin-top: -16px;">
                            {{getterQuestionnaireKnowMoreOrLearning.know_more}}
                        </div>
                    </div>
                </div>
                <div v-if="getterQuestionnaireKnowMoreOrLearning.content !== undefined && getterQuestionnaireKnowMoreOrLearning.content.length !== 0">
                    <div v-for="(item, keyItem) in getterQuestionnaireKnowMoreOrLearning.content" :key="'CONTENT' + keyItem" style="display: flex;" :style="keyItem === 0 ? getterQuestionnaireKnowMoreOrLearning.know_more === undefined ? 'margin-top: -15px;' : 'margin-top: 15px;' : 'margin-top: 40px;'">
                        <div style="width: 150px; min-width: 150px;">
                            <img width="150" :src="item.src" alt="" style="border-radius: 4px;">
                        </div>
                        <div style="width: 100%; margin-left: 20px;">
                            <div class="gh-row-content T-subtitle-second">
                                {{item.name}}
                            </div>
                            <template v-if="item.author !== '' || item.editor !== '' || item.publication_date !== ''">
                                <div class="gh-row-content T-small-text" style="font-size: 12px !important; margin-top: -8px; color: #AFAFAF;">
                                    {{item.author + ' ' + item.editor + ' ' + item.publication_date}}
                                </div>
                            </template>
                            <div class="gh-row-content" style="padding-top: 5px; padding-bottom: 15px;">
                                <label class="gh_text_field-label T-small-text">{{$t('topics') + ': '}}</label>
                                <label class="gh_text_field-label T-small-text" style="color: #808080;">{{item.topic}}</label>
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T-text">{{item.description}}</label>
                            </div>
                            <div class="gh-row-content no-apply-pallete" v-if="item.view !== '' || item.download !== '' || item.view !== undefined || item.download !== undefined" style="padding-top: 10px;">
                                <a :href="item.view" target="_blank">
                                    <GhButton v-if="item.view !== '' && item.view !== undefined"
                                        :datainput="{
                                            id: 'button_view_content_' + keyItem,
                                            text: $t('view'),
                                            class: 'T19 container-md_button_content button-questionnaire-secondary'
                                        }"
                                    />
                                </a>
                                <a :href="item.download">
                                    <GhButton v-if="item.download !== '' && item.download !== undefined"
                                        :datainput="{
                                            id: 'button_download_content_' + keyItem,
                                            text: $t('download'),
                                            class: 'T19 container-md_button_content button-questionnaire-primary'
                                        }"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_close_popup_learning',
                    text: $t('close'),
                    class: 'T19 container-md_button_content button-questionnaire-secondary'
                }"
                @click="Global.closePopUp('popup_learning');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import svgUrlHat from "@/assets/questionnaire/hat-white.svg";
    import svgUrlMaterial from "@/assets/questionnaire/book-white.svg";

    export default {
        name: 'popup_learning',
        props: ['title'],
        components: {
            GhButton,
            GhPopUp
        },
        computed: {
            ...mapGetters(['getterQuestionnaireKnowMoreOrLearning'])
        },
        mounted() {
            fetch(this.title === 'know_more' ? svgUrlHat : svgUrlMaterial).then(response => response.text()).then(svgData => {
                this.$el.children[0].children[0].children[0].children[0].children[0].innerHTML = svgData;
                this.$el.children[0].children[0].children[0].style.setProperty("background-color", "#1B82C5", "important");
            });
        }
    }
</script>

<style>
    #popup_learning > div > header > div > .T8 {
        font-size: 20px;
    }
</style>